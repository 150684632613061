export interface IFilterParams {
  page: string
  search?: string
  limit?: string
}

export const filterValues = {
  page: '1',
  search: '',
  limit: '9',
}
