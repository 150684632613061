/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getAmenities, putAmenities} from '../api/amenities'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {AmenitiesModel} from '../interface/Amenities'
import GoBack from '../../../components/partials/GoBack'
import Button from '../../../components/common/Button'
import {handleErrorMessage} from '../../../components/handleErrorMessage'

export interface IPageParams {
  id: string
}
const URL = '/amenities'
const EditAmenities: FC = () => {
  const {id} = useParams<IPageParams>()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const [amenities, setAmenities] = useState<AmenitiesModel>()

  useEffect(() => {
    getAmenities(id).then(({data}) => {
      setAmenities(data.result)
    })
  }, [id])

  function handleChange(event: React.ChangeEvent<any>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const result = {...amenities, [name]: value} as AmenitiesModel
    setAmenities(result)
  }

  function handleSubmit(event: React.ChangeEvent<any>) {
    event.preventDefault()
    setLoading(true)
    putAmenities(id, amenities as AmenitiesModel)
      .then((data) => {
        // history.push(`/amenities/view/${id}`)
        history.push(URL)
        toast.success('Success!')
      })
      .catch((error) => {
        const res = error.response.data.result
        toast.error(handleErrorMessage(res)[0])
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Update Amenities</PageTitle>
      {amenities && (
        <form onSubmit={handleSubmit}>
          <div className='card pt-4 mb-6 mb-xl-9'>
            {/*begin::Card body*/}
            <div className='card-body pt-0 pb-5'>
              {/*begin::Table*/}
              <table className='table align-middle table-row-dashed gy-5'>
                {/*begin::Table body*/}
                <tbody className='fs-6 fw-bold text-gray-600'>
                  <tr>
                    <td>Amenities Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={amenities.name}
                        onChange={handleChange}
                        name='name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Amenities Tagline</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={amenities.icon}
                        onChange={handleChange}
                        name='icon'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Amenities Description</td>
                    <td>
                      <textarea
                        className='form-control'
                        onChange={handleChange}
                        name='description'
                        required
                        value={amenities.description}
                      ></textarea>
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
              <div className='d-flex justify-content-end align-items-center mt-15'>
                {/*begin::Button*/}
                <GoBack url={URL} />

                {/*end::Button*/}
                <Button loading={loading} name={'Update'} />
                {/*begin::Button*/}
                {/* <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                <span className='indicator-label'>Save</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </button> */}
                {/*end::Button*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
        </form>
      )}
    </>
  )
}

export default EditAmenities
