import React, {FC, useState} from 'react'
// import {toast} from 'react-toastify'
interface ModalProps {
  id: string | undefined | object
  handleAction: (event: React.ChangeEvent<any>, id: string, status: string) => void
  text: string
  isAdmin: Boolean
}

const ModalActions: FC<ModalProps> = ({text, handleAction, id, isAdmin}) => {
  //   const [rejectionReason, setRejectionReason] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  return (
    <div className='modal fade' id='kt_modal' tabIndex={-1} aria-hidden='true'>
      {/*begin::Modal dialog*/}
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        {/*begin::Modal content*/}
        <div className='modal-content'>
          {/*begin::Modal header*/}
          <div className='modal-header' id='kt_modal_header'>
            {/*begin::Modal title*/}
            <h2 className='fw-bolder'>{text}</h2>
            {/*end::Modal title*/}
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-kt-users-modal-action='close'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal'
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className='svg-icon svg-icon-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect
                    opacity='0.5'
                    x={6}
                    y='17.3137'
                    width={16}
                    height={2}
                    rx={1}
                    transform='rotate(-45 6 17.3137)'
                    fill='black'
                  />
                  <rect
                    x='7.41422'
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform='rotate(45 7.41422 6)'
                    fill='black'
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Modal header*/}
          {/*begin::Modal body*/}
          <div className='modal-body scroll-y mx-5 mx-xl-15'>
            {/*begin::Form*/}
            <label htmlFor='status'>Status</label>
            <select
              className='form-select form-select-solid mb-5'
              data-placeholder='Select option'
              defaultValue='default'
              onChange={(event) => {
                setStatus(event.target.value)
              }}
            >
              <option value='default' disabled>
                Set Status
              </option>
              {isAdmin && (
                <>
                  <option value='PACKAGING'>Packaging</option>
                  <option value='SHIPPED'>Shipped</option>
                  <option value='DELIVERED'>Delivered</option>
                  <option value='RETURNED'>Returned</option>
                  {/* <option value='CANCELED'>Canceled</option> */}
                </>
              )}
              <option value='REJECTED'>Rejected</option>
            </select>
            {/* {status === '2' && (
              <div className='form-group mb-7'>
                <label htmlFor='reject'>REASON FOR REJECTION</label>
                <textarea
                  className='form-control form-control-solid'
                  rows={5}
                  onChange={(event) => setRejectionReason(event?.target.value)}
                  required
                ></textarea>
              </div>
            )} */}
            <div>
              <button
                type='submit'
                className={`btn btn-primary me-3`}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal'
                // data-bs-toggle={status !== '2' && !rejectionReason ? 'modal' : ''}
                // data-bs-target={status !== '2' && !rejectionReason ? '#kt_modal' : ''}
                onClick={(event) => {
                  //   if (status === '2') {
                  //     if (rejectionReason) {
                  //       handleAction(event, id as string, status, rejectionReason)
                  //       setRejectionReason('')
                  //     } else {
                  //       toast.error('Enter valid reason')
                  //     }
                  //   } else {
                  //     handleAction(event, id as string, status)
                  //   }
                  handleAction(event, id as string, status)
                }}
                disabled={!(status.length > 0)}
              >
                Save
              </button>

              <button
                type='submit'
                className='btn btn-light'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal'
                data-kt-users-modal-action='close'
              >
                <span className='indicator-label'>Cancel</span>
              </button>
            </div>

            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </div>
        {/*end::Modal content*/}
      </div>
      {/*end::Modal dialog*/}
    </div>
  )
}
export default ModalActions
