import {FC} from 'react'
import {Link} from 'react-router-dom'
import {parseDate} from '../../../utils/dateAndTime'
import Variation from '../common/variation/Variation'
import ViewItem from '../common/ViewItem'
import {DealModel} from './InterfaceDeal'
import Status from './Status'

interface IPageParams {
  deal: DealModel
  isAdmin?: boolean
}
const ViewContent: FC<IPageParams> = ({deal, isAdmin = false}) => {
  return (
    <div className='collapse show'>
      <div className='card mb-5 mb-xl-10'>
        <div className='rounded border p-3'>
          <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                Deal Info
              </a>
            </li>
            {deal.deals.map((item, index) => {
              return (
                <li className='nav-item' key={index}>
                  <a className='nav-link ' data-bs-toggle='tab' href={`#kt_tab_pane_${index + 2}`}>
                    Deals #{index + 1}
                  </a>
                </li>
              )
            })}
          </ul>
          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
              <div className='pb-5 fs-6'>
                <ViewItem name='ID' value={deal?._id} />
                <ViewItem
                  name='Product'
                  value={
                    <div className='text-gray-600'>
                      <Link
                        to={`/product/view/${deal?.product_id?._id}`}
                        className='text-gray-800 text-hover-primary mb-1'
                      >
                        {deal?.product_id?.name}
                      </Link>
                    </div>
                  }
                />
                {isAdmin && (
                  <ViewItem
                    name='Merchant'
                    value={
                      <div className='text-gray-600'>
                        <Link
                          to={`/merchant/view/${deal?.merchant_id?._id}`}
                          className='text-gray-800 text-hover-primary mb-1'
                        >
                          {deal?.merchant_id?.display_name}
                        </Link>
                      </div>
                    }
                  />
                )}
                <ViewItem name='NAME' value={deal?.title} />
                <ViewItem name='DESCRIPTION' value={deal.description} />
                {deal.start_date && (
                  <ViewItem name='STARTDATE' value={parseDate(deal.start_date)} />
                )}
                {deal.end_date && <ViewItem name='ENDDATE' value={parseDate(deal.end_date)} />}
                <ViewItem name='CREATED DATE' value={parseDate(deal?.createdAt)} />
                <ViewItem name='STOCK' value={deal?.product_id?.stock} />
                <ViewItem name='STATUS' value={<Status status={deal.is_approved} />} />
                {deal.is_approved !== '3' && (
                  <ViewItem name='REJECTION REASON' value={deal.rejection_reason} />
                )}
              </div>
            </div>
            {deal.deals.map((item, index) => {
              return (
                <div
                  className='tab-pane fade'
                  id={`kt_tab_pane_${index + 2}`}
                  role='tabpanel'
                  key={index}
                >
                  <ViewItem name='TITLE' value={item.title} />
                  <ViewItem name='ORIGINAL PRICE' value={item.original_price} />
                  <ViewItem name='DISPLAY PRICE' value={item.display_price} />
                  {/* <ViewItem name='PRODUCT PRICE' value={deal?.product_id?.actual_price} /> */}
                  {/*begin::Details item*/}
                  {/* <div className='fw-bolder mt-5'>DISCOUNTED PRICE</div>
                  <div className='text-gray-600'>
                    {calculateDisplayPrice(
                      item.offer_type,
                      deal?.product_id?.actual_price,
                      item.offer_value
                    )}
                  </div> */}
                  {/*end::Details item*/}
                  {/*begin::Details item*/}

                  <ViewItem
                    name='OFFER TYPE'
                    value={item.offer_type === 'flat' ? 'FLAT' : 'PERCENT'}
                  />

                  <ViewItem
                    name='DISCOUNT'
                    value={
                      item.offer_type === 'flat'
                        ? 'Rs. ' + item.offer_value
                        : item.offer_value + '%'
                    }
                  />
                  <ViewItem name='DESCRIPTION' value={item.extra_information} />
                  {item.variation && item.variation.length > 0 && (
                    <Variation variation={item.variation} />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewContent
