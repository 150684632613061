import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {NotificationModel} from '../../modules/notification/interface/INotification'

type Props = {
  className: string
  notification: NotificationModel[]
}

const Notices: React.FC<{data: NotificationModel}> = ({data: {title, description, createdAt}}) => {
  return (
    <div>
      <div>
        <div className='fw-mormal timeline-content text-muted fw-bolder'>{title}</div>
        <div className='fw-mormal timeline-content text-muted'>{description}</div>
      </div>
    </div>
  )
}

const Notification: React.FC<Props> = ({className, notification}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bolder mb-2 text-dark'>Notice</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
        </div>
      </div>
      {notification && notification.length > 0 ? (
        <div className='card-body'>
          {notification.slice(0, 7).map((item: NotificationModel, index: number) => {
            return <Notices data={item} key={item._id} />
          })}
        </div>
      ) : (
        <div className='card-body'>
          <div>
            <div className='timeline-item d-flex'>
              <div className='timeline-badge me-2'>
                <i className='fa fa-genderless text-danger fs-1'></i>
              </div>
              <div className='fw-bold timeline-content text-muted'>No Notice</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {Notification}
