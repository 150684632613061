import {FC, useEffect, useState} from 'react'
import {DatePicker, Form, Input, message, notification, Select, Spin, Switch} from 'antd'

import MultiImage from './partials/MultiImage'
import DealChoice from './partials/DealChoice'
import {getAllProduct, getProduct, getVariations} from '../../product-management/api/product'
import {ProductModel} from '../../../product/interface/Product'
import {VariationListModel} from '../../../variation-management/interface/IVariation'
import {getDeal, putDeal, putDealStockIn} from '../api/deal'
import Button from '../../../../components/common/Button'
import {useHistory, useParams} from 'react-router-dom'
import moment from 'moment'
import DealContext from '../contexts/DealContext'
import handleError from '../../../../components/handleError'
import GoBack from '../../../../components/partials/GoBack'

interface Props {
  isStockChange: boolean
}

const URL = '/deal/1'

const EditDeal: FC<Props> = ({isStockChange}) => {
  const {id} = useParams<{id: string}>()
  const [form] = Form.useForm()
  const [editPageImageUrls, setImageUrls] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [productList, setProductList] = useState<ProductModel[]>([])
  const [applicableVariants, setApplicableVariants] = useState<VariationListModel>()
  const [appliedVariants, setAppliedVariants] = useState<any | null>(null)
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)
  const [checked, setChecked] = useState(false)
  const history = useHistory()

  useEffect(() => {
    setLoading(true)
    getAllProduct({page: '', search: '', limit: 'MAX'}).then((value) => {
      setProductList(value.data)
    })
    getDeal(id, 'true')
      .then(({data}) => {
        setImageUrls(data.result.images)
        getProductDetail(data.result.product_id._id)
        const res = data.result
        form.setFieldsValue({
          product_id: res.product_id._id,
          title: res.title,
          actual_price: res.product_id.actual_price,
          deals: res.deals,
          range: [moment(res.start_date), moment(res.end_date)],
          description: res.description,
          flash_deal: res.flash_deal,
          type: res.type,
        })
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    form.setFieldsValue({flash_deal: checked})
  }, [checked, form])

  function getProductDetail(id: string, applyVariants: boolean = false) {
    getProduct(id, 'false')
      .then(({data}) => {
        const {result} = data
        //variations for the product
        getVariations(result.categoryId._id)
          .then((value) => {
            setApplicableVariants(value)
          })
          .catch((err) => {
            message.error('Could not get any variation of this product!')
            console.log(err)
          })
        //selected variation of parent product
        applyVariants && setAppliedVariants(result.variation)
      })
      .catch((err) => {
        message.error('Error on fetching product information')
        console.log(err)
      })
  }

  // On Form Submission
  async function handleSubmit(values: any) {
    let payload = {
      ...values,
      images,
    }

    if (values.flash_deal) {
      payload = {
        ...payload,
        end_date: values['range'][1].toDate(),
        start_date: values['range'][0].toDate(),
      }
    }
    // return
    delete payload['range']
    setFormIsSubmitting(true)
    console.log(payload)
    await putDeal(id, payload)
      .then(({data}) => {
        notification.success({message: data.result})
        history.push('/deal/1')
      })
      .catch((error) => {
        console.log(error)
        if (error && error.response) {
          handleError(form, error.response.data.result)
        }
      })
    setFormIsSubmitting(false)
  }

  async function handleStockChange(values: any) {
    setFormIsSubmitting(true)
    await putDealStockIn(id, values)
      .then(({data}) => {
        notification.success({message: data.result})
        history.push('/deal/1')
      })
      .catch((error) => {
        if (error && error.response) {
          handleError(form, error.response.data.result)
        }
      })
      .finally(() => {
        setFormIsSubmitting(false)
      })
  }

  // On Image Upload
  let images: string[] = editPageImageUrls
  const allImageUrls = (values: string[]) => {
    images = values
  }

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title'>Update New Deal</h3>
        <div className='card-toolbar'>
          <GoBack url={URL} className='btn btn-light btn-sm me-5' />
          <button onClick={form.submit} type='button' className='btn btn-sm btn-primary'>
            {formIsSubmitting ? 'Please Wait...' : 'Update'}
          </button>
        </div>
      </div>
      {loading ? (
        <Spin style={{height: '80vh', marginTop: '40px'}} />
      ) : (
        <DealContext.Provider
          value={{changedVariant: appliedVariants, isCreate: false, isStockChange: isStockChange}}
        >
          <Form
            form={form}
            onFinish={isStockChange ? handleStockChange : handleSubmit}
            scrollToFirstError
          >
            <div className='card-body'>
              <div className='row'>
                <div className='col'>
                  <label htmlFor='product' className='required form-label'>
                    Select Product
                  </label>
                  <Form.Item
                    name='product_id'
                    rules={[
                      {
                        required: true,
                        message: 'Product is required!',
                      },
                    ]}
                  >
                    <Select
                      // defaultValue={form.getFieldValue('product_id').name}
                      showSearch
                      placeholder='Select a product'
                      onSearch={() => {}}
                      onSelect={(_, option) => {
                        const data = productList[+option.key!]
                        setApplicableVariants(undefined)
                        setAppliedVariants(undefined)
                        form.setFieldsValue({
                          actual_price: data.actual_price,
                        })
                        getProductDetail(data._id!, true)
                      }}
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().includes(input.toLowerCase())
                      }
                      disabled={isStockChange}
                    >
                      {/* do not change the key={idx} onSelect is triggered on that basis */}
                      {productList.map((e, idx) => (
                        <Select.Option key={idx} value={e._id!}>
                          {e.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className='col'>
                  <label htmlFor='title' className='required form-label'>
                    Title
                  </label>
                  <Form.Item
                    name='title'
                    rules={[{required: true, message: 'A title is required!'}]}
                  >
                    <Input placeholder='2 Pairs of Nike Air Shoes' disabled={isStockChange} />
                  </Form.Item>
                </div>
              </div>
              <div className='row'>
                <div className='col-6'>
                  <label htmlFor='actual_price' className='form-label'>
                    Product Price
                  </label>
                  <Form.Item name='actual_price'>
                    <Input disabled />
                  </Form.Item>
                </div>
                <div className='col-6'>
                  <label className='form-label required'>Deal Type</label>
                  <Form.Item name='type'>
                    <Select
                      showSearch
                      placeholder='Select Type'
                      filterOption={(input, option) =>
                        option?.children.toLowerCase().includes(input.toLowerCase())
                      }
                      disabled={isStockChange}
                    >
                      <Select.Option value='service'>Service</Select.Option>
                      <Select.Option value='product'>Product</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className='row'>
                <div className='col'>
                  <label className='form-label'>Flash Deal</label>
                  <Form.Item name='flash_deal' valuePropName='checked'>
                    <Switch
                      defaultChecked={false}
                      checkedChildren={<span>No</span>}
                      unCheckedChildren={<span>Yes</span>}
                      onChange={(checked) => setChecked(checked)}
                      disabled={isStockChange}
                    />
                  </Form.Item>
                </div>
                {form.getFieldValue('flash_deal') && (
                  <div className='col-6'>
                    <label className='form-label'>Valid Period</label>
                    <Form.Item
                      name='range'
                      rules={[{required: true, message: 'Date is required!'}]}
                    >
                      <DatePicker.RangePicker
                        style={{display: 'inline-flex'}}
                        showTime
                        disabled={isStockChange}
                      />
                    </Form.Item>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col'>
                  <div className='rounded border d-flex flex-column p-10'>
                    <label className='required form-label'>Write about the deal?</label>
                    <Form.Item
                      name='description'
                      rules={[{required: true, message: 'Description is required!'}]}
                    >
                      <Input.TextArea data-kt-autosize='true' rows={4} disabled={isStockChange} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              {/* {!isStockChange && ( */}
              <div className='row'>
                <div className='col mt-10'>
                  <label className='required form-label'>Upload Deal Images</label>
                  <MultiImage
                    imagesUrl={editPageImageUrls}
                    setImagesUrl={allImageUrls}
                    disabled={isStockChange}
                  />
                </div>
              </div>
              {/* )} */}
              <DealChoice form={form} applicableVariants={applicableVariants} />
            </div>
            <div className='card-footer'>
              <Button loading={formIsSubmitting} name='Update' />
            </div>
          </Form>
        </DealContext.Provider>
      )}
    </div>
  )
}

export default EditDeal
