import {check} from 'prettier'
import {useEffect, useState} from 'react'
import DynamicAttribute from './DynamicAttribute'

interface Props {
  variations: any
  form: any
}

const getSelectedAttributes = (attributes: any) => {
  return attributes.map((_: any) => _.title)
}

const VariationForm = ({variations, form}: Props) => {
  const [checkedList, setCheckedList] = useState<string[]>([])
  useEffect(() => {
    if (variations && variations.length > 0) {
      setCheckedList(getSelectedAttributes(variations))
    }
  }, [variations])

  useEffect(() => {
    const result = form.getFieldValue()
    if (result && result.variation && result.variation.length > 0) {
      const rest = Object.keys(result.variation[0])
        .filter((e) => e !== 'stock')
        .filter((e) => e !== 'sku')
      setCheckedList(rest)
    }
  }, [form])

  return (
    <>
      {variations && checkedList && (
        <DynamicAttribute attributes={variations} form={form} labels={checkedList} />
      )}
    </>
  )
}

export default VariationForm
