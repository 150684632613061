import {Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import AccountStatement from './components/account-statement/AccountStatement'

const FinancePage = () => {
  return (
    <Switch>
      <Route path='/finance/statement'>
        <PageTitle breadcrumbs={[]}>Account Statement</PageTitle>
        <AccountStatement />
      </Route>
    </Switch>
  )
}

export default FinancePage
