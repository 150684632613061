/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {deleteFeatureAd, getAllFeatureAd, searchFeatureAd} from '../api/feature-ad'
import {Link, useParams} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {FeatureAdListModel} from '../interface/FeatureAd'
import {toast} from 'react-toastify'
import Pagination from '../../../components/Pagination'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Search from '../../../components/Search'
import ModalAddFeatureAd from '../modal/ModalAddFeatureAd'
import Spinner from '../../../components/Spinner'
import NoData from '../../../components/common/NoData'

interface IPageParams {
  page: string
}

const ListFeatureAd: FC = () => {
  const [featureAdList, setFeatureAdList] = useState<FeatureAdListModel>()
  const [search, setSearch] = useState<string>('')
  const [deleteId, setDeleteId] = useState<string>('')
  const {page} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)

  function getFeatureAd() {
    getAllFeatureAd(page)
      .then((data) => {
        setLoading(false)
        setFeatureAdList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }
  useEffect(() => {
    getFeatureAd()
  }, [page])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteFeatureAd(id).then((data) => {
      toast.success('Success!')
      getFeatureAd()
    })
  }
  function handleSearch(event: React.ChangeEvent<any>) {
    event.preventDefault()
    const {value} = event.target
    setSearch(value)
    if (value === '') {
      getFeatureAd()
      return
    }
    searchFeatureAd(search).then((data) => {
      setFeatureAdList(data)
    })
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card featureAd*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}
                <Search name={search} placeholder='featureAd' handleSearch={handleSearch} />
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Toolbar*/}
                <div className='d-flex justify-content-end' data-kt-featureAd-table-toolbar='base'>
                  <ModalAddFeatureAd handleGetFeatureAd={getFeatureAd} />
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Delete*/}
                <ModalDelete id={deleteId} handleDelete={handleDelete} />
                {/*end::Modal - delete*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card featureAd*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_featureAds_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            FeatureAd
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex={0}
                            aria-controls='kt_table_featureAds'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '134.65px'}}
                            aria-label='Role: activate to sort column ascending'
                          >
                            Callback Name
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_featureAds'
                            style={{width: '140.417px'}}
                          >
                            Featured
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_featureAds'
                            style={{width: '140.417px'}}
                            aria-label='Joined Date: activate to sort column ascending'
                          >
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '106.433px'}}
                            aria-label='Actions'
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {featureAdList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {featureAdList.data.length === 0 && <NoData />}
                          {featureAdList.data.length !== 0 &&
                            featureAdList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::FeatureAd=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to={`/feature-ad/view/${item._id}`}>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.image ? (
                                            <img
                                              src={item.image}
                                              alt='featureAd'
                                              height='50px'
                                              width='50px'
                                            />
                                          ) : (
                                            'N'
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::FeatureAd details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/feature-ad/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.name}
                                      </Link>
                                      <span>{item.offer}%</span>
                                    </div>
                                    {/*begin::FeatureAd details*/}
                                  </td>
                                  {/*end::FeatureAd=*/}
                                  {/*begin::callback name*/}
                                  <td>{item.callback_name}</td>
                                  {/*begin::callback name*/}
                                  {/*begin::featured*/}
                                  <td>
                                    {item.is_featured ? (
                                      <div className='badge badge-light-success fw-bolder'>
                                        Featured
                                      </div>
                                    ) : (
                                      <div className='badge badge-light-danger fw-bolder'>
                                        Not Featured
                                      </div>
                                    )}
                                  </td>
                                  {/*end:: featured*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Link
                                      to={`/feature-ad/edit/${item._id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='feature-ad'
                  currentPage={page}
                  prev_page={featureAdList?.prev_page}
                  next_page={featureAdList?.next_page}
                  total_number_of_page={featureAdList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListFeatureAd
