export interface ErrorMessage {
  value: string
  msg: string
  param: string
  location: string
}
const handleErrorMessage = (message: string | ErrorMessage[]) => {
  if (Array.isArray(message)) {
    return message.map((_) => _.msg)
  } else if (typeof message == 'string') {
    return [message]
  } else {
    return ['Error! Please try again']
  }
}
export {handleErrorMessage}
