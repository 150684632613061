import {FC} from 'react'
import {useHistory} from 'react-router'

const GoBack: FC<{url?: string; className?: string; name?: string}> = ({url, className, name}) => {
  const history = useHistory()

  const handleClick = () => {
    if (url) {
      history.push(url)
    } else {
      history.goBack()
    }
    return
  }
  return (
    <button
      type='button'
      onClick={handleClick}
      className={className ? className : 'btn btn-light me-5'}
    >
      {name ? name : 'Back'}
    </button>
  )
}
export default GoBack
