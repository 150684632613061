import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import CreatePromo from './promo/CreatePromo'
import EditPromo from './promo/EditPromo'
import ListPromo from './promo/ListPromo'
import ViewPromo from './promo/ViewPromo'

const PromoPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/promo/new'>
          <PageTitle breadcrumbs={[]}>Create New Promocode</PageTitle>
          <CreatePromo />
        </Route>
        <Route path='/promo/edit/:id'>
          <PageTitle breadcrumbs={[]}>Promocode Edit</PageTitle>
          <EditPromo />
        </Route>
        <Route path='/promo/view/:id'>
          <PageTitle breadcrumbs={[]}>Promocode View</PageTitle>
          <ViewPromo />
        </Route>
        <Route path='/promo/:page'>
          <PageTitle breadcrumbs={[]}>Promocode List</PageTitle>
          <ListPromo />
        </Route>
        <Redirect from='/promo' exact={true} to='/promo/1' />
        <Redirect to='/promo/1' />
      </Switch>
    </>
  )
}

export default PromoPage
