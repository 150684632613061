import {FC} from 'react'
interface Props {
  children: any
  name: string
}
const ViewCard: FC<Props> = ({name, children}) => {
  return (
    <>
      {children && (
        <div className='card mb-5 mb-xl-8'>
          <div className='card-body'>
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>{name}</div>
            </div>
            <div className='separator' />
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export default ViewCard
