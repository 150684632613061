/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {getOrder, putOrder} from '../api/order'
import {useParams} from 'react-router-dom'
import {OrderModel} from '../interface/Order'
import {ViewOrder as View} from '../../../components/common/order/ViewOrder'

export interface IPageParams {
  id: string
}
const ViewOrder: FC = () => {
  const {id} = useParams<IPageParams>()
  const [order, setOrder] = useState<OrderModel>()

  useEffect(() => {
    getOrder(id)
      .then(({data}) => {
        setOrder(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  function handleUpdateOrder(id: string, itemId: string, status: string) {
    putOrder(id, itemId, status)
      .then(({data}) => {
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return <>{order && <View isAdmin={true} order={order} handleUpdateOrder={handleUpdateOrder} />}</>
}

export default ViewOrder
