import {FC, useState} from 'react'
import {postAmenities} from '../api/amenities'
import {toast} from 'react-toastify'

import {AutoComplete, Drawer, Form, Space, Button} from 'antd'
import {faIcons} from '../../../../utils/icons'
import handleError from '../../../components/handleError'

interface ModalProps {
  handleGetAmenities: () => void
}

const ModalAddAmenities: FC<ModalProps> = (props) => {
  const [visible, setVisible] = useState(false)
  const [selectedIcon, setSelectedIcon] = useState('')
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(
    faIcons.map((e) => {
      // const coffeeLookup: IconLookup = {prefix: 'fas', iconName: {e}}/
      // const coffeeIconDefinition: IconDefinition = findIconDefinition(coffeeLo/okup)
      return {
        value: e,
        label: (
          <div className='row'>
            {/* <FontAwesomeIcon icon={coffeeIconDefinition} /> */}
            <div className='col-2'>
              <i className={`fas fa-${e}`} style={{fontSize: '24px'}} />
            </div>
            <div className='col-10'>
              <span>&nbsp;{e}</span>
            </div>
          </div>
        ),
      }
    })
  )
  const [form] = Form.useForm()

  const showDrawer = () => {
    setVisible(true)
  }

  const onClose = () => {
    setVisible(false)
  }

  const onFinish = (values: any) => {
    setLoading(true)
    postAmenities({
      name: values.name,
      icon: selectedIcon,
      description: values.description,
    })
      .then(() => {
        toast.success('New Amenity Created!')
        setVisible(false)
        setSelectedIcon('')
        props.handleGetAmenities()
        form.resetFields()
      })
      .catch((error) => {
        setLoading(false)
        handleError(form, error.response.data.result)
      })
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        onClick={() => {
          showDrawer()
        }}
      >
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
        <span className='svg-icon svg-icon-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
          >
            <rect
              opacity='0.5'
              x='11.364'
              y='20.364'
              width={16}
              height={2}
              rx={1}
              transform='rotate(-90 11.364 20.364)'
              fill='black'
            />
            <rect x='4.36396' y='11.364' width={16} height={2} rx={1} fill='black' />
          </svg>
        </span>
        {/*end::Svg Icon*/}Add Amenities
      </button>
      <Drawer
        title='Basic Drawer'
        placement='right'
        onClose={onClose}
        visible={visible}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={form.submit} type='primary'>
              {loading && (
                <i className='fa fa-spinner fa-spin' style={{marginRight: 10, color: 'white'}} />
              )}
              Create
            </Button>
          </Space>
        }
      >
        <Form form={form} onFinish={onFinish}>
          <label className='form-label'>Enter Title</label>
          <Form.Item name='name' rules={[{required: true, message: 'Title is required!'}]}>
            <input className='form-control form-control-solid' placeholder='Free Wifi' />
          </Form.Item>
          <div className='row justify-content-between align-items-center'>
            <div className='col'>
              <label className='form-label'>Select Icon</label>
            </div>
            <div className='col'>
              {selectedIcon && (
                <i className={`fas fa-${selectedIcon} m-4`} style={{fontSize: '4rem'}} />
              )}
            </div>
          </div>
          <Form.Item name='icon'>
            <AutoComplete
              options={options}
              style={{display: 'block'}}
              filterOption={(inputValue, option: any) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onSelect={(str) => {
                setSelectedIcon(str)
                form.resetFields(['icon'])
              }}
            >
              <input className='form-control form-control-solid' placeholder='wifi' />
              {selectedIcon && (
                <i className={`fas fa-${selectedIcon}`} style={{fontSize: '2rem'}} />
              )}
            </AutoComplete>
          </Form.Item>
          <label className='form-label'>Description</label>
          <Form.Item
            name='description'
            rules={[{required: true, message: 'Description is required!'}]}
          >
            <textarea
              rows={4}
              className='form-control form-control-solid'
              placeholder='Describe the service...'
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  )
}

export default ModalAddAmenities
