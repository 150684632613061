/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {PageTitle} from '../../../../_metronic/layout/core'
import {SalesStatistics} from './components/SalesStatistics'
import {DailySales} from './components/DailySales'
import {MonthlySales} from './components/MonthlySales'
import {getDashboard} from './api/dashboard'
import {IDashboardModel} from './interface/IDashboardModel'
import {RecentProducts} from './components/RecentProduct'
import {TopDeals} from './components/TopDeals'
import {OrderStatistics} from './components/OrderStatistics'
import {Notification} from '../../../components/dashboard/Notification'

const DashboardPage: FC = () => {
  const [dashboard, setDashboard] = useState<IDashboardModel>({
    total_number_order_sales: 0,
    total_order_sales: 0,
    daily_sales: [],
    monthly_sales: [],
    recent_product: [],
    top_deals: [],
    notification: [],
  })
  useEffect(() => {
    getDashboard()
      .then((data) => {
        // setLoading(false)
        setDashboard(data)
      })
      .catch((err) => {
        // setLoading(false)
        console.log(err)
        toast.error('Error!')
      })
  }, [])
  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <SalesStatistics
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            strokeColor='#cb1e46'
            total_number_order_sales={dashboard.total_number_order_sales}
            total_order_sales={dashboard.total_order_sales}
          />
        </div>
        <div className='col-xxl-4'>
          <Notification className='card-xxl-stretch' notification={dashboard.notification} />
        </div>
        <div className='col-xxl-4'>
          {/* {dashboard.daily_sales.length > 0 && ( */}
          <DailySales
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            daily_sales={dashboard.daily_sales}
          />
          {/* )} */}
          {/* {dashboard.monthly_sales.length > 0 && ( */}
          <MonthlySales
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
            monthly_sales={dashboard.monthly_sales}
          />
          {/* )} */}
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <OrderStatistics className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-8'>
          <TopDeals className='card-xxl-stretch mb-5 mb-xxl-8' deals={dashboard.top_deals} />
        </div>
        <div className='col-xxl-4'>
          <RecentProducts
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
            product={dashboard.recent_product}
          />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
