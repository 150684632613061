import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import CreateMerchant from './merchant/CreateMerchant'
import EditMerchant from './merchant/EditMerchant'
import ListMerchant from './merchant/ListMerchant'
import ViewMerchant from './merchant/ViewMerchant'

const MerchantPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/merchant/add'>
          <PageTitle breadcrumbs={[]}>Create Merchant</PageTitle>
          <CreateMerchant />
        </Route>
        <Route path='/merchant/edit/:id'>
          <PageTitle breadcrumbs={[]}>Update Merchant</PageTitle>
          <EditMerchant />
        </Route>
        <Route path='/merchant/view/:id'>
          <PageTitle breadcrumbs={[]}>View Merchant</PageTitle>
          <ViewMerchant />
        </Route>
        <Route path='/merchant/:pageNo'>
          <PageTitle breadcrumbs={[]}>List Merchant</PageTitle>
          <ListMerchant />
        </Route>
        <Redirect from='/merchant' exact={true} to='/merchant/1' />
        <Redirect to='/merchant/1' />
      </Switch>
    </>
  )
}

export default MerchantPage
