/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {capitalize} from '../../../../../utils/capitalize'
import {parseDate} from '../../../../../utils/dateAndTime'
import {KTSVG} from '../../../../../_metronic/helpers'
import NoData from '../../../../components/common/NoData'
import ModalDelete from '../../../../components/Modals/ModalDelete'
import Pagination from '../../../../components/Pagination'
import Search from '../../../../components/Search'
import {ProductListModel} from '../../../product/interface/Product'
import {deleteProduct, getAllProduct, searchProduct} from '../api/product'
import {filterValues, IFilterParams} from '../interface/IFilterParams'

interface IPageParams {
  page: string
}

const ListProduct: FC = () => {
  const [productList, setProductList] = useState<ProductListModel>()
  const [deleteId, setDeleteId] = useState<string>('')
  const {page} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [filterParams, setFilterParams] = useState<IFilterParams>(filterValues)

  function getProducts(filterParams: IFilterParams) {
    getAllProduct(filterParams)
      .then((data) => {
        setLoading(false)
        console.log(data)
        setProductList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }
  useEffect(() => {
    getProducts(filterParams)
  }, [filterParams])

  useEffect(() => {
    setFilterParams({...filterParams, page})
  }, [page])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteProduct(id).then((data) => {
      toast.success('Success!')
      getProducts(filterParams)
    })
  }

  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card header*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}
                <Search
                  name={filterParams?.search!}
                  placeholder='product'
                  handleSearch={handleFilter}
                />
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Toolbar*/}
                <div className='d-flex justify-content-end' data-kt-product-table-toolbar='base'>
                  <Link to='/product/add' className='btn btn-primary'>
                    Add Product
                  </Link>
                  {/*end::Toolbar*/}
                  {/*begin::Modal - Delete*/}
                  <ModalDelete id={deleteId} handleDelete={handleDelete} />
                  {/*end::Modal - delete*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_products_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <div className='container'>
                      <div className='text-center'>
                        <span className='spinner-border spinner-border-lg align-middle ms-2' />
                      </div>
                    </div>
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Product
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex={0}
                            aria-controls='kt_table_products'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '134.65px'}}
                            aria-label='Role: activate to sort column ascending'
                          >
                            Original Price
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_products'
                            style={{width: '140.417px'}}
                          >
                            Total Stock
                          </th>
                          {/* <th
                              className='min-w-125px'
                              aria-controls='kt_table_products'
                              style={{width: '140.417px'}}
                            >
                              Variation
                            </th> */}
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_products'
                            style={{width: '140.417px'}}
                            aria-label='Joined Date: activate to sort column ascending'
                          >
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '106.433px'}}
                            aria-label='Actions'
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {productList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {productList.data.length === 0 && <NoData />}
                          {productList.data.length !== 0 &&
                            productList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Product=*/}
                                  <td>
                                    {/*begin:: Avatar */}
                                    <div className='d-flex align-items-center'>
                                      <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                        <Link to={`/product/view/${item._id}`}>
                                          <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                            {item.image ? (
                                              <img
                                                src={item.image}
                                                alt='banner'
                                                height='50px'
                                                width='50px'
                                              />
                                            ) : (
                                              <KTSVG path='/media/icons/duotune/general/box2.svg' />
                                            )}
                                          </div>
                                        </Link>
                                      </div>
                                      {/*end::Avatar*/}
                                      {/*begin::Product details*/}
                                      <div className='d-flex flex-column'>
                                        <Link
                                          to={`/product/view/${item._id}`}
                                          className='text-gray-800 text-hover-primary mb-1'
                                        >
                                          {item.name}
                                        </Link>
                                        <span>{item.description.slice(0, 40)}...</span>
                                      </div>
                                    </div>
                                    {/*begin::Product details*/}
                                  </td>
                                  {/*end::Product=*/}
                                  {/*begin::actual price*/}
                                  <td>{item.actual_price}</td>
                                  {/*begin::actual price*/}
                                  {/*begin::end date*/}
                                  <td>{item.stock ?? 0}</td>
                                  {/* {item.variation && item.variation!.length > 0
                                        ? item.variation?.map((item: any, index) => (
                                          <div key={index}>
                                            {Object.keys(item)
                                              .slice(0, 3)
                                              .map((e) => (
                                                <div className='text-gray-600'>
                                                  {capitalize(e)}: {item[e]}
                                                </div>
                                              ))}
                                          </div>
                                        ))
                                        : 'No variation'}
                                    </td>
                                    {/*begin::end date*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Link
                                      to={`/product/edit/${item._id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='product'
                  currentPage={page}
                  prev_page={productList?.prev_page}
                  next_page={productList?.next_page}
                  total_number_of_page={productList?.total_number_of_page}
                />
                {/*end::Table*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Post*/}
      </div>
    </div>
  )
}

export default ListProduct
