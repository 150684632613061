import axios from 'axios'
import {FaqListModel, FaqModelResult, FaqModel, FaqQuery} from '../interface/IFaq'

const API_URL = process.env.REACT_APP_API_URL

const FAQ_GETALL_URL = `${API_URL}/faqs/`
const FAQ_REQ_URL = `${API_URL}/faq/`

export const getAllFaq = async (params: FaqQuery): Promise<FaqListModel> => {
  const {sort_by, order, search, filter_by = 'all', page = 1} = params
  const res = await axios.get(
    FAQ_GETALL_URL +
      `${filter_by}?page=${page}&sort_by=${sort_by}&order=${order}&search_text=${search}`
  )
  return res.data.result
}

export const getFaq = (id: string): Promise<FaqModelResult> => {
  return axios.get(FAQ_REQ_URL + id)
}

export const postFaq = (data: FaqModel): Promise<FaqModelResult> => {
  return axios.post(FAQ_REQ_URL, data)
}

export const putFaq = (id: string, data: FaqModel): Promise<FaqModelResult> => {
  return axios.put(FAQ_REQ_URL + id, data)
}

export const deleteFaq = (id: string): Promise<FaqModelResult> => {
  return axios.delete(FAQ_REQ_URL + id)
}
