import React, {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {Moment} from 'moment'
import {connect} from 'react-redux'
import {Alert, AutoComplete, Col, DatePicker, Empty, Row, Switch, Tooltip} from 'antd'
import {RangeValue} from 'rc-picker/lib/interface'
import {parseDate} from '../../../../../utils/dateAndTime'
import {DealListModel} from '../../../../components/deal/InterfaceDeal'
import Status from '../../../../components/deal/Status'
import ModalDelete from '../../../../components/Modals/ModalDelete'
import Pagination from '../../../../components/Pagination'
import Search from '../../../../components/Search'
import {deleteDeal, getAllDeal, patchDeal} from '../../deal-management/api/deal'

import {RootState} from '../../../../../setup'
import * as auth from '../../../../modules/auth/redux/AuthRedux'
import NoData from '../../../../components/common/NoData'
import Spinner from '../../../../components/Spinner'
import {IFilterParams} from '../interface/IFilterParams'
import {getProductsForFilter} from '../api/deal'

const {RangePicker} = DatePicker

interface IPageParams {
  pageNo: string
}

const filterValues = {
  page: '1',
  filterBy: 'all',
  search: '',
  date: '',
  product_id: '',
}

const ListDeal: FC = ({
  auth: {
    user: {
      result: {is_verified},
    },
  },
}: any) => {
  const [dealList, setDealList] = useState<DealListModel>()
  // const [search, setSearch] = useState<string>('')
  const [deleteId, setDeleteId] = useState<string>('')
  const {pageNo} = useParams<IPageParams>()
  // const [page, setPage] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState([{value: 'Var'}, {value: 'Per'}])
  const [filterParams, setFilterParams] = useState<IFilterParams>(filterValues)

  function getDeals(filterParams: IFilterParams) {
    setLoading(true)
    getAllDeal(filterParams)
      .then((data) => {
        setDealList(data)
      })
      .catch((err) => {
        toast.error('Error!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function initializeProducts() {
    getProductsForFilter()
      .then((data) => {
        const res = data.data.result
        setProducts([
          ...res.map((e) => {
            return {value: e.name, id: e._id}
          }),
        ])
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (is_verified) {
      getDeals(filterParams)
    }
  }, [filterParams, is_verified])

  useEffect(() => {
    setFilterParams({...filterParams, page: pageNo})
  }, [pageNo])

  useEffect(() => {
    initializeProducts()
  }, [])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteDeal(id).then((data) => {
      toast.success('Success!')
      getDeals(filterParams)
    })
  }
  function onChange(checked: boolean, id: string) {
    patchDeal(id, checked).then((data) => {
      toast.success('Success!')
    })
  }

  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }

  const handleDatePickerChange = (date: RangeValue<Moment>, dateString: [string, string]) => {
    setFilterParams({...filterParams, date: dateString})
  }

  return is_verified ? (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card header*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}
                <Row gutter={[16, 24]} className='mb-4'>
                  <Col className='gutter-row'>
                    <Search
                      name={filterParams?.search!}
                      placeholder='deal'
                      handleSearch={handleFilter}
                    />
                  </Col>

                  <Col>
                    <select
                      className='form-select form-select-solid'
                      data-placeholder='Select option'
                      defaultValue='all'
                      value={filterParams.filterBy}
                      onChange={(event) => {
                        setFilterParams({...filterParams, page: '1', filterBy: event.target.value})
                      }}
                    >
                      <option value='all' disabled>
                        Filter Status
                      </option>
                      <option value='1'>TO BE APPROVED</option>
                      <option value='2'>REJECTED</option>
                      <option value='3'>APPROVED</option>
                      {/* <option value='4'>EXPIRED</option> */}
                    </select>
                  </Col>

                  <Col className='gutter-row'>
                    <RangePicker
                      className='form-control form-control-solid me-2'
                      style={{display: 'inline-flex'}}
                      onChange={(date, dateString) => handleDatePickerChange(date, dateString)}
                    />
                  </Col>
                  <Col className='gutter-row'>
                    <AutoComplete
                      options={products}
                      style={{display: 'block'}}
                      filterOption={(inputValue, option) => {
                        return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }}
                      onSelect={(value, options) => {
                        setFilterParams({...filterParams, page: '1', productId: options.id})
                      }}
                    >
                      <input
                        className='form-control form-control-solid w-250px me-2'
                        placeholder='Search Products'
                      />
                    </AutoComplete>
                  </Col>
                  <Col className='gutter-row'>
                    <button
                      type='submit'
                      className='btn btn-flex btn-light btn-active-primary fw-bolder me-2'
                      onClick={() => {
                        setFilterParams({
                          ...filterParams,
                          filterBy: 'all',
                          date: '',
                          search: '',
                        })
                      }}
                    >
                      Reset
                    </button>
                  </Col>
                </Row>

                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Toolbar*/}
                <div className='d-flex justify-content-end' data-kt-deal-table-toolbar='base'>
                  <Link to='/deal/add' className='btn btn-primary'>
                    Add Deal
                  </Link>
                  {/*end::Toolbar*/}
                  {/*begin::Modal - Delete*/}
                  <ModalDelete id={deleteId} handleDelete={handleDelete} />
                  {/*end::Modal - delete*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_deals_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Deal
                          </th>
                          <th className='min-w-125px' style={{width: '134.65px'}}>
                            Status
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Date of Creation
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            style={{width: '250.433px'}}
                            aria-label='Actions'
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {dealList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {dealList.data.length === 0 && <NoData />}
                          {dealList.data.length !== 0 &&
                            dealList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Deal=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to={`/deal/view/${item._id}`}>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.images.length ? (
                                            <img
                                              src={item.images[0]}
                                              alt='banner'
                                              height='50px'
                                              width='50px'
                                            />
                                          ) : (
                                            'N'
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Deal details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/deal/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.title}
                                      </Link>
                                      {/* <span>{item.description.slice(0, 40)}...</span> */}
                                    </div>
                                    {/*begin::Deal details*/}
                                  </td>
                                  {/*end::Deal=*/}
                                  {/*begin::sub title*/}
                                  <td>
                                    <Status status={item.is_approved} />
                                  </td>
                                  {/*begin::sub title*/}
                                  {/*begin::end date*/}
                                  {/*begin::end date*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Tooltip placement='top' title={'Show/Hide Deal '}>
                                      <Switch
                                        defaultChecked={item.is_enabled || false}
                                        onChange={(checked) => onChange(checked, item._id!)}
                                        className='me-3'
                                      />
                                    </Tooltip>
                                    <Link
                                      to={`/deal/changestock/${item._id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Stock In/Out
                                    </Link>
                                    <Link
                                      to={`/deal/edit/${item._id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='deal'
                  currentPage={filterParams.page}
                  prev_page={dealList?.prev_page}
                  next_page={dealList?.next_page}
                  total_number_of_page={dealList?.total_number_of_page}
                />
                {/*end::Table*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Post*/}
      </div>
    </div>
  ) : (
    <div className='card card-custom d-flex p-5' style={{minHeight: '60vh'}}>
      <Alert
        message='Unverified Account'
        description='Please wait for your account to be verified by the admin to add/list deals.'
        type='error'
        showIcon
      />
      <Empty style={{marginTop: '25vh'}} description={false} />
    </div>
  )
}

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)

export default connector(ListDeal)
