import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditCategory from './parent/EditCategory'
import ViewCategory from './parent/ViewCategory'
import ListCategory from './parent/ListCategory'
import CreateEditCategory from './components/CreateEditCategory'

const SubCategoryPage: React.FC = () => {
  const LEVEL = 2
  return (
    <>
      <Switch>
        <Route path='/subcategory/add'>
          <PageTitle breadcrumbs={[]}>Add Category </PageTitle>
          <CreateEditCategory level={LEVEL} />
        </Route>
        <Route path='/subcategory/edit/:id'>
          <PageTitle breadcrumbs={[]}>Edit Category</PageTitle>
          <CreateEditCategory level={LEVEL} />
          {/* <EditCategory level={LEVEL} /> */}
        </Route>
        <Route path='/subcategory/view/:id'>
          <PageTitle breadcrumbs={[]}>View Category</PageTitle>
          <ViewCategory level={LEVEL} />
        </Route>
        <Route path='/subcategory/:pageNo'>
          <PageTitle breadcrumbs={[]}>List Category</PageTitle>
          <ListCategory level={LEVEL} />
        </Route>
        <Redirect from='/subcategory' exact={true} to='/subcategory/1' />
        <Redirect to='/subcategory/1' />
      </Switch>
    </>
  )
}

export default SubCategoryPage
