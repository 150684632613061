import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import Editnewsletter from './newsletter/EditNewsletter'
import Listnewsletter from './newsletter/ListNewsletter'

const newsletterPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/newsletter/edit/:id'>
          <PageTitle breadcrumbs={[]}>Newsletter Edit</PageTitle>
          <Editnewsletter />
        </Route>
        <Route path='/newsletter/:page'>
          <PageTitle breadcrumbs={[]}>Newsletter List</PageTitle>
          <Listnewsletter />
        </Route>
        <Redirect from='/newsletter' exact={true} to='/newsletter/1' />
        <Redirect to='/newsletter/1' />
      </Switch>
    </>
  )
}

export default newsletterPage
