import {FC} from 'react'

interface IPageParams {
  status: string
}
const Status: FC<IPageParams> = ({status}) => {
  return (
    <>
      {status === '1' ? (
        <div className='badge badge-light-primary fw-bolder'>TO BE APPROVED</div>
      ) : status === '2' ? (
        <div className='badge badge-light-danger fw-bolder'>REJECTED</div>
      ) : status === '3' ? (
        <div className='badge badge-light-success fw-bolder'>APPROVED</div>
      ) : status === '4' ? (
        <div className='badge badge-light-warning fw-bolder'>EXPIRED</div>
      ) : (
        'Null'
      )}
    </>
  )
}
export default Status
