import {FC} from 'react'
import {IBillingAddress} from '../../../../interface/IAddress'
import ViewItem from '../../ViewItem'
interface Props {
  value: IBillingAddress
}
const BillingAddress: FC<Props> = ({value}) => {
  const {
    full_name,
    company_name,
    country,
    address,
    province,
    district,
    region,
    email,
    phone_number,
  } = value

  return (
    <div className='collapse show'>
      <div className='pb-5 fs-6'>
        <ViewItem name={'Full Name'} value={full_name} />
        <ViewItem name={'Email'} value={email} />
        <ViewItem name={'Phone Number'} value={phone_number} />
        <ViewItem name={'Company Name'} value={company_name} />
        <ViewItem name={'Country'} value={country} />
        <ViewItem name={'Address'} value={address} />
        <ViewItem name={'Province'} value={province} />
        <ViewItem name={'District'} value={district} />
        <ViewItem name={'Region'} value={region} />
      </div>
    </div>
  )
}

export default BillingAddress
