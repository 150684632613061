/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {getAllOrder, searchOrder} from '../api/order'
import {useParams} from 'react-router-dom'
import {Col, DatePicker, Row} from 'antd'
import {Moment} from 'moment'
import {toast} from 'react-toastify'
import {RangeValue} from 'rc-picker/lib/interface'
import {OrderListModel} from '../../../order-management/interface/Order'
import Pagination from '../../../../components/Pagination'
import {ListOrder as LOrder} from '../../../../components/common/order/ListOrder'
import Search from '../../../../components/Search'
import Spinner from '../../../../components/Spinner'

const {RangePicker} = DatePicker

interface IPageParams {
  pageNo: string
}

const ListOrder: FC = () => {
  const [orderList, setOrderList] = useState<OrderListModel>()
  const [page, setPage] = useState<string>('')
  const {pageNo} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [filterBy, setFilterBy] = useState<string>('all')
  const [date, setDate] = useState<string[] | string>('')
  const [search, setSearch] = useState<string>('')

  function getOrder(page: string, filterBy?: string, date?: string | string[], search?: string) {
    getAllOrder(page, filterBy, date, search)
      .then((data) => {
        setLoading(false)
        setOrderList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }
  useEffect(() => {
    getOrder(page, filterBy, date, '')
  }, [page, filterBy, date])

  useEffect(() => {
    setPage(pageNo)
  }, [pageNo])

  const handleDatePickerChange = (date: RangeValue<Moment>, dateString: [string, string]) => {
    setDate(dateString)
  }

  function handleSearch(event: React.ChangeEvent<any>) {
    event.preventDefault()
    const {value} = event.target
    setSearch(value)
    if (value === '') {
      getOrder(page, filterBy, date, '')
      return
    }
    getOrder(page, filterBy, date, value)
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}

                <Row gutter={[16, 24]} className='mb-4'>
                  <Col className='gutter-row'>
                    <Search name={search} placeholder='order id' handleSearch={handleSearch} />
                  </Col>
                  <Col className='gutter-row'>
                    <RangePicker
                      className='form-control form-control-solid'
                      style={{display: 'inline-flex'}}
                      onChange={(date, dateString) => handleDatePickerChange(date, dateString)}
                    />
                  </Col>
                </Row>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Toolbar*/}
                <div className='d-flex justify-content-end' data-kt-order-table-toolbar='base'>
                  <button
                    type='submit'
                    className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder me-2'
                    onClick={() => {
                      setFilterBy('all')
                      setDate('')
                    }}
                  >
                    Reset
                  </button>

                  <select
                    className='form-select form-select-solid'
                    data-placeholder='Select option'
                    defaultValue='all'
                    value={filterBy}
                    onChange={(event) => {
                      setPage('1')
                      setFilterBy(event.target.value)
                    }}
                  >
                    <option value='all' disabled>
                      Filter Status
                    </option>
                    <option value='ACKNOWLEDGED'>Acknowledged</option>
                    <option value='PACKAGING'>PACKAGING</option>
                    <option value='SHIPPED'>Shipped</option>
                    <option value='DELIVERED'>Delivered</option>
                    <option value='CANCELED'>Canceled</option>
                    <option value='REJECTED'>Rejected</option>
                  </select>
                </div>
                {/*end::Toolbar*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_orders_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? <Spinner /> : <LOrder orderList={orderList} isAdmin={false} />}
                </div>
                <Pagination
                  name='order'
                  currentPage={page}
                  prev_page={orderList?.prev_page}
                  next_page={orderList?.next_page}
                  total_number_of_page={orderList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListOrder
