import React, {FC, useState} from 'react'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {postFeatureAd} from '../api/feature-ad'
import {toast} from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import {getValidUrl} from '../../../../utils'

const featureAdSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  offer: Yup.number().required('Offer is required'),
  callback: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'The url must be valid.'
    )
    .required('Callback is required'),
  callback_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Callback name is required'),
  file: Yup.string().required('Image is required'),
})

interface ModalProps {
  handleGetFeatureAd: () => void
}

const ModalAddFeatureAd: FC<ModalProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const toogleModal = () => {
    setIsOpen(!isOpen)
  }

  const initialValues = {
    name: '',
    offer: 0,
    callback: '',
    callback_name: '',
    is_featured: false,
    file: '',
    color: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: featureAdSchema,
    validateOnMount: true,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      const newValues = {
        name: values.name,
        offer: values.offer,
        callback: getValidUrl(values.callback),
        callback_name: values.callback_name,
        is_featured: values.is_featured,
        color: values.color,
        image: values.file,
      }
      postFeatureAd(newValues)
        .then(() => {
          setLoading(false)
          toast.success('Success!')
          resetForm({})
          props.handleGetFeatureAd()
          toogleModal()
        })
        .catch((error) => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Failed')
        })
    },
  })
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        onClick={() => {
          toogleModal()
        }}
      >
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
        <span className='svg-icon svg-icon-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
          >
            <rect
              opacity='0.5'
              x='11.364'
              y='20.364'
              width={16}
              height={2}
              rx={1}
              transform='rotate(-90 11.364 20.364)'
              fill='black'
            />
            <rect x='4.36396' y='11.364' width={16} height={2} rx={1} fill='black' />
          </svg>
        </span>
        {/*end::Svg Icon*/}Add Feature Ad
      </button>
      {/*begin::Add feature ad*/}
      <Modal
        show={isOpen}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        dialogClassName='mw-650px'
      >
        {/*begin::Modal header*/}
        <div className='modal-header' id='kt_modal_add_feature_ad'>
          {/*begin::Modal title*/}
          <h2 className='fw-bolder'>Add Feature Ad</h2>
          {/*end::Modal title*/}
          {/*begin::Close*/}
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-users-modal-action='close'
            onClick={toogleModal}
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x={6}
                  y='17.3137'
                  width={16}
                  height={2}
                  rx={1}
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y={6}
                  width={16}
                  height={2}
                  rx={1}
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Modal header*/}
        {/*begin::Modal body*/}
        <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
          {/*begin::Form*/}
          <form
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_feature_ad_form'
            onSubmit={formik.handleSubmit}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            {/*begin::Scroll*/}
            <div
              className='d-flex flex-column scroll-y me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-offset='300px'
              style={{maxHeight: '411px'}}
            >
              {/* begin::Form group Name */}
              <div className='row fv-row mb-7'>
                <div className='col-xl-12'>
                  <label className='form-label fw-bolder text-dark fs-6'>Name</label>
                  <input
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Form group Offer Percent*/}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Offer Percent</label>
                <input
                  type='number'
                  autoComplete='off'
                  {...formik.getFieldProps('offer')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.offer && formik.errors.offer,
                    },
                    {
                      'is-valid': formik.touched.offer && !formik.errors.offer,
                    }
                  )}
                />
                {formik.touched.offer && formik.errors.offer && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.offer}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group Callback */}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Callback</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('callback')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.callback && formik.errors.callback,
                    },
                    {
                      'is-valid': formik.touched.callback && !formik.errors.callback,
                    }
                  )}
                />
                {formik.touched.callback && formik.errors.callback && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.callback}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group Callback Name*/}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Callback Name</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('callback_name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.callback_name && formik.errors.callback_name,
                    },
                    {
                      'is-valid': formik.touched.callback_name && !formik.errors.callback_name,
                    }
                  )}
                />
                {formik.touched.callback_name && formik.errors.callback_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.callback_name}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group Color */}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Color</label>

                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  {...formik.getFieldProps('color')}
                >
                  <option value='' disabled>
                    Choose Position
                  </option>
                  <option value='primary'>Primary</option>
                  <option value='warning'>Warning</option>
                  <option value='danger'>Danger</option>
                </select>
                {formik.touched.color && formik.errors.color && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.color}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
              {/* begin::Form group Is Featured */}
              <div className='fv-row mb-7'>
                <label className='form-check form-check-inline form-check-solid'>
                  <span className='fw-bold ps-2 fs-6'>Featured</span>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='flexCheckChecked'
                    {...formik.getFieldProps('is_featured')}
                  />
                </label>
              </div>
              {/* end::Form group */}

              {/* begin::Form group Image*/}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6 me-3'>Image</label>
                <input
                  type='file'
                  {...formik.getFieldProps('image')}
                  accept='.png, .jpg, .jpeg'
                  // ref={inputRef}
                  // style={{display: 'none'}}
                  onChange={async (event: React.ChangeEvent<any>) => {
                    // formik.setFieldValue('file', event.currentTarget.files[0])
                    let formdata = new FormData()
                    formdata.append('file', event.currentTarget.files[0])
                    try {
                      const url: string = process.env.REACT_APP_IMAGE_UPLOAD as string
                      const res = await fetch(url, {
                        method: 'post',
                        body: formdata,
                      })
                      const data = await res.json()
                      if (data.status_code === 201) {
                        formik.setFieldValue('file', data['url'])
                      }
                    } catch (err) {
                      console.log(err)
                    }
                  }}
                />
                {formik.errors.file && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.file}</span>
                    </div>
                  </div>
                )}
                {/* <button className='btn btn-primary' onClick={() => inputRef.current?.click()}>
                  Choose
                </button> */}
              </div>
              {/* end::Form group */}

              {/*begin::Actions*/}
              <div className='text-center pt-15'>
                <button type='reset' className='btn btn-light me-3' onClick={toogleModal}>
                  Cancel
                </button>
                <button
                  type='submit'
                  disabled={formik.isSubmitting || !formik.isValid}
                  className='btn btn-primary'
                  data-kt-users-modal-action='submit'
                >
                  {!loading ? (
                    <span className='indicator-label'>Submit</span>
                  ) : (
                    <span className='indicator-label'>
                      Submit
                      <span className='spinner-border spinner-border-sm align-middle ms-2' />
                    </span>
                  )}
                </button>
              </div>
              {/*end::Actions*/}
            </div>
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Modal body*/}
      </Modal>
      {/*end::Add feature ad*/}
    </>
  )
}

export default ModalAddFeatureAd
