/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {deleteProduct, getAllProduct} from '../api/product'
import {Link, useParams} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {ProductListModel} from '../interface/Product'
import {toast} from 'react-toastify'
import Pagination from '../../../components/Pagination'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Search from '../../../components/Search'
import handleError from '../../../components/handleError'
import {AutoComplete, Col, Row} from 'antd'
import useMerchant from '../../../components/useMerchant'
import Spinner from '../../../components/Spinner'
import NoData from '../../../components/common/NoData'

interface IPageParams {
  pageNo: string
}

const ListProduct: FC = () => {
  const [productList, setProductList] = useState<ProductListModel>()
  const [search, setSearch] = useState<string>('')
  const [deleteId, setDeleteId] = useState<string>('')
  let {pageNo} = useParams<IPageParams>()
  const [page, setPage] = useState<string>('1')
  const [loading, setLoading] = useState<boolean>(true)
  const [merchants] = useMerchant()
  const [merchantId, setMerchantId] = useState<string>('')

  function getProducts() {
    getAllProduct(page, {search, merchantId})
      .then((data) => {
        setLoading(false)
        setProductList(data)
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast.error('Error!')
      })
  }
  useEffect(() => {
    getProducts()
  }, [page, merchantId])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteProduct(id)
      .then((data) => {
        toast.success('Success!')
        getProducts()
      })
      .catch((error) => {
        handleError(null, error.response.data.result)
      })
  }
  function handleSearch(event: React.ChangeEvent<any>) {
    event.preventDefault()
    const {value} = event.target
    setSearch(value)
    if (value === '') {
      getProducts()
      return
    }
    getAllProduct(page, {search, merchantId}).then((data) => {
      setProductList(data)
    })
  }

  useEffect(() => {
    setPage(pageNo)
  }, [pageNo])

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card header*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}

                <Row gutter={[16, 24]} className='mb-4'>
                  <Col className='gutter-row'>
                    <Search name={search} placeholder='product' handleSearch={handleSearch} />
                  </Col>
                  <Col className='gutter-row'>
                    <AutoComplete
                      options={merchants}
                      style={{display: 'block'}}
                      filterOption={(inputValue, option) => {
                        return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }}
                      onSelect={(value, options) => {
                        setPage('1')
                        setMerchantId(options.id)
                      }}
                    >
                      <input
                        className='form-control form-control-solid w-250px'
                        placeholder='Search Merchants'
                      />
                    </AutoComplete>
                  </Col>
                </Row>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Modal - Delete*/}
                <ModalDelete id={deleteId} handleDelete={handleDelete} />
                {/*end::Modal - delete*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_products_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Product
                          </th>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Merchant
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex={0}
                            aria-controls='kt_table_products'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '134.65px'}}
                            aria-label='Role: activate to sort column ascending'
                          >
                            Original Price
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_products'
                            style={{width: '140.417px'}}
                          >
                            Total Stock
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_products'
                            style={{width: '140.417px'}}
                            aria-label='Joined Date: activate to sort column ascending'
                          >
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '106.433px'}}
                            aria-label='Actions'
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {productList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {productList.data.length === 0 && <NoData />}
                          {productList.data.length !== 0 &&
                            productList.data.map((item) => {
                              console.log(item)
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Product=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to={`/product/view/${item._id}`}>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.image ? (
                                            <img
                                              src={item.image}
                                              alt='banner'
                                              height='50px'
                                              width='50px'
                                            />
                                          ) : (
                                            item.name[0]
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Product details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/product/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.name}
                                      </Link>
                                      <span>{item.description.slice(0, 40)}...</span>
                                    </div>
                                    {/*begin::Product details*/}
                                  </td>
                                  {/*end::Product=*/}
                                  <td>{item.merchantId.display_name ?? 'No Merchant'}</td>
                                  {/*begin::actual price*/}
                                  <td>{item.actual_price}</td>
                                  {/*begin::actual price*/}
                                  {/*begin::end date*/}
                                  <td>{item.stock ?? 0}</td>
                                  {/*begin::end date*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='product'
                  currentPage={page}
                  prev_page={productList?.prev_page}
                  next_page={productList?.next_page}
                  total_number_of_page={productList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListProduct
