import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditAmenities from './amenities/EditAmenities'
import ViewAmenities from './amenities/ViewAmenities'
import ListAmenities from './amenities/ListAmenities'

const AmenitiesPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/amenities/edit/:id'>
          <PageTitle breadcrumbs={[]}>Amenities Edit</PageTitle>
          <EditAmenities />
        </Route>
        <Route path='/amenities/view/:id'>
          <PageTitle breadcrumbs={[]}>Amenities View</PageTitle>
          <ViewAmenities />
        </Route>
        <Route path='/amenities/:page'>
          <PageTitle breadcrumbs={[]}>Amenities List</PageTitle>
          <ListAmenities />
        </Route>
        <Redirect from='/amenities' exact={true} to='/amenities/1' />
        <Redirect to='/amenities/1' />
      </Switch>
    </>
  )
}

export default AmenitiesPage
