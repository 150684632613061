/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useState} from 'react'
import {parseDate} from '../../../../utils/dateAndTime'
import {capitalize} from '../../../../utils/capitalize'
import {OrderModel} from '../../../modules/order-management/interface/Order'
import ViewItem from '../ViewItem'
import ModalOrder from './ModalOrder'
import OrderStatus from './OrderStatus'
import ReactToPrint from 'react-to-print'
import PrintedInvoice from './print/PrintedInvoice'
import OrderInformation from './partial/OrderInformation'
import ShippingAddress from './partial/ShippingAddress'
import BillingAddress from './partial/BillingAddress'
import GoBack from '../../partials/GoBack'
import ViewCard from '../../ViewCard'

export interface IPageParams {
  id: string
}
interface Props {
  order: OrderModel
  isAdmin: Boolean
  handleUpdateOrder: (id: string, itemId: string, status: string) => void
}
const URL = '/order'
const ViewOrder: FC<Props> = ({order, isAdmin, handleUpdateOrder}) => {
  // const {id} = useParams<IPageParams>()
  const [itemId, setItemId] = useState('')
  const componentRef = useRef<any>()

  function handleAction(event: React.ChangeEvent<any>, id: string, status: string) {
    event.preventDefault()
    handleUpdateOrder(id, itemId, status)
    // history.push('/order')
  }

  return (
    <>
      {/*begin::Card*/}
      {order && (
        <>
          <ModalOrder
            id={order._id}
            handleAction={handleAction}
            text={'Update Order Status'}
            isAdmin={isAdmin}
          />
          <div className='card mb-5 mb-xl-8'>
            {/*begin::Card body*/}
            <div className='card-body'>
              {/*begin::Details toggle*/}
              <div className='d-flex flex-stack fs-4 py-3'>
                <div className='fw-bolder rotate collapsible'>Details</div>

                <span>
                  <GoBack url={URL} />
                  {isAdmin && (
                    <>
                      <ReactToPrint
                        trigger={() => (
                          <button
                            className='btn btn-sm btn-light-primary me-2'
                            // onClick={() => setItemId(_id)}
                          >
                            Print Invoice
                          </button>
                        )}
                        content={() => componentRef.current}
                      />
                      <PrintedInvoice data={order} ref={componentRef} />
                    </>
                  )}
                </span>
              </div>
              {/*end::Details toggle*/}
              <div className='separator' />
              {/*begin::Details content*/}
              <div id='kt_order_view_details' className='collapse show'>
                <div className='pb-5 fs-6'>
                  {/*begin::Details item*/}
                  <div className='fw-bolder mt-5'>ID</div>
                  <div className='text-gray-600'>{order?._id}</div>
                  {/*end::Details item*/}
                  {/*begin::Details item*/}
                  <ViewItem name={'Payment Method'} value={order?.payment_method} />
                  {/* <ViewItem
                    name={'Paid'}
                    value={
                      <div
                        className={`badge badge-light-${
                          order?.is_paid ? 'success' : 'danger'
                        } fw-bolder`}
                      >
                        {order?.is_paid ? 'Yes' : 'No'}
                      </div>
                    }
                  /> */}
                  {isAdmin && (
                    <>
                      {order?.coupon_code && (
                        <>
                          <div className='fw-bolder mt-5'>Coupon Code</div>
                          <div className='text-gray-600'>{order?.coupon_code}</div>
                        </>
                      )}
                      {/*end::Details item*/}
                      <ViewItem name={'sub total'} value={order?.sub_total} />
                      <ViewItem name={'Shipping Cost'} value={order?.shipping_cost} />
                      <ViewItem name={'Coupon Discount'} value={order?.coupon_discount} />
                      <ViewItem name={'total'} value={order?.total} />
                      {/*begin::Details item*/}
                      <div className='fw-bolder mt-5'>Ordered By</div>
                      {order.express_checkout ? (
                        <>
                          <div className='text-gray-600'>{order.express_checkout.phone_number}</div>
                          <div className='text-gray-600'>{order.express_checkout?.email}</div>
                        </>
                      ) : (
                        <>
                          <div className='text-gray-600'>
                            {order.user_id && order.user_id.full_name}
                          </div>
                          <div className='text-gray-600'>
                            {order.user_id && order.user_id.email}
                          </div>
                        </>
                      )}
                      {/*end::Details item*/}
                    </>
                  )}
                  <ViewItem name={'Ordered Date'} value={parseDate(order?.createdAt)} />
                </div>
              </div>
              {/*end::Details content*/}
            </div>
            {/*end::Card body*/}
          </div>
        </>
      )}
      {/*end::Card*/}

      {isAdmin && (
        <>
          <ViewCard
            children={<BillingAddress value={order.billing_address} />}
            name={'Billing Address'}
          />
          <ViewCard
            children={<ShippingAddress value={order?.delivery_address} />}
            name={'Shipping Address'}
          />
        </>
      )}

      {/*begin::Card*/}
      {order.items.map((item, index) => {
        const {_id, deal_image, merchant_id, status, gift_delivery_address} = item
        return (
          <div className='collapse show' key={_id}>
            <div className='card mb-5 mb-xl-8'>
              {/*begin::Card body*/}
              <div className='card-body'>
                {/*begin::Summary*/}
                {/*begin::Order Info*/}
                <div className='d-flex flex-center flex-column py-5'>
                  {/*begin::Avatar*/}
                  <div className='symbol symbol-100px symbol-circle mb-7'>
                    <img src={deal_image} alt='avatar' />
                  </div>
                  {/*end::Avatar*/}
                  {/*begin::Name*/}
                  <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-3'>
                    {capitalize(merchant_id.display_name)}
                  </a>
                  {/*end::Name*/}
                  {/*begin::Position*/}
                  <div className='mb-9'>
                    {/*begin::Badge*/}
                    <OrderStatus status={status} />
                    {/*begin::Badge*/}
                  </div>
                  {/*end::Position*/}
                </div>
                {/*begin::Details toggle*/}
                <div className='d-flex flex-stack fs-4 py-3'>
                  <div className='fw-bolder rotate collapsible'>Order #{index + 1}</div>
                  <span>
                    <a
                      href='#'
                      className='btn btn-sm btn-light-primary me-2'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal'
                      onClick={() => setItemId(_id)}
                    >
                      Verify
                    </a>
                  </span>
                </div>
                {/*end::Details toggle*/}
                <div className='separator' />
                {/*begin::Details content*/}

                <div className='rounded border p-3'>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        data-bs-toggle='tab'
                        href={`#kt_tab_pane_1${index}`}
                      >
                        Order Information
                      </a>
                    </li>
                    {order.is_gift && gift_delivery_address && (
                      <li className='nav-item'>
                        <a
                          className='nav-link'
                          data-bs-toggle='tab'
                          href={`#kt_tab_pane_2${index}`}
                        >
                          Gift Delivery Address
                        </a>
                      </li>
                    )}
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade active show'
                      id={`kt_tab_pane_1${index}`}
                      role='tabpanel'
                    >
                      <OrderInformation value={item} is_gift={order.is_gift} />
                    </div>
                    {order.is_gift && gift_delivery_address && (
                      <div
                        className='tab-pane fade active show'
                        id={`kt_tab_pane_2${index}`}
                        role='tabpanel'
                      >
                        <ShippingAddress value={gift_delivery_address} />
                      </div>
                    )}
                  </div>
                </div>
                {/*end::Details content*/}
              </div>
            </div>
          </div>
        )
      })}
      {/*end::Card*/}
    </>
  )
}

export {ViewOrder}
