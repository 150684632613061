import axios from 'axios'
import {BannerListModel, BannerModelResult, BannerModel} from '../interface/Banner'

const API_URL = process.env.REACT_APP_API_URL

const BANNER_GETALL_URL = `${API_URL}/banners/all`
const BANNER_REQ_URL = `${API_URL}/banner/`

export const getAllBanner = async (page: string): Promise<BannerListModel> => {
  const res = await axios.get(BANNER_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getBanner = (id: string): Promise<BannerModelResult> => {
  return axios.get(BANNER_REQ_URL + id)
}

export const putBanner = (id: string, data: BannerModel): Promise<BannerModelResult> => {
  return axios.put(BANNER_REQ_URL + id, data)
}

export const postBanner = (data: BannerModel): Promise<BannerModelResult> => {
  return axios.post(BANNER_REQ_URL, data)
}

export const deleteBanner = (id: string): Promise<BannerModelResult> => {
  return axios.delete(BANNER_REQ_URL + id)
}

export const searchBanner = async (name: string): Promise<BannerListModel> => {
  const res = await axios.get(BANNER_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
