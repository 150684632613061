import {useContext, useEffect, useState} from 'react'
import {Typography} from 'antd'

import DynamicAttribute from '../partials/DynamicAttribute'
import AttributeCheckbox from '../partials/AttributeCheckbox'
import CreateEditProductContext from '../../contexts/CreateEditProductContext'

interface Props {
  variations: any
  form: any
  parentIndex?: number //index of the deal list form
  productVariation?: any
  onlyStock?: boolean
}

const getSelectedAttributes = (attributes: any) => {
  return attributes.map((_: any) => _.title)
}

const VariationForm = ({variations, form, onlyStock = false}: Props) => {
  useEffect(() => {
    if (variations && variations.length > 0) {
      setCheckedList(getSelectedAttributes(variations))
    }
  }, [variations])

  const {checkedList, setCheckedList} = useContext(CreateEditProductContext)

  useEffect(() => {
    const result = form.getFieldValue()
    if (result && result.variation && result.variation.length > 0) {
      const rest = Object.keys(result.variation[0])
        .filter((e) => e !== 'stock')
        .filter((e) => e !== 'sku')
      setCheckedList(rest)
    }
  }, [form])

  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  let options = variations.map((e: any) => e.title)

  const onChange = (list: any) => {
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < options.length)
    setCheckAll(list.length === options.length)
  }

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? options : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }
  return (
    <>
      {!onlyStock && (
        <>
          <Typography.Text>Mention the attributes that your product have.</Typography.Text>
          <AttributeCheckbox
            options={options}
            onChange={onChange}
            onCheckAllChange={onCheckAllChange}
            checkAll={checkAll}
            checkedList={checkedList}
            indeterminate={indeterminate}
          />
        </>
      )}
      <DynamicAttribute
        attributes={variations}
        form={form}
        labels={checkedList}
        // parentIndex={parentIndex}
        // productVariation={productVariation}
      />
    </>
  )
}

export default VariationForm
