import axios from 'axios'
import {DealListModel, DealResult} from '../../../components/deal/InterfaceDeal'
import {MerchantFilterList, ProductFilterList} from '../../../interface/Result'
import {IFilterParams} from '../interface/IFilterParams'

const API_URL = process.env.REACT_APP_API_URL

const DEAL_GETALL_URL = `${API_URL}/deals/`
const DEAL_REQ_URL = `${API_URL}/deal/`

export const getAllDeal = async (filterParams: IFilterParams): Promise<DealListModel> => {
  const {page, productId, merchantId, filterBy, date, search} = filterParams
  const res = await axios.get(
    DEAL_GETALL_URL +
      `${filterBy}?page=${page}&search_text=${search}&product_id=${productId}&merchant_id=${merchantId}&date=${date}`
  )
  return res.data.result
}

export const getDeal = (id: string): Promise<DealResult> => {
  return axios.get(DEAL_REQ_URL + id)
}

export const deleteDeal = (id: string): Promise<DealResult> => {
  return axios.delete(DEAL_REQ_URL + id)
}

export const searchDeal = async (name: string): Promise<DealListModel> => {
  const res = await axios.get(DEAL_GETALL_URL + `all?search_text=${name}`)
  return res.data.result
}

export const putDeal = (id: string, data: any): Promise<DealResult> => {
  return axios.put(DEAL_REQ_URL + id, data)
}

export const patchDeal = (id: string, status: Boolean): Promise<DealResult> => {
  return axios.patch(DEAL_REQ_URL + id, {status})
}

export const getMerchantsForFilter = (): Promise<MerchantFilterList> => {
  return axios.get(`${API_URL}/merchants-for-filter`)
}
export const getProductsForFilter = (): Promise<ProductFilterList> => {
  return axios.get(`${API_URL}/products-for-filter`)
}
