import {FC} from 'react'

interface IPageParams {
  name: string
  value: any
}
const ViewItem: FC<IPageParams> = ({name, value}) => {
  return (
    <>
      {value ? (
        <>
          <div className='fw-bolder mt-5'>{name.toUpperCase()}</div>
          <div className='text-gray-600'>{value}</div>
        </>
      ) : (
        ''
      )}
    </>
  )
}

export default ViewItem
