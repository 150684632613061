/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap-v5'
import {Link, useParams, useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import {parseDate} from '../../../../../../utils/dateAndTime'
import NoData from '../../../../../components/common/NoData'
import Pagination from '../../../../../components/Pagination'
import Search from '../../../../../components/Search'
import {ProductListModel} from '../../../../product/interface/Product'
import {getAllProduct} from '../../api/product'
import {IFilterParams, filterValues} from '../../interface/IFilterParams'

interface IPageParams {
  page: string
}

const StockIn: FC = () => {
  const [productList, setProductList] = useState<ProductListModel>()
  const {page} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [filterParams, setFilterParams] = useState<IFilterParams>(filterValues)
  const history = useHistory()

  function getProducts(filterParams: IFilterParams) {
    getAllProduct(filterParams)
      .then((data) => {
        setLoading(false)
        setProductList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }

  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }

  useEffect(() => {
    getProducts(filterParams)
  }, [filterParams])

  useEffect(() => {
    setFilterParams({...filterParams, page})
  }, [page])
  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card header*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}
                <Search
                  name={filterParams?.search!}
                  placeholder='product'
                  handleSearch={handleFilter}
                />
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_products_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <div className='container'>
                      <div className='text-center'>
                        <span className='spinner-border spinner-border-lg align-middle ms-2' />
                      </div>
                    </div>
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Product
                          </th>
                          <th
                            className='min-w-125px'
                            tabIndex={0}
                            aria-controls='kt_table_products'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '134.65px'}}
                            aria-label='Role: activate to sort column ascending'
                          >
                            Original Price
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_products'
                            style={{width: '140.417px'}}
                          >
                            Stock
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_products'
                            style={{width: '140.417px'}}
                            aria-label='Joined Date: activate to sort column ascending'
                          >
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '106.433px'}}
                            aria-label='Actions'
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {productList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {productList.data.length === 0 && <NoData />}
                          {productList.data.length !== 0 &&
                            productList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Product=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to={`/product/view/${item._id}`}>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.image ? (
                                            <img
                                              src={item.image}
                                              alt='banner'
                                              height='50px'
                                              width='50px'
                                            />
                                          ) : (
                                            'N'
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Product details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/product/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.name}
                                      </Link>
                                      <span>{item.description.slice(0, 40)}...</span>
                                    </div>
                                    {/*begin::Product details*/}
                                  </td>
                                  {/*end::Product=*/}
                                  {/*begin::actual price*/}
                                  <td>{item.actual_price}</td>
                                  {/*begin::actual price*/}
                                  {/*begin::end date*/}
                                  <td>{item.stock ?? 0}</td>
                                  {/*begin::end date*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Button
                                      onClick={() =>
                                        history.push({
                                          pathname: `/product/stock/add/${item._id}`,
                                          state: {variation: item.variation, id: item._id},
                                        })
                                      }
                                      className='btn text-white btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Stock In
                                    </Button>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='product/stock'
                  currentPage={page}
                  prev_page={productList?.prev_page}
                  next_page={productList?.next_page}
                  total_number_of_page={productList?.total_number_of_page}
                />
                {/*end::Table*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Post*/}
      </div>
    </div>
  )
}

export default StockIn
