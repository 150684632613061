import {FC, useEffect, useState} from 'react'
import {Switch, Alert, DatePicker, Empty, Form, Input, message, notification, Select} from 'antd'
import {useHistory} from 'react-router-dom'
import {connect} from 'react-redux'
import MultiImage from './partials/MultiImage'
import DealChoice from './partials/DealChoice'
import {getAllProduct, getProduct, getVariations} from '../../product-management/api/product'
import {ProductModel} from '../../../product/interface/Product'
import {VariationListModel} from '../../../variation-management/interface/IVariation'
import {postDeal} from '../api/deal'
import Button from '../../../../components/common/Button'
import {RootState} from '../../../../../setup'
import * as auth from '../../../../modules/auth/redux/AuthRedux'
import DealContext from '../contexts/DealContext'
import handleError from '../../../../components/handleError'

const CreateDeal: FC = ({
  auth: {
    user: {
      result: {is_verified},
    },
  },
}: any) => {
  const [form] = Form.useForm()
  const [productList, setProductList] = useState<ProductModel[]>([])
  const [applicableVariants, setApplicableVariants] = useState<VariationListModel>()
  const [appliedVariants, setAppliedVariants] = useState<any>(null)
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)
  const [checked, setChecked] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (is_verified) {
      getAllProduct({page: '', search: '', limit: 'MAX'}).then((value) => {
        setProductList(value.data)
      })
    }
  }, [])

  useEffect(() => {
    form.setFieldsValue({flash_deal: checked})
  }, [checked, form])

  function getProductDetail(id: string) {
    getProduct(id, 'false')
      .then(({data}) => {
        const {result} = data
        //variations for the product
        getVariations(result.categoryId._id)
          .then((value) => {
            setApplicableVariants(value)
          })
          .catch((err) => {
            message.error('Could not get any variation of this product!')
            console.log(err)
          })
        //selected variation of parent product
        setAppliedVariants(result.variation)
      })
      .catch((err) => {
        message.error('Error on fetching product information')
        console.log(err)
      })
  }

  // On Form Submission
  async function handleSubmit(values: any) {
    if (images.length === 0) return message.error('Please upload images for this deal in the form!')

    let payload = {
      ...values,
      images,
    }
    if (values.flash_deal) {
      payload = {
        ...payload,
        end_date: values['range'][1].toDate(),
        start_date: values['range'][0].toDate(),
      }
    }
    delete payload['range']
    try {
      setFormIsSubmitting(true)
      await postDeal(payload)
        .then((res) => {
          notification.success({message: res.data.result})
        })
        .catch((error) => {
          const res = error.response.data.result
          if (res) {
            handleError(form, res)
          }
        })
      history.push('/deal/1')
    } catch (error) {
      console.log(error)
    }
    setFormIsSubmitting(false)
  }

  // On Image Upload
  let images: string[] = []
  const allImageUrls = (values: string[]) => {
    images = values
  }

  return is_verified ? (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title'>Create New Deal</h3>
        <div className='card-toolbar'>
          <button type='button' className='btn btn-sm btn-danger me-4' onClick={history.goBack}>
            Cancel
          </button>
          <button onClick={form.submit} type='button' className='btn btn-sm btn-primary'>
            {formIsSubmitting ? 'Please Wait...' : 'Create'}
          </button>
        </div>
      </div>

      <DealContext.Provider
        value={{changedVariant: appliedVariants, isCreate: true, isStockChange: false}}
      >
        <Form form={form} onFinish={handleSubmit} scrollToFirstError>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <label htmlFor='product' className='required form-label'>
                  Select Product
                </label>
                <Form.Item
                  name='product_id'
                  rules={[
                    {
                      required: true,
                      message: 'Product is required!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder='Select a product'
                    onSearch={() => {}}
                    onSelect={(_, option) => {
                      const data = productList[+option.key!]
                      setApplicableVariants(undefined)
                      setAppliedVariants(undefined)
                      form.setFieldsValue({
                        actual_price: data.actual_price,
                      })
                      getProductDetail(data._id!)
                    }}
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {/* do not change the key={idx} onSelect is triggered on that basis */}
                    {productList.map((e, idx) => (
                      <Select.Option key={idx} value={e._id!}>
                        {e.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className='col'>
                <label htmlFor='title' className='required form-label'>
                  Title
                </label>
                <Form.Item name='title' rules={[{required: true, message: 'A title is required!'}]}>
                  <Input placeholder='2 Pairs of Nike Air Shoes' />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <label htmlFor='actual_price' className='form-label'>
                  Product Price
                </label>
                <Form.Item name='actual_price'>
                  <Input disabled />
                </Form.Item>
              </div>
              <div className='col'>
                <label className='form-label required'>Deal Type</label>
                <Form.Item name='type'>
                  <Select
                    showSearch
                    placeholder='Select Type'
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    <Select.Option value='service'>Service</Select.Option>
                    <Select.Option value='product'>Product</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className='form-label'>Flash Deal</label>
                <Form.Item name='flash_deal' valuePropName='checked'>
                  <Switch
                    defaultChecked={false}
                    checkedChildren={<span>No</span>}
                    unCheckedChildren={<span>Yes</span>}
                    onChange={(checked) => setChecked(checked)}
                  />
                </Form.Item>
              </div>
              {form.getFieldValue('flash_deal') && (
                <div className='col-6'>
                  <label className='form-label'>Valid Period</label>
                  <Form.Item name='range' rules={[{required: true, message: 'Date is required!'}]}>
                    <DatePicker.RangePicker style={{display: 'inline-flex'}} showTime />
                  </Form.Item>
                </div>
              )}
            </div>
            <div className='row'>
              <div className='col'>
                <div className='rounded border d-flex flex-column p-10'>
                  <label className='required form-label'>Write about the deal?</label>
                  <Form.Item
                    name='description'
                    rules={[{required: true, message: 'Description is required!'}]}
                  >
                    <Input.TextArea data-kt-autosize='true' rows={4} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col mt-10'>
                <label className='required form-label'>Upload Deal Images</label>
                <MultiImage imagesUrl={null} setImagesUrl={allImageUrls} />
              </div>
            </div>

            <DealChoice form={form} applicableVariants={applicableVariants} />
          </div>
          <div className='card-footer'>
            <Button loading={formIsSubmitting} name='Create' />
          </div>
        </Form>
      </DealContext.Provider>
    </div>
  ) : (
    <div className='card card-custom d-flex p-5' style={{minHeight: '60vh'}}>
      <Alert
        message='Unverified Account'
        description='Please wait for your account to be verified by the admin to add deals.'
        type='error'
        showIcon
      />
      <Empty style={{marginTop: '25vh'}} description={false} />
    </div>
  )
}

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)

export default connector(CreateDeal)
