/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getBanner, putBanner} from '../api/banner'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {BannerModel} from '../interface/Banner'
import GoBack from '../../../components/partials/GoBack'

export interface IPageParams {
  id: string
}
const URL = '/banner'
const EditBanner: FC = () => {
  const {id} = useParams<IPageParams>()
  const [banner, setBanner] = useState<BannerModel>()
  const history = useHistory()

  useEffect(() => {
    getBanner(id).then(({data}) => {
      setBanner(data.result)
    })
  }, [id])

  function handleChange(event: React.ChangeEvent<any>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const result = {...banner, [name]: value} as BannerModel
    setBanner(result)
  }

  function handleSubmit(event: React.ChangeEvent<any>) {
    event.preventDefault()
    putBanner(id, banner as BannerModel)
      .then((data) => {
        // history.push(`/banner/view/${id}`)
        history.push(URL)
        toast.success('Success!')
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Update Banner</PageTitle>

      {banner && (
        <div className='card pt-4 mb-6 mb-xl-9'>
          <form onSubmit={handleSubmit}>
            {/*begin::Card body*/}
            <div className='card-body pt-0 pb-5'>
              {/*begin::Table*/}
              <table className='table align-middle table-row-dashed gy-5'>
                {/*begin::Table body*/}
                <tbody className='fs-6 fw-bold text-gray-600'>
                  <tr>
                    <td>Banner Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={banner.name}
                        onChange={handleChange}
                        name='name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Banner Upper Tagline </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={banner.tagline_upper}
                        onChange={handleChange}
                        name='tagline_upper'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Banner Middle Tagline </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={banner.tagline_middle}
                        onChange={handleChange}
                        name='tagline_middle'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Banner Lower Tagline </td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={banner.tagline_lower}
                        onChange={handleChange}
                        name='tagline_lower'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Callback</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={banner.callback}
                        onChange={handleChange}
                        name='callback'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Callback Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={banner.callback_name}
                        onChange={handleChange}
                        name='callback_name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Position</td>
                    <td>
                      <select
                        className='form-select'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        name='position'
                        defaultValue={banner.position}
                        onChange={handleChange}
                        required
                      >
                        <option value='' disabled>
                          Choose Position
                        </option>
                        <option value='start'>Start</option>
                        <option value='center'>Center</option>
                        <option value='end'>End</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>Banner Image</td>
                    <td>
                      <img src={banner.image} alt='banner' height='50px' width='50px' />
                      <input
                        type='file'
                        name='image'
                        accept='.png, .jpg, .jpeg'
                        onChange={async (event: React.ChangeEvent<any>) => {
                          let formdata = new FormData()
                          formdata.append('file', event.currentTarget.files[0])
                          try {
                            const url: string = process.env.REACT_APP_IMAGE_UPLOAD as string
                            const res = await fetch(url, {
                              method: 'post',
                              body: formdata,
                            })
                            const data = await res.json()
                            if (data.status_code === 201) {
                              setBanner({...banner, image: data['url']})
                            }
                          } catch (err) {
                            console.log(err)
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Featured</td>
                    <td>
                      <input
                        type='checkbox'
                        id='flexCheckChecked'
                        className='form-check-input'
                        onChange={handleChange}
                        name='is_featured'
                        checked={banner.is_featured}
                      />
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
              <div className='d-flex justify-content-end align-items-center mt-15'>
                {/*begin::Button*/}
                <GoBack url={URL} />
                {/*end::Button*/}
                <input type='submit' value='Submit' className='btn btn-primary' />
                {/*begin::Button*/}
                {/* <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                <span className='indicator-label'>Save</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </button> */}
                {/*end::Button*/}
              </div>
            </div>
            {/*end::Card body*/}
          </form>
        </div>
      )}
    </>
  )
}

export default EditBanner
