import React, {FC} from 'react'
interface ModalProps {
  id: string
  handleDelete: (event: React.ChangeEvent<any>, id: string) => void
  name?: string
  buttonName?: string
}

const ModalDelete: FC<ModalProps> = ({
  id,
  handleDelete,
  name = 'Are you sure you want to delete the item?',
  buttonName = 'Delete',
}) => {
  return (
    <div className='modal fade' id='kt_modal_delete' tabIndex={-1} aria-hidden='true'>
      {/*begin::Modal dialog*/}
      <div className='modal-dialog modal-dialog-centered mw-650px'>
        {/*begin::Modal content*/}
        <div className='modal-content'>
          {/*begin::Modal header*/}
          <div className='modal-header' id='kt_modal_delete'>
            {/*begin::Modal title*/}
            <h2 className='fw-bolder'>{name}</h2>
            {/*end::Modal title*/}
            {/*begin::Close*/}
            <div
              className='btn btn-icon btn-sm btn-active-icon-primary'
              data-kt-users-modal-action='close'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_delete'
            >
              {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
              <span className='svg-icon svg-icon-1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width={24}
                  height={24}
                  viewBox='0 0 24 24'
                  fill='none'
                >
                  <rect
                    opacity='0.5'
                    x={6}
                    y='17.3137'
                    width={16}
                    height={2}
                    rx={1}
                    transform='rotate(-45 6 17.3137)'
                    fill='black'
                  />
                  <rect
                    x='7.41422'
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform='rotate(45 7.41422 6)'
                    fill='black'
                  />
                </svg>
              </span>
              {/*end::Svg Icon*/}
            </div>
            {/*end::Close*/}
          </div>
          {/*end::Modal header*/}
          {/*begin::Modal body*/}
          <div className='modal-body scroll-y mx-5 mx-xl-15'>
            {/*begin::Form*/}
            <div>
              <button
                type='submit'
                className='btn btn-danger me-2'
                data-kt-users-modal-action='submit'
                onClick={(event) => handleDelete(event, id)}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_delete'
              >
                <span className='indicator-label'>{buttonName}</span>
              </button>
              <button
                type='reset'
                className='btn btn-light me-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_delete'
              >
                Cancel
              </button>
            </div>

            {/*end::Form*/}
          </div>
          {/*end::Modal body*/}
        </div>
        {/*end::Modal content*/}
      </div>
      {/*end::Modal dialog*/}
    </div>
  )
}

export default ModalDelete
