import axios from 'axios'
import {MerchantListModel, MerchantResult} from '../../auth/models/UserListModel'
import {MerchantModel} from '../../auth/models/UserModel'
import {
  AddressModal,
  BankAccountModal,
  BusinessInformationModal,
  SellerDetails,
} from '../../merchant/account-management/interface/account'
import {FilterParams} from '../interface/IMerchant'

const API_URL = process.env.REACT_APP_API_URL

const MERCHANT_GETALL_URL = `${API_URL}/merchants/all`
const MERCHANT_REQ_URL = `${API_URL}/merchant/`
const MERCHANT_ID_NAME = `${API_URL}/merchants-for-filter/`
// const MERCHANT_POST_URL = `${API_URL}/merchant`

export const getAllMerchant = async (
  // page: string,
  filterBy?: FilterParams
): Promise<MerchantListModel> => {
  let res
  if (filterBy) {
    var {
      page,
      date,
      is_verified,
      province,
      area,
      district,
      warehouse_address,
      return_address,
      search = '',
    } = filterBy
    res = await axios.get(
      MERCHANT_GETALL_URL +
        `?page=${page}&search_text=${search}&date=${date}&province=${province}&area=${area}&district=${district}&is_verified=${is_verified}&warehouse_address=${warehouse_address}&return_address=${return_address}`
    )
  } else {
    res = await axios.get(MERCHANT_GETALL_URL)
  }
  return res.data.result
}

export const getMerchantIdName = async (): Promise<MerchantModel[]> => {
  let res = await axios.get(MERCHANT_ID_NAME)
  return res.data.result
}

export const getMerchant = (id: string): Promise<MerchantResult> => {
  return axios.get(MERCHANT_REQ_URL + id)
}

export const putMerchantBankDetails = (
  id: string | undefined,
  data: BankAccountModal
): Promise<MerchantResult> => {
  return axios.put(MERCHANT_REQ_URL + id, {bank_account: data})
}

export const putMerchantBusinessDetails = (
  id: string | undefined,
  data: BusinessInformationModal
): Promise<MerchantResult> => {
  return axios.put(MERCHANT_REQ_URL + id, {business_information: data})
}

export const putMerchantWarehouseAddress = (
  id: string | undefined,
  data: AddressModal
): Promise<MerchantResult> => {
  return axios.put(MERCHANT_REQ_URL + id, {warehouse_address: data})
}

export const putMerchantReturnAddress = (
  id: string | undefined,
  data: AddressModal
): Promise<MerchantResult> => {
  return axios.put(MERCHANT_REQ_URL + id, {return_address: data})
}

export const putMerchantAccount = (
  id: string | undefined,
  data: SellerDetails
): Promise<MerchantResult> => {
  return axios.put(MERCHANT_REQ_URL + id, data)
}

export const postMerchant = (data: MerchantModel): Promise<MerchantResult> => {
  return axios.post(MERCHANT_REQ_URL, data)
}

export const deleteMerchant = (id: string): Promise<MerchantResult> => {
  return axios.delete(MERCHANT_REQ_URL + id)
}

export const putMerchant = (id: string, data: MerchantModel): Promise<MerchantResult> => {
  return axios.put(MERCHANT_REQ_URL + id, data)
}

export const putDisableMerchant = (
  id: string,
  data: {is_verified: boolean; rejection_reason?: string}
): Promise<MerchantResult> => {
  return axios.put(`${MERCHANT_REQ_URL}status/${id}`, data)
}
