import axios from 'axios'
import {BrandListModel, BrandModelResult, BrandModel} from '../interface/Brand'

const API_URL = process.env.REACT_APP_API_URL

const BRAND_GETALL_URL = `${API_URL}/brands/all`
const BRAND_REQ_URL = `${API_URL}/brand/`

export const getAllBrand = async (page: string): Promise<BrandListModel> => {
  console.log(BRAND_GETALL_URL + `?page=${page}`)
  const res = await axios.get(BRAND_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getBrand = (id: string): Promise<BrandModelResult> => {
  return axios.get(BRAND_REQ_URL + id)
}

export const putBrand = (id: string, data: BrandModel): Promise<BrandModelResult> => {
  return axios.put(BRAND_REQ_URL + id, data)
}

export const postBrand = (data: BrandModel): Promise<BrandModelResult> => {
  return axios.post(BRAND_REQ_URL, data)
}

export const deleteBrand = (id: string): Promise<BrandModelResult> => {
  return axios.delete(BRAND_REQ_URL + id)
}

export const searchBrand = async (name: string): Promise<BrandListModel> => {
  const res = await axios.get(BRAND_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
