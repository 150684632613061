/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {FaqModel} from '../interface/IFaq'
import {getFaq} from '../api/faq'
import GoBack from '../../../components/partials/GoBack'
import ViewItem from '../../../components/common/ViewItem'

export interface IPageParams {
  id: string
}
const URL = '/faq'
const ViewFaq: FC = () => {
  const {id} = useParams<IPageParams>()
  const [faq, setFaq] = useState<FaqModel>()

  useEffect(() => {
    getFaq(id)
      .then(({data}) => {
        setFaq(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {faq && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>{faq.is_active}</div>
              <span>
                <GoBack url={URL} />
                <Link to={`/${URL}/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator mb-10' />
            {/*begin::Details content*/}
            <div className='collapse show'>
              <ViewItem name={'ID'} value={faq?._id} />
              <ViewItem name={'Question'} value={faq?.question} />
              <ViewItem name={'Answer'} value={faq?.answer} />
              <ViewItem
                name={'Active'}
                value={
                  faq?.is_active ? (
                    <div className='badge badge-light-success fw-bolder'>ACTIVE</div>
                  ) : (
                    <div className='badge badge-light-danger fw-bolder'>INACTIVE</div>
                  )
                }
              />
              <ViewItem name={'Created Date'} value={parseDate(faq?.createdAt)} />
            </div>
          </div>
          {/*end::Details content*/}
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewFaq
