import axios from 'axios'
import { IDashboardModel } from '../interface/IDashboardModel'

const API_URL = process.env.REACT_APP_API_URL

const DASHBORD_URL = `${API_URL}/dashboard`

export const getDashboard = async (): Promise<IDashboardModel> => {
  const res = await axios.get(DASHBORD_URL)
  return res.data.result
}
