const NoData = () => {
  return (
    <tr className='odd'>
      <td colSpan={7} valign='top' style={{textAlign: 'center'}}>
        No data available
      </td>
    </tr>
  )
}
export default NoData
