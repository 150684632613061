import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import CreateEditFaq from './components/CreateEditFaq'
import ListFaq from './components/ListFaq'
import ViewFaq from './components/ViewFaq'

const FaqPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/faq/view/:id'>
          <PageTitle breadcrumbs={[]}>View FAQ</PageTitle>
          <ViewFaq />
        </Route>
        <Route path='/faq/create'>
          <PageTitle breadcrumbs={[]}>Create FAQ</PageTitle>
          <CreateEditFaq />
        </Route>
        <Route path='/faq/edit/:id'>
          <PageTitle breadcrumbs={[]}>Update FAQ</PageTitle>
          <CreateEditFaq />
        </Route>
        <Route path='/faq/:page'>
          <PageTitle breadcrumbs={[]}>List FAQ</PageTitle>
          <ListFaq />
        </Route>
        <Redirect from='/faq' exact={true} to='/faq/1' />
        <Redirect to='/faq/1' />
      </Switch>
    </>
  )
}

export default FaqPage
