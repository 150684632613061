/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {parseValueToCeil} from '../../../utils/parseNumber'
import {getDashboard} from './api/dashboard'
import {PageTitle} from '../../../_metronic/layout/core'
import {IDashboardModel} from './interface/IDashboardModel'
import {GenerateReport} from './components/GenerateReport'
import {SalesBarChart} from './components/SalesBarChart'
import {AdminMixWidget} from './components/AdminMixWidget'
import {TopDeals} from './components/TopDeals'
import {TopMerchants} from './components/TopMerchants'
import {DealVerification} from './components/DealVerification'
import {MerchantVerification} from './components/MerchantVerification'
import {NewAndExpiringDeals} from './components/NewAndExpiringDeals'
// import {OrderStatistics} from './components/OrderStatistics'
import {OrderStatistics} from '../../../_metronic/partials/widgets'

const DashboardPage: FC = () => {
  const [dashboard, setDashboard] = useState<IDashboardModel>({
    total_number_order_sales: 0,
    total_order_sales: 0,
    daily_sales: [],
    top_merchant: [],
    monthly_sales: [],
    recent_product: [],
    top_deals: [],
    new_deals: [],
    not_verified_merchants: [],
    not_verified_deals: [],
    expiring_deals: [],
    notification: [],
  })

  useEffect(() => {
    getDashboard()
      .then((data) => {
        // setLoading(false)
        setDashboard(data)
      })
      .catch((err) => {
        // setLoading(false)
        toast.error('Error!')
      })
  }, [])

  return (
    <>
      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-4'>
          <AdminMixWidget
            className='card-xl-stretch mb-xl-8'
            chartColor='danger'
            chartHeight='200px'
            totalSales={parseValueToCeil(
              dashboard.monthly_sales.map((e) => e.total_sales).reduce((a, b) => a + b, 0)
            )}
            strokeColor='#cb1e46'
          />
        </div>

        <div className='col-xxl-8'>
          <GenerateReport
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='150px'
            data={dashboard.daily_sales.map((e) => e.total_count)}
          />
          <SalesBarChart
            className='card-xxl-stretch-50 mb-5 mb-xl-8'
            chartColor='primary'
            chartHeight='175px'
            dailySales={dashboard.daily_sales}
          />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 gx-xl-8'>
        {/* <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div> */}
        <div className='col-xl-12'>
          <OrderStatistics className='card-xxl-stretch mb-5 mb-xl-8' />
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <TopMerchants merchants={dashboard.top_merchant} className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xxl-8'>
          <MerchantVerification
            merchants={dashboard.not_verified_merchants}
            className='card-xxl-stretch mb-5 mb-xxl-8'
          />
        </div>
      </div>
      {/* end::Row */}

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-8'>
          <DealVerification
            deals={dashboard.not_verified_deals}
            className='card-xxl-stretch mb-5 mb-xxl-8'
          />
        </div>
        <div className='col-xxl-4'>
          <TopDeals deals={dashboard.top_deals} className='card-xxl-stretch mb-xl-3' />
        </div>
      </div>
      <div className='row gy-5 gx-xl-8'>
        {/* <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div> */}
        <div className='col-xl-12'>
          <NewAndExpiringDeals
            deals={{new_deals: dashboard.new_deals, expiring_deals: dashboard.expiring_deals}}
            className='card-xxl-stretch mb-5 mb-xl-8'
          />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
