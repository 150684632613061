/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getBrand, putBrand} from '../api/brand'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {BrandModel} from '../interface/Brand'
import GoBack from '../../../components/partials/GoBack'
import Button from '../../../components/common/Button'

export interface IPageParams {
  id: string
}
const URL = '/brand'
const EditBrand: FC = () => {
  const {id} = useParams<IPageParams>()
  const [brand, setBrand] = useState<BrandModel>()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    getBrand(id).then(({data}) => {
      setBrand(data.result)
    })
  }, [id])

  function handleChange(event: React.ChangeEvent<any>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const result = {...brand, [name]: value} as BrandModel
    setBrand(result)
  }

  function handleSubmit(event: React.ChangeEvent<any>) {
    event.preventDefault()
    setLoading(true)
    putBrand(id, brand as BrandModel)
      .then((data) => {
        // history.push(`/brand/view/${id}`)
        history.push(URL)
        toast.success('Success!')
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(true)
      })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit Brand</PageTitle>

      {brand && (
        <form onSubmit={handleSubmit}>
          <div className='card pt-4 mb-6 mb-xl-9'>
            {/*begin::Card body*/}
            <div className='card-body pt-0 pb-5'>
              {/*begin::Table*/}
              <table className='table align-middle table-row-dashed gy-5'>
                {/*begin::Table body*/}
                <tbody className='fs-6 fw-bold text-gray-600'>
                  <tr>
                    <td>Brand Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={brand.name}
                        onChange={handleChange}
                        name='name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Brand Tagline</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={brand.tagline}
                        onChange={handleChange}
                        name='tagline'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Brand Image</td>
                    <td>
                      <img src={brand.image} alt='brand' height='50px' width='50px' />
                      <input
                        type='file'
                        name='image'
                        accept='.png, .jpg, .jpeg'
                        onChange={async (event: React.ChangeEvent<any>) => {
                          let formdata = new FormData()
                          formdata.append('file', event.currentTarget.files[0])
                          try {
                            const url: string = process.env.REACT_APP_IMAGE_UPLOAD as string
                            const res = await fetch(url, {
                              method: 'post',
                              body: formdata,
                            })
                            const data = await res.json()
                            if (data.status_code === 201) {
                              setBrand({...brand, image: data['url']})
                            }
                          } catch (err) {
                            console.log(err)
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Featured</td>
                    <td>
                      <input
                        type='checkbox'
                        id='flexCheckChecked'
                        className='form-check-input'
                        onChange={handleChange}
                        name='is_featured'
                        checked={brand.is_featured}
                      />
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
              <div className='d-flex justify-content-end align-items-center mt-15'>
                {/*begin::Button*/}
                <GoBack url={URL} />
                {/*end::Button*/}
                <Button loading={loading} name={'Update'} />
                {/*begin::Button*/}
                {/* <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                  <span className='indicator-label'>Save</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                </button> */}
                {/*end::Button*/}
              </div>
            </div>
            {/*end::Card body*/}
          </div>
        </form>
      )}
    </>
  )
}

export default EditBrand
