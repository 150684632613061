import axios from 'axios'
import {ResultModel} from '../../../../interface/Result'
import {SettlementRootObject} from '../interface/settlement'

const API_URL = process.env.REACT_APP_API_MERCHANT_URL

const FINANCE_REQ_URL = `${API_URL}/finance`

export const getStatement = (start_date: any, end_date: any): Promise<SettlementRootObject> => {
  return axios.get(`${FINANCE_REQ_URL}/statement`, {
    params: {start_date, end_date},
  })
}

export const patchPayoutRequest = async (
  period_end: string,
  period_start?: string
): Promise<ResultModel> => {
  const result = await axios.patch(`${FINANCE_REQ_URL}/payout_request`, {
    period_start,
    period_end,
  })
  return result.data
}

// {{baseurlmerchant}}/finance/statement?start_date=2011-01-01&end_date=2022-10-10
