import {Tag} from 'antd'
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {parseValueToCeil} from '../../../../utils/parseNumber'
import {OrderListModel} from '../../../modules/order-management/interface/Order'
import NoData from '../NoData'
interface Props {
  orderList?: OrderListModel
  setDeleteId?: (id: string) => void
  isAdmin: boolean
}
const ListOrder: FC<Props> = ({orderList, isAdmin, setDeleteId}) => {
  return (
    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
      <thead className='thead-dark'>
        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
          <th style={{width: '240.65px'}}>Order</th>
          <th style={{width: '240.65px'}}>Order Items</th>
          <th style={{width: '240.65px'}}>Total Price</th>
          <th style={{width: '240.65px'}}>User</th>
          <th style={{width: '240.65px'}}>No of Items</th>
          <th style={{width: '140.417px'}}>Created Date</th>
          <th className='text-end min-w-100px' style={{width: '106.433px'}}>
            Actions
          </th>
        </tr>
      </thead>
      {orderList && (
        <tbody className='text-gray-600 fw-bold'>
          {orderList.data.length === 0 && <NoData />}
          {orderList.data.length !== 0 &&
            orderList.data.map((item) => {
              const {_id, createdAt, user_id, express_checkout, no_of_orders, total, order_names} =
                item
              return (
                <tr className='odd' key={_id}>
                  {/*begin::Order=*/}
                  <td className='d-flex align-items-center'>
                    {/*begin::Order details*/}
                    <div className='d-flex flex-column'>
                      {isAdmin && (
                        <Link
                          to={`/order/view/${_id}`}
                          className='text-gray-800 text-hover-primary mb-1'
                        >
                          {express_checkout ? express_checkout.email : user_id?.email}
                        </Link>
                      )}
                      <span>{_id}</span>
                    </div>
                  </td>
                  <td>
                    {order_names.slice(0, 3).map((e) => (
                      <Tag color='magenta' className='mb-2'>
                        {e}
                      </Tag>
                    ))}
                  </td>
                  <td>{parseValueToCeil(total)}</td>
                  <td>{express_checkout ? express_checkout.phone_number : user_id?.full_name}</td>
                  <td>{no_of_orders}</td>
                  <td>{parseDate(createdAt)}</td>
                  <td className='text-end'>
                    <Link
                      to={`/order/view/${_id}`}
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                    >
                      View
                    </Link>
                    {isAdmin && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href='#'
                        className='btn btn-danger btn-sm px-4'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_delete'
                        onClick={() => setDeleteId && setDeleteId(_id as string)}
                      >
                        Delete
                      </a>
                    )}
                  </td>
                </tr>
              )
            })}
        </tbody>
      )}
    </table>
  )
}
export {ListOrder}
