import {FC} from 'react'
import {capitalize} from '../../../../utils/capitalize'
interface Props {
  variation: any
}
const Variation: FC<Props> = ({variation}) => {
  return (
    <>
      <div className='fw-bolder mt-5'>Variation</div>
      {variation?.map((item: any, index: number) => (
        <div key={index} className='mb-3'>
          {Object.keys(item).map((e, index) => (
            <div className='text-gray-600' key={index}>
              {capitalize(e)}: {item[e]}
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default Variation
