/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Link, useParams} from 'react-router-dom'

import Pagination from '../../../components/Pagination'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Search from '../../../components/Search'
import {NewsletterListModel} from '../interface/Newsletter'
import {deleteNewsletter, getAllNewsletter, searchNewsletter} from '../api/newsletter'
import {parseDate} from '../../../../utils/dateAndTime'
import Spinner from '../../../components/Spinner'
import NoData from '../../../components/common/NoData'

interface IPageParams {
  page: string
}

const ListNewsletter: FC = () => {
  const [newsletterList, setNewsletterList] = useState<NewsletterListModel>()
  const [search, setSearch] = useState<string>('')
  const [deleteId, setDeleteId] = useState<string>('')
  const {page} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)

  function getNewsletter() {
    getAllNewsletter(page)
      .then((data) => {
        console.log(data)
        setLoading(false)
        setNewsletterList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }
  useEffect(() => {
    getNewsletter()
  }, [page])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteNewsletter(id).then((data) => {
      toast.success('Success!')
      getNewsletter()
    })
  }
  function handleSearch(event: React.ChangeEvent<any>) {
    event.preventDefault()
    const {value} = event.target
    setSearch(value)
    if (value === '') {
      getNewsletter()
      return
    }
    searchNewsletter(search).then((data) => {
      setNewsletterList(data)
    })
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card newsletter*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}
                <Search name={search} placeholder='newsletter' handleSearch={handleSearch} />
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Modal - Delete*/}
                <ModalDelete id={deleteId} handleDelete={handleDelete} />
                {/*end::Modal - delete*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card newsletter*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_newsletters_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Newsletter
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Email
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            style={{width: '106.433px'}}
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {newsletterList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {newsletterList.data.length === 0 && <NoData />}
                          {newsletterList.data.length !== 0 &&
                            newsletterList.data.map((item) => {
                              console.log(item)
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Newsletter=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to='#'>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.name.slice(0, 1)}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Newsletter details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to='#'
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.name}
                                      </Link>
                                    </div>
                                    {/*begin::Newsletter details*/}
                                  </td>
                                  {/*end:: featured*/}
                                  {/*begin::Email*/}
                                  <td>{item.email}</td>
                                  {/*end:: Email*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Link
                                      to={`/newsletter/edit/${item._id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='newsletter'
                  currentPage={page}
                  prev_page={newsletterList?.prev_page}
                  next_page={newsletterList?.next_page}
                  total_number_of_page={newsletterList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListNewsletter
