/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Spin, Tabs} from 'antd'
import {toast} from 'react-toastify'
import OrderTable from './OrderTable'
import {OrderListModel} from '../../../order-management/interface/Order'
import {getDashboardOrder} from '../api/dashboard'

type Props = {
  className: string
}

const OrderStatistics: React.FC<Props> = ({className}) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [newOrders, setNewOrders] = useState<OrderListModel>()
  const [deliveredOrder, setDeliveredOrder] = useState<OrderListModel>()
  const [canceledOrder, setCanceledOrder] = useState<OrderListModel>()

  async function getOrder() {
    setLoading(true)
    await Promise.all([
      getDashboardOrder({
        page: '1',
        filterBy: 'ACKNOWLEDGED',
      }),
      getDashboardOrder({
        page: '1',
        filterBy: 'DELIVERED',
      }),
      getDashboardOrder({
        page: '1',
        filterBy: 'CANCELED',
      }),
    ])
      .then(([newOrder, deliveredOrder, canceledOrder]) => {
        setNewOrders(newOrder)
        setDeliveredOrder(deliveredOrder)
        setCanceledOrder(canceledOrder)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getOrder()
  }, [])

  if (loading) return <Spin />

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Orders Statistics</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>Over 500 members</span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <Tabs onChange={() => {}} type='card'>
          <Tabs.TabPane tab={<span style={{margin: 15}}>New Orders</span>} key='1'>
            {/* begin::Table container */}
            <OrderTable orderList={newOrders} />
            {/* end::Table container */}
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span style={{margin: 15}}>Cancelled Orders</span>} key='2'>
            <OrderTable orderList={canceledOrder} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span style={{margin: 15}}>Delivered Orders</span>} key='3'>
            <OrderTable orderList={deliveredOrder} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {OrderStatistics}
