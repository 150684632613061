import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
// import EditCategory from './parent/EditCategory'
import ViewCategory from './parent/ViewCategory'
import ListCategory from './parent/ListCategory'
import CreateEditCategory from './components/CreateEditCategory'

const CategoryPage: React.FC = () => {
  const LEVEL = 1
  return (
    <>
      <Switch>
        <Route path='/category/add'>
          <PageTitle breadcrumbs={[]}>Add Segment</PageTitle>
          <CreateEditCategory level={LEVEL} />
        </Route>
        <Route path='/category/edit/:id'>
          <PageTitle breadcrumbs={[]}>Edit Segment</PageTitle>
          {/* <EditCategory level={LEVEL} /> */}
          <CreateEditCategory level={LEVEL} />
        </Route>
        <Route path='/category/view/:id'>
          <PageTitle breadcrumbs={[]}>View Segment</PageTitle>
          <ViewCategory level={LEVEL} />
        </Route>
        <Route path='/category/:pageNo'>
          <PageTitle breadcrumbs={[]}>List Segment </PageTitle>
          <ListCategory level={LEVEL} />
        </Route>
        <Redirect from='/category' exact={true} to='/category/1' />
        <Redirect to='/category/1' />
      </Switch>
    </>
  )
}

export default CategoryPage
