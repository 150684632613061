import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import ProductPage from '../modules/merchant/product-management/ProductPage'
import OrderPage from '../modules/merchant/order-management/OrderPage'
import {DashboardWrapper} from '../modules/merchant/dashboard/DashboardWrapper'
import DealPage from '../modules/merchant/deal-management/DealPage'
import FinancePage from '../modules/merchant/finance-management/FinancePage'

export function MerchantPrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const MerchantAccountPage = lazy(
    () => import('../modules/merchant/account-management/AccountPage')
  )

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/account' component={MerchantAccountPage} />
        <Route path='/product' component={ProductPage} />
        <Route path='/deal' component={DealPage} />
        <Route path='/order' component={OrderPage} />
        <Route path='/finance' component={FinancePage} />
        <Route path='/builder' component={BuilderPageWrapper} />
        {/* <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        <Redirect from='/merchant' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
