import {PDFDownloadLink, PDFViewer} from '@react-pdf/renderer'
import {Alert, Button, Col, DatePicker, Divider, Empty, Row, Select, Space, Spin} from 'antd'
import {
  DownloadOutlined,
  LoadingOutlined,
  MoneyCollectOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import {toast} from 'react-toastify'
import MyDocument from './PDFStatement'
import {useEffect, useState} from 'react'
import {getAllOrder} from '../../../order-management/api/order'
import {connect, shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../../setup'
import {getStatement, patchPayoutRequest} from '../../api/finance'
import {ResultInterface} from '../../interface/settlement'
import * as auth from '../../../../../modules/auth/redux/AuthRedux'

const AccountStatement = ({
  auth: {
    user: {
      result: {is_verified},
    },
  },
}: any) => {
  const [loading, setLoading] = useState(false)
  const [statement, setStatement] = useState<ResultInterface>()
  const {
    result: {payout_period},
  }: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  let filterDate: string = ''
  if (payout_period) {
    filterDate = JSON.stringify(payout_period[payout_period.length - 1])
  } else {
    // filterDate = JSON.stringify('2022')
  }

  async function getAccountStatementByFilter() {
    if (filterDate === 'all') {
      return
    } else {
      const [start_date, end_date] = JSON.parse(filterDate)
      setLoading(true)
      const result = (await getStatement(start_date, end_date)).data.result
      setLoading(false)
      setStatement(result)
    }
  }

  async function requestForPayout() {
    if (filterDate === 'all') {
      const today_date = new Date()
      await patchPayoutRequest(
        new Date(today_date.getFullYear(), today_date.getMonth(), 0).toDateString()
      )
        .then(({error, result}) => {
          toast.success(result)
        })
        .catch((error) => {
          toast.error(error.response.data.result)
        })
        .finally(() => {})
    } else if (filterDate) {
      let [start_date, end_date] = JSON.parse(filterDate)
      await patchPayoutRequest(
        new Date(end_date).toLocaleDateString(),
        new Date(start_date).toLocaleDateString()
      )
        .then(({error, result}) => {
          toast.success(result)
        })
        .catch((error) => {
          toast.error(error.response.data.result)
        })
        .finally(() => {})
    } else {
      toast.error('Date Not Selected')
    }
  }

  return is_verified ? (
    <>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {/* <DatePicker.RangePicker onChange={(values) => console.log(values)} /> */}

        <Row gutter={[16, 16]} className='mb-1'>
          <Col className='gutter-row'>
            <Select
              defaultValue={filterDate}
              onChange={(value) => {
                filterDate = value
              }}
            >
              {/* <Select.Option value='hi'>Sat 11 Feb 2022 - Sat 11 Feb 2022</Select.Option> */}
              {payout_period &&
                payout_period.length > 0 &&
                payout_period.map((e: string[], idx: number) => (
                  <Select.Option key={idx} value={JSON.stringify(e)}>
                    {e[0]} - {e[1]}
                  </Select.Option>
                ))}

              <Select.Option key={'all'} value={'all'}>
                Request Payment Till{' '}
                {new Date(new Date().getFullYear(), new Date().getMonth(), 0).toDateString()}
              </Select.Option>
            </Select>
          </Col>
          <Col className='gutter-row'>
            <Button onClick={getAccountStatementByFilter} type='primary' icon={<FilterOutlined />}>
              Filter
            </Button>
          </Col>
          <Col className='gutter-row'>
            <Button icon={<MoneyCollectOutlined />} onClick={requestForPayout} type='primary'>
              Payout Request
            </Button>
          </Col>
          <Col className='gutter-row'>
            <PDFDownloadLink
              document={<MyDocument statement={statement} />}
              fileName='account-statement.pdf'
            >
              {({blob, url, loading, error}) =>
                loading ? (
                  <Button icon={<LoadingOutlined />}>Loading Statement..</Button>
                ) : (
                  <Button type='primary' icon={<DownloadOutlined />}>
                    Download
                  </Button>
                )
              }
            </PDFDownloadLink>
          </Col>
        </Row>
      </div>
      <Divider />

      <div style={{display: 'flex', justifyContent: 'center'}}>
        {loading ? (
          <Spin />
        ) : (
          <PDFViewer showToolbar={false} height='1122px' width='793px'>
            <MyDocument statement={statement} />
          </PDFViewer>
        )}
      </div>
    </>
  ) : (
    <div className='card card-custom d-flex p-5' style={{minHeight: '60vh'}}>
      <Alert
        message='Unverified Account'
        description='Please wait for your account to be verified by the admin to look over your accounts!.'
        type='error'
        showIcon
      />
      <Empty style={{marginTop: '25vh'}} description={false} />
    </div>
  )
}

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)

export default connector(AccountStatement)
