import {toast} from 'react-toastify'

const displayError = function (form: any, name: string, errors: string[]) {
  let fieldName: any = name.replace('[', '.').replace(']', '').split('.')
  if (fieldName && fieldName.length > 1) {
    fieldName[1] = +fieldName[1]
    form.setFields([
      {
        name: fieldName,
        errors,
      },
    ])
  } else {
    form.setFields([
      {
        name,
        errors,
      },
    ])
  }
}

const handleError = function (form: any, result: any) {
  if (Array.isArray(result) && form) {
    result.forEach((_) => displayError(form, _.param, [_.msg]))
  } else {
    toast.error(result)
  }
}

export default handleError
