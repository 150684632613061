import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
// import EditCategory from './parent/EditCategory'
import ViewCategory from './parent/ViewCategory'
import ListCategory from './parent/ListCategory'
import CreateEditCategory from './components/CreateEditCategory'

const InnerCategory: React.FC = () => {
  const LEVEL = 3
  return (
    <>
      <Switch>
        <Route path='/innercategory/add'>
          <PageTitle breadcrumbs={[]}>Add Subcategory</PageTitle>
          <CreateEditCategory level={LEVEL} />
        </Route>
        <Route path='/innercategory/edit/:id'>
          <PageTitle breadcrumbs={[]}>Edit Subcategory </PageTitle>
          <CreateEditCategory level={LEVEL} />
        </Route>
        <Route path='/innercategory/view/:id'>
          <PageTitle breadcrumbs={[]}>View Subcategory</PageTitle>
          <ViewCategory level={LEVEL} />
        </Route>
        <Route path='/innercategory/:pageNo'>
          <PageTitle breadcrumbs={[]}>List Subcategory</PageTitle>
          <ListCategory level={LEVEL} />
        </Route>
        <Redirect from='/innercategory' exact={true} to='/innercategory/1' />
        <Redirect to='/innercategory/' />
      </Switch>
    </>
  )
}

export default InnerCategory
