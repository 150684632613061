/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {IMerchant} from '../../../../app/pages/dashboard/interface/IDashboardModel'
import {KTSVG} from '../../../../_metronic/helpers'
import NoData from '../../../components/common/NoData'

type Props = {
  className: string
  merchants: IMerchant[]
}

const TopMerchants: React.FC<Props> = ({className, merchants}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>Top Merchants</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {merchants && merchants.length > 0 ? (
          merchants.map((e, idx) => (
            <div key={idx} className='d-flex align-items-center mb-7'>
              {/* begin::Avatar */}
              <div className='symbol symbol-50px me-5'>
                <img src={e.image} className='' alt='' />
              </div>
              {/* end::Avatar */}
              {/* begin::Text */}
              <div className='flex-grow-1'>
                <Link
                  to={`/merchant/view/${e._id}`}
                  className='text-dark fw-bolder text-hover-primary fs-6'
                >
                  {e.display_name}
                </Link>
                <span className='text-muted d-block fw-bold'>{e.email}</span>
              </div>
              {/* end::Text */}
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopMerchants}
