import {useState, useEffect} from 'react'
import {getMerchantsForFilter} from '../modules/deal-management/api/deal'

const useMerchant = () => {
  const [merchants, setMerchants] = useState([{ value: 'Var' }, { value: 'Per' }])

  function initializeMerchants() {
    getMerchantsForFilter()
      .then((data) => {
        const res = data.data.result
        setMerchants([
          ...res.map((e) => {
            return {value: e.display_name, id: e._id}
          }),
        ])
      })
      .catch((err) => {})
  }

  useEffect(() => {
    initializeMerchants()
  }, [])

  return [merchants]
}

export default useMerchant
