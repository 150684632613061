import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditHeaderOffer from './header-offer/EditHeaderOffer'
import ListHeaderOffer from './header-offer/ListHeaderOffer'
import ViewHeaderOffer from './header-offer/ViewHeaderOffer'

const HeaderOfferPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/header-offer/edit/:id'>
          <PageTitle breadcrumbs={[]}>HeaderOffer Edit</PageTitle>
          <EditHeaderOffer />
        </Route>
        <Route path='/header-offer/view/:id'>
          <PageTitle breadcrumbs={[]}>HeaderOffer View</PageTitle>
          <ViewHeaderOffer />
        </Route>
        <Route path='/header-offer/:page'>
          <PageTitle breadcrumbs={[]}>HeaderOffer List</PageTitle>
          <ListHeaderOffer />
        </Route>
        <Redirect from='/header-offer' exact={true} to='/header-offer/1' />
        <Redirect to='/header-offer/1' />
      </Switch>
    </>
  )
}

export default HeaderOfferPage
