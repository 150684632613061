/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {IMerchant} from '../../../../app/pages/dashboard/interface/IDashboardModel'
import {KTSVG} from '../../../../_metronic/helpers'
import NoData from '../../../components/common/NoData'

type Props = {
  className: string
  merchants: IMerchant[]
}

const MerchantVerification: React.FC<Props> = ({className, merchants}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Merchant Verification</span>
          <span className='text-muted mt-1 fw-bold fs-7'>
            More than 30 total pending verifications
          </span>
        </h3>
      </div>
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {merchants && merchants.length > 0 ? (
                    merchants.map((e, idx) => (
                      <tr key={idx}>
                        <td>
                          <div className='symbol symbol-45px me-2'>
                            <span className='symbol-label'>
                              <img src={e.image} className='h-50 align-self-center' alt='' />
                            </span>
                          </div>
                        </td>
                        <td>
                          <Link to={'/merchant/view/' + e._id}>
                            <div className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                              {e.display_name}
                            </div>
                          </Link>
                          <span className='text-muted fw-bold d-block'>{e.email}</span>
                        </td>
                        <td className='text-hover-primary'>{e.phone_number}</td>
                        <td>{moment(e.createdAt).fromNow()}</td>
                        <td className='text-end'>
                          <Link
                            to={'/merchant/view/' + e._id}
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-2'
                            />
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoData />
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {MerchantVerification}
