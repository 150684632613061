import {FC, useEffect, useState} from 'react'
import {getProduct, getSubCategory, getVariations, postProduct, putProduct} from '../api/product'
import {useHistory, useParams} from 'react-router-dom'
import ImageUpload from '../../../../components/ImageUpload'
import Button from '../../../../components/common/Button'
import {useForm} from 'antd/lib/form/Form'
import {AutoComplete, Form, Input, InputNumber} from 'antd'
import {toast} from 'react-toastify'
import VariationForm from './partials/VariationForm'
import CreateEditProductContext from '../contexts/CreateEditProductContext'
import GoBack from '../../../../components/partials/GoBack'
import handleError from '../../../../components/handleError'
// import {useFormik} from 'formik'
// import clsx from 'clsx'
// import * as Yup from 'yup'

// const productSchema = Yup.object().shape({
//   name: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(1000, 'Maximum 1000 symbols')
//     .required('Name is required'),
//   description: Yup.string()
//     .min(3, 'Minimum 3 symbols')
//     .max(1000, 'Maximum 1000 symbols')
//     .required('Description is required'),
//   actual_price: Yup.number().required('Actual Price is required'),
//   stock: Yup.number().required('Stock Quantity is required'),
// })

interface IPageParams {
  id: string
}

const URL = '/product'
const CreateEditProduct: FC = () => {
  const router = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [variations, setVariations] = useState<any>([])
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [hintCategoryData, setHintCategoryData] = useState<any>()
  const [image, setImage] = useState('')
  const [imageLoading, setImageLoading] = useState(false)
  const [stock, setStock] = useState<number>(0)
  const [checkedList, setCheckedList] = useState<string[]>([])
  const [form] = useForm()

  const {id} = useParams<IPageParams>()
  const isCreateMode = !id

  const getAutoCompleteCategoryData = () => {
    getSubCategory().then((data) => {
      setHintCategoryData([
        ...data.result.map((e) => ({
          label: e.name,
          value: e.name,
          id: e._id,
        })),
      ])
    })
  }

  useEffect(() => {
    getAutoCompleteCategoryData()
    if (!isCreateMode) {
      getProduct(id, 'true')
        .then(({data}) => {
          const result = data.result
          handleGetVariation(result.categoryId._id)
          setSelectedOption(result.categoryId)
          result.categoryId = result.categoryId.name
          form.setFieldsValue({
            ...result,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      form.resetFields()
    }
  }, [isCreateMode])

  const handleGetVariation = (id: string) => {
    getVariations(id)
      .then((value) => {
        //all variation in the category
        setVariations(value)
      })
      .catch((e) => console.log(e))
  }

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // setImage(info.file.response.url)
      form.setFieldsValue({image: info.file.response.url})
      setImageLoading(false)
    }
  }

  const onFinish = (values: any) => {
    // setLoading(true)
    if (image) {
      values = {...values, image}
    }
    if (selectedOption) {
      values = {...values, categoryId: selectedOption._id || selectedOption.value}
      // values = {...values, categoryId: selectedOption._id}
    }
    if (stock) {
      values = {...values, stock}
    }
    if (!isCreateMode) {
      const {variation} = values
      let defaultVariation = {}
      if (checkedList.length > 0) {
        variation.map(({sku, stock, ...rest}: any) => {
          defaultVariation = {
            sku: sku,
            stock: stock,
          }
          Object.keys(rest).forEach((e) => {
            if (checkedList.includes(e)) {
              defaultVariation = {...defaultVariation, [e]: rest[e]}
            }
          })
        })
      }
      values.variation = defaultVariation
      putProduct(id, values)
        .then(() => {
          toast.success('Success!')
          router.push('/product')
        })
        .catch((error) => {
          const res = error.response.data.result
          if (res) {
            handleError(form, res)
          }
        })
    } else {
      postProduct(values)
        .then(() => {
          toast.success('Success!')
          router.push('/product')
        })
        .catch((error) => {
          const res = error.response.data.result
          if (res) {
            handleError(form, res)
          }
        })
    }
  }

  return (
    <CreateEditProductContext.Provider
      value={{
        handleStockChange: (stock) => {
          form.setFieldsValue({stock: stock})
          setStock(stock)
        },
        checkedList,
        setCheckedList,
      }}
    >
      <Form onFinish={onFinish} form={form} labelAlign='left' labelCol={{span: 3}}>
        <div className='card shadow-sm'>
          <div className='card-header'>
            <h3 className='card-title'>{!isCreateMode ? 'Update' : 'Create'} Product Details</h3>
            <div className='card-toolbar'>
              <GoBack url={URL} className='btn btn-sm btn-danger me-4' name='Cancel' />
              <button type='submit' className='btn btn-sm btn-primary'>
                {!isCreateMode ? 'Update' : 'Create'}
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <label htmlFor='name' className='required form-label'>
                  Name
                </label>
                <Form.Item name='name' rules={[{required: true, message: 'Name is required!'}]}>
                  <Input
                    type='text'
                    className='form-control form-control-lg'
                    autoComplete='off'
                    // {...formik.getFieldProps('name')}
                    // className={clsx(
                    //   'form-control form-control-lg form-control-solid',
                    //   {
                    //     'is-invalid': formik.touched.name && formik.errors.name,
                    //   },
                    //   {
                    //     'is-valid': formik.touched.name && !formik.errors.name,
                    //   }
                    // )}
                  />
                </Form.Item>
              </div>
              <div className='col'>
                <label htmlFor='actual_price' className='required form-label'>
                  Original Price
                </label>
                <Form.Item
                  name='actual_price'
                  rules={[
                    {required: true, message: 'Price is required!'},
                    {
                      type: 'integer',
                      min: 0,
                      message: 'Price should be positive',
                    },
                  ]}
                >
                  <InputNumber
                    size='small'
                    style={{width: '100%'}}
                    className='form-control form-control-lg'
                    autoComplete='off'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col'>
                <label htmlFor='categoryId' className='required form-label'>
                  Category Name
                </label>
                {/* <Select
                defaultValue={selectedOption}
                onChange={(value) => {
                  getVariations(value['value'])
                    .then((value) => setVariations(value))
                    .catch((e) => setVariations([]))
                  setSelectedOption(value)
                  console.log(value)
                }}
                options={hintCategoryData}
                styles={selectStyle}
              /> */}
                <Form.Item
                  name='categoryId'
                  rules={[{required: true, message: 'Category Name is required'}]}
                >
                  <AutoComplete
                    options={hintCategoryData}
                    style={{display: 'block'}}
                    filterOption={(inputValue, option) => {
                      return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }}
                    allowClear={true}
                    onChange={(value) => {
                      setSelectedOption({
                        label: value,
                        value: '',
                      })
                    }}
                    onSelect={(value, option) => {
                      handleGetVariation(option['id'])
                      setSelectedOption({
                        label: value,
                        value: option.id,
                      })
                    }}
                  >
                    <Input className='form-control form-control-lg' placeholder='Select' />
                  </AutoComplete>
                </Form.Item>
              </div>
              <div className='col'>
                <label htmlFor='stock' className='form-label'>
                  Stock
                </label>
                <Form.Item
                  name='stock'
                  rules={[
                    {
                      type: 'integer',
                      min: 0,
                      message: 'Stock should be positive',
                    },
                  ]}
                >
                  {/* <InputNumber
                    type='number'
                    className='form-control form-control-lg'
                    autoComplete='off'
                    value={stock}
                    disabled={stock > 0}
                    style={stock > 0 ? {cursor: 'not-allowed'} : {}}
                  /> */}

                  <InputNumber
                    size='small'
                    className='form-control form-control-lg'
                    autoComplete='off'
                    disabled={stock > 0}
                    style={stock > 0 ? {cursor: 'not-allowed'} : {}}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col'>
                <p className='form-label'>Upload Image</p>
                <ImageUpload
                  // image={formik.values.image}
                  image={form.getFieldValue('image')}
                  imageLoading={imageLoading}
                  // imageUrl={image}
                  changeAvatar={changeAvatar}
                />
              </div>
            </div>
            {variations.length > 0 && <VariationForm form={form} variations={variations} />}
            <div className='rounded border d-flex flex-column p-10 mb-8 mt-8'>
              <label htmlFor='' className='required form-label'>
                Description
              </label>
              <Form.Item
                name='description'
                rules={[{required: true, message: 'Description is required!'}]}
              >
                <textarea
                  className='form-control form-control'
                  data-kt-autosize='true'
                  rows={4}
                  // {...formik.getFieldProps('description')}
                ></textarea>
              </Form.Item>
            </div>
            {/*begin::Image input*/}
          </div>
          <div className='card-footer'>
            <Button loading={loading} name={!isCreateMode ? 'Update' : 'Create'} />
          </div>
        </div>
      </Form>
    </CreateEditProductContext.Provider>
  )
}

export default CreateEditProduct
