import {useState, useEffect, useContext} from 'react'
import {Form, InputNumber, Radio} from 'antd'
import {calculateDisplayPrice} from '../../../../../../utils'
import DealContext from '../../contexts/DealContext'

interface Props {
  name: number
  form: any
  restField: any
}

function DiscountField({name, restField, form}: Props) {
  const {isStockChange} = useContext(DealContext)
  const [isFlatDiscount, setIsFlatDiscount] = useState(false)
  useEffect(() => {
    const deals = form.getFieldValue('deals')
    if (deals && deals.length > 0) {
      setIsFlatDiscount(deals[restField.fieldKey]?.offer_type === 'flat')
    }
  }, [])
  return (
    <>
      <div className='form-check form-check-custom form-check-solid mb-2'>
        <Form.Item {...restField} name={[name, 'offer_type']} initialValue='percent'>
          <Radio.Group
            onChange={(e) => {
              setIsFlatDiscount(e.target.value === 'flat')
              const discount = form!.getFieldValue(['deals', name, 'offer_value'])
              let fieldsValue = form!.getFieldsValue()
              const product_price = fieldsValue['deals'][name]['original_price']
              fieldsValue['deals'][name]['display_price'] = calculateDisplayPrice(
                e.target.value === 'flat',
                product_price,
                discount
              )
              form!.setFieldsValue(fieldsValue)
            }}
            disabled={isStockChange}
          >
            <Radio value='percent'>Percent Discount</Radio>
            <Radio value='flat'>Flat Discount</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      {isFlatDiscount ? (
        <Form.Item
          {...restField}
          rules={[
            {required: true, message: 'Rate is required'},
            {
              type: 'integer',
              min: 0,
              message: 'Rate should be positive',
            },
          ]}
          name={[name, 'offer_value']}
        >
          <InputNumber
            style={{width: '100%'}}
            addonAfter={'.00'}
            addonBefore={'Rs.'}
            placeholder='e.g. 25'
            min={0}
            onChange={(discount) => {
              let fieldsValue = form!.getFieldsValue()
              const product_price = fieldsValue['deals'][name]['original_price']
              if (discount < 0 || discount > product_price) {
                return
              }
              fieldsValue['deals'][name]['display_price'] = calculateDisplayPrice(
                form!.getFieldValue(['deals', name, 'offer_type']) === 'flat',
                product_price,
                discount
              )
              form!.setFieldsValue(fieldsValue)
            }}
            disabled={isStockChange}
          />
        </Form.Item>
      ) : (
        <Form.Item
          {...restField}
          rules={[
            {required: true, message: 'Rate is required'},
            {
              type: 'integer',
              min: 0,
              message: 'Rate should be positive',
            },
            {
              type: 'integer',
              max: 100,
              message: 'Rate should be less than 100',
            },
          ]}
          name={[name, 'offer_value']}
        >
          <InputNumber
            style={{width: '100%'}}
            addonAfter={'%'}
            placeholder='e.g. 25'
            min={0}
            max={100}
            onChange={(discount) => {
              if (discount < 0 || discount > 100) {
                return
              }
              let fieldsValue = form!.getFieldsValue()
              const product_price = fieldsValue['deals'][name]['original_price']
              fieldsValue['deals'][name]['display_price'] = calculateDisplayPrice(
                form!.getFieldValue(['deals', name, 'offer_type']) === 'flat',
                product_price,
                discount
              )
              form!.setFieldsValue(fieldsValue)
            }}
            disabled={isStockChange}
          />
        </Form.Item>
      )}
      {/* <Form.Item
        {...restField}
        rules={[
          {required: true, message: 'Rate is required'},
          // {max: 3, message: 'Percent is invalid'},
        ]}
        name={[name, 'offer_value']}
      >
        <Input
          type='number'
          addonAfter={!isFlatDiscount ? '%' : '.00'}
          addonBefore={isFlatDiscount && 'Rs.'}
          placeholder='e.g. 25'
          onChange={(event) => {
            const discount = +event.target.value
            console.log(discount, isFlatDiscount)
            if (!isFlatDiscount && discount > 100) {
              return
            }
            let fieldsValue = form!.getFieldsValue()
            const product_price = fieldsValue['deals'][name]['original_price']
            fieldsValue['deals'][name]['display_price'] = calculateDisplayPrice(
              form!.getFieldValue(['deals', name, 'offer_type']) === 'flat',
              product_price,
              discount
            )
            form!.setFieldsValue(fieldsValue)
          }}
        />
      </Form.Item> */}
    </>
  )
}

export default DiscountField
