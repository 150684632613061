/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'

import {getMerchant, putDisableMerchant, putMerchant} from '../api/merchant'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'
import {MerchantModel} from '../../auth/models/UserModel'
import {parseDate} from '../../../../utils/dateAndTime'
import ModalActions from '../../../components/Modals/ModalAction'
import {toast} from 'react-toastify'
import ModalDelete from '../../../components/Modals/ModalDelete'
import GoBack from '../../../components/partials/GoBack'

export interface IPageParams {
  id: string
}
const URL = '/merchant'
const ViewMerchant: FC = () => {
  const {id} = useParams<IPageParams>()
  const [user, setUser] = useState<MerchantModel>()

  useEffect(() => {
    getMerchant(id)
      .then(({data}) => {
        setUser(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  function handleVerify(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    putMerchant(id, {is_verified: true}).then(({data}) => {
      setUser(data.result)
      toast.success('Success!')
    })
  }

  function handleReject(event: React.ChangeEvent<any>, id: string, rejectionReason: string) {
    event.preventDefault()
    putDisableMerchant(id, {rejection_reason: rejectionReason, is_verified: false})
      .then(({data}) => {
        setUser(data.result)
        toast.success('Success!')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleDisableMerchant(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    putDisableMerchant(id, {is_verified: false})
      .then(() => {
        toast.success('Success!')
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>View Merchant</PageTitle>
      <ModalActions
        id={id}
        handleAction={handleVerify}
        handleReject={handleReject}
        text={'Verify Merchant'}
      />
      <ModalDelete
        id={id}
        handleDelete={handleDisableMerchant}
        name='Are you sure you want to disable the merchant?'
        buttonName='Disable'
      />
      {/*begin::Card*/}
      {user && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::User Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img
                  src={user.image ? user.image : toAbsoluteUrl('/media/avatars/150-1.jpg')}
                  alt='avatar'
                />
              </div>
              {/*end::Avatar*/}
              {/*begin::Name*/}
              <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-3'>
                {user?.display_name}
              </a>
              {/*end::Name*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>{user.email}</div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::User Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <GoBack url={URL} className='btn btn-sm btn-light me-2' />
                {!user.is_verified && (
                  <a
                    href='#'
                    className='btn btn-sm btn-light-danger me-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal'
                  >
                    Verify
                  </a>
                )}

                {user.is_verified && (
                  <a
                    href='#'
                    className='btn btn-sm btn-light-danger me-2'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_delete'
                  >
                    Disable
                  </a>
                )}
                <Link to={`/merchant/edit/${id}`}>
                  <a className='btn btn-sm btn-light-primary'>Edit</a>
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_user_view_details' className='collapse show'>
              <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='rounded border p-3'>
                  <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                        Profile
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                        Business Information
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
                        Bank Account
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_4'>
                        Warehouse Address
                      </a>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
                        Return Address
                      </a>
                    </li>
                  </ul>
                  <div className='tab-content' id='myTabContent'>
                    <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
                      <div className='pb-5 fs-6'>
                        {/*begin::Details item*/}
                        <div className='fw-bolder mt-5'>Account ID</div>
                        <div className='text-gray-600'>{user?._id}</div>
                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        {user.full_name && (
                          <>
                            <div className='fw-bolder mt-5'>Full Name</div>
                            <div className='text-gray-600'>{user?.full_name}</div>
                          </>
                        )}

                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        <div className='fw-bolder mt-5'>Phone Number</div>
                        <div className='text-gray-600'>
                          <a href='#' className='text-gray-600 text-hover-primary'>
                            {user.phone_number}
                          </a>
                        </div>
                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        <div className='fw-bolder mt-5'>Display Name</div>
                        <div className='text-gray-600'>{user.display_name}</div>
                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        <div className='fw-bolder mt-5'>Holiday Mode</div>
                        <div className='text-gray-600'>{user.holiday_mode ? 'Yes' : 'No'}</div>
                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        {user.holiday_mode && (
                          <>
                            <div className='fw-bolder mt-5'>Holiday Mode From</div>
                            <div className='text-gray-600'>{parseDate(user.holiday_mode_from)}</div>
                            <div className='fw-bolder mt-5'>Holiday Mode To</div>
                            <div className='text-gray-600'>{parseDate(user.holiday_mode_to)}</div>
                          </>
                        )}
                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        <div className='fw-bolder mt-5'>Joined Date</div>
                        <div className='text-gray-600'>{parseDate(user?.createdAt)}</div>
                        {/*end::Details item*/}
                        {/*begin::Details item*/}
                        <div className='fw-bolder mt-5'>Status</div>
                        <div className='text-gray-600'>
                          {user.is_verified ? (
                            <div className='badge badge-light-success fw-bolder'>Verified</div>
                          ) : (
                            <div className='badge badge-light-danger fw-bolder'>Not Verified</div>
                          )}
                        </div>
                        {/*end::Details item*/}
                      </div>
                    </div>
                    <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
                      <div className='pb-5 fs-6'>
                        {/*begin::Details item*/}
                        {user.business_information?.legal_name && (
                          <>
                            {/*begin::Details item*/}
                            {user.business_information.legal_name && (
                              <>
                                <div className='fw-bolder mt-5'>Legal Name</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.legal_name}
                                </div>
                              </>
                            )}
                            {user.business_information.address && (
                              <>
                                <div className='fw-bolder mt-5'>Address</div>
                                <div className='text-gray-600'>
                                  Address: {user?.business_information.address}
                                </div>
                              </>
                            )}
                            {user.business_information.country && (
                              <>
                                <div className='fw-bolder mt-5'>Country</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.country}
                                </div>
                              </>
                            )}
                            {user.business_information.person_in_charge && (
                              <>
                                <div className='fw-bolder mt-5'>Person In Charge</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.person_in_charge}
                                </div>
                              </>
                            )}
                            {user.business_information.registration_number && (
                              <>
                                <div className='fw-bolder mt-5'>Registration Number</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.registration_number}
                                </div>
                              </>
                            )}
                            {user.business_information.zone && (
                              <>
                                <div className='fw-bolder mt-5'>Province</div>
                                <div className='text-gray-600'>
                                  <div className='text-gray-600'>
                                    {user?.business_information.zone}
                                  </div>
                                </div>
                              </>
                            )}
                            {user.business_information.area && (
                              <>
                                <div className='fw-bolder mt-5'>Area</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.area}
                                </div>
                              </>
                            )}
                            {user.business_information.region && (
                              <>
                                <div className='fw-bolder mt-5'>District</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.region}
                                </div>
                              </>
                            )}
                            {user.business_information.person_in_charge_phone_number && (
                              <>
                                <div className='fw-bolder mt-5'>Person In Charge Phone Number</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.person_in_charge_phone_number}
                                </div>
                              </>
                            )}
                            {user.business_information.person_in_charge_email && (
                              <>
                                <div className='fw-bolder mt-5'>Person In Charge Email</div>
                                <div className='text-gray-600'>
                                  {user?.business_information.person_in_charge_email}
                                </div>
                              </>
                            )}
                          </>
                        )}
                        {/*end::Details item*/}
                      </div>
                    </div>
                    <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
                      <div className='pb-5 fs-6'>
                        {/*begin::Details item*/}
                        {user.bank_account && (
                          <>
                            {user.bank_account?.account_title && (
                              <>
                                <div className='fw-bolder mt-5'>Account Title</div>
                                <div className='text-gray-600'>
                                  Account Title: {user?.bank_account?.account_title}
                                </div>
                              </>
                            )}
                            {user.bank_account?.account_number && (
                              <>
                                <div className='fw-bolder mt-5'>Account Number</div>
                                <div className='text-gray-600'>
                                  {user?.bank_account?.account_number}
                                </div>
                              </>
                            )}
                            {user.bank_account?.bank_name && (
                              <>
                                <div className='fw-bolder mt-5'> Bank Name</div>
                                <div className='text-gray-600'>{user?.bank_account?.bank_name}</div>
                              </>
                            )}
                            {user.bank_account?.branch_name && (
                              <>
                                <div className='fw-bolder mt-5'> Branch Name</div>
                                <div className='text-gray-600'>
                                  Branch Name: {user?.bank_account?.branch_name}
                                </div>
                              </>
                            )}
                            {user.bank_account?.tax_category && (
                              <>
                                <div className='fw-bolder mt-5'> Tax Category</div>
                                <div className='text-gray-600'>
                                  {user?.bank_account?.tax_category}
                                </div>
                              </>
                            )}
                            {user.bank_account?.cheque_image && (
                              <>
                                <div className='fw-bolder mt-5'>Cheque Image:</div>
                                <div className='text-gray-600'>
                                  <img
                                    width='100px'
                                    height='100px'
                                    src={user?.bank_account?.cheque_image}
                                    alt='cheque'
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {/*end::Details item*/}
                      </div>
                    </div>
                    <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
                      <div className='pb-5 fs-6'>
                        {/*begin::Details item*/}
                        {user.warehouse_address && (
                          <>
                            {user.warehouse_address.full_name && (
                              <>
                                <div className='fw-bolder mt-5'>FullName</div>
                                <div className='text-gray-600'>
                                  {user?.warehouse_address?.full_name}
                                </div>
                              </>
                            )}
                            {user.warehouse_address.address && (
                              <>
                                <div className='fw-bolder mt-5'>Address</div>
                                <div className='text-gray-600'>
                                  {user?.warehouse_address?.address}
                                </div>
                              </>
                            )}

                            {user.warehouse_address.phone_number && (
                              <>
                                <div className='fw-bolder mt-5'>Phone Number</div>
                                <div className='text-gray-600'>
                                  {user?.warehouse_address?.phone_number}
                                </div>
                              </>
                            )}

                            {user.warehouse_address.email && (
                              <>
                                <div className='fw-bolder mt-5'>Email</div>
                                <div className='text-gray-600'>
                                  {user?.warehouse_address?.email}
                                </div>
                              </>
                            )}

                            {user.warehouse_address.city && (
                              <>
                                <div className='fw-bolder mt-5'>City</div>
                                <div className='text-gray-600'>{user?.warehouse_address?.city}</div>
                              </>
                            )}
                            {user.warehouse_address.country && (
                              <>
                                <div className='fw-bolder mt-5'>Country</div>
                                <div className='text-gray-600'>
                                  {user?.warehouse_address?.country}
                                </div>
                              </>
                            )}

                            {user.warehouse_address.zone && (
                              <>
                                <div className='fw-bolder mt-5'>Province</div>
                                <div className='text-gray-600'>{user?.warehouse_address?.zone}</div>
                              </>
                            )}

                            {user.warehouse_address.region && (
                              <>
                                <div className='fw-bolder mt-5'>District</div>
                                <div className='text-gray-600'>
                                  {user?.warehouse_address?.region}
                                </div>
                              </>
                            )}

                            {user.warehouse_address.area && (
                              <>
                                <div className='fw-bolder mt-5'>Area</div>
                                <div className='text-gray-600'>{user?.warehouse_address?.area}</div>
                              </>
                            )}
                          </>
                        )}
                        {/*end::Details item*/}
                      </div>
                    </div>
                    <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
                      <div className='pb-5 fs-6'>
                        {/*begin::Details item*/}
                        {user.return_address && (
                          <>
                            {user.return_address.full_name && (
                              <>
                                <div className='fw-bolder mt-5'>Fullname </div>
                                <div className='text-gray-600'>
                                  {user?.return_address?.full_name}
                                </div>
                              </>
                            )}
                            {user.return_address.address && (
                              <>
                                <div className='fw-bolder mt-5'>Address</div>
                                <div className='text-gray-600'>{user?.return_address?.address}</div>
                              </>
                            )}

                            {user.return_address.phone_number && (
                              <>
                                <div className='fw-bolder mt-5'>Phone Number</div>
                                <div className='text-gray-600'>
                                  {user?.return_address?.phone_number}
                                </div>
                              </>
                            )}

                            {user.return_address.email && (
                              <>
                                <div className='fw-bolder mt-5'>Email</div>
                                <div className='text-gray-600'>{user?.return_address?.email}</div>
                              </>
                            )}

                            {user.return_address.city && (
                              <>
                                <div className='fw-bolder mt-5'>City</div>
                                <div className='text-gray-600'>
                                  City: {user?.return_address?.city}
                                </div>
                              </>
                            )}
                            {user.return_address.country && (
                              <>
                                <div className='fw-bolder mt-5'>Country</div>
                                <div className='text-gray-600'>{user?.return_address?.country}</div>
                              </>
                            )}

                            {user.return_address.zone && (
                              <>
                                <div className='fw-bolder mt-5'>Province</div>
                                <div className='text-gray-600'>{user?.return_address?.zone}</div>
                              </>
                            )}

                            {user.return_address.region && (
                              <>
                                <div className='fw-bolder mt-5'>District</div>
                                <div className='text-gray-600'>
                                  Region: {user?.return_address?.region}
                                </div>
                              </>
                            )}

                            {user.return_address.area && (
                              <>
                                <div className='fw-bolder mt-5'>Area</div>
                                <div className='text-gray-600'>{user?.return_address?.area}</div>
                              </>
                            )}
                          </>
                        )}
                        {/*end::Details item*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewMerchant
