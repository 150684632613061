import axios from 'axios'
import {FeatureAdListModel, FeatureAdModelResult, FeatureAdModel} from '../interface/FeatureAd'

const API_URL = process.env.REACT_APP_API_URL

const FEATURE_AD_GETALL_URL = `${API_URL}/feature-ads/all`
const FEATURE_AD_REQ_URL = `${API_URL}/feature-ad/`

export const getAllFeatureAd = async (page: string): Promise<FeatureAdListModel> => {
  const res = await axios.get(FEATURE_AD_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getFeatureAd = (id: string): Promise<FeatureAdModelResult> => {
  return axios.get(FEATURE_AD_REQ_URL + id)
}

export const putFeatureAd = (id: string, data: FeatureAdModel): Promise<FeatureAdModelResult> => {
  return axios.put(FEATURE_AD_REQ_URL + id, data)
}

export const postFeatureAd = (data: FeatureAdModel): Promise<FeatureAdModelResult> => {
  return axios.post(FEATURE_AD_REQ_URL, data)
}

export const deleteFeatureAd = (id: string): Promise<FeatureAdModelResult> => {
  return axios.delete(FEATURE_AD_REQ_URL + id)
}

export const searchFeatureAd = async (name: string): Promise<FeatureAdListModel> => {
  const res = await axios.get(FEATURE_AD_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
