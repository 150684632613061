import {Tag} from 'antd'
import {Link} from 'react-router-dom'
import {Deal} from '../../../../app/pages/dashboard/interface/IDashboardModel'
import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  deals: Deal[] | undefined
}
const DealsTable = ({deals}: Props) => {
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-150px'>Deals</th>
            <th className='min-w-150px'>Variation</th>
            <th className='min-w-120px'>Display Price</th>
            <th className='min-w-120px'>Offer Type</th>
            <th className='min-w-120px'>Order Value (in Rs.)</th>
            <th className='min-w-120px'></th>
            {/* <th className='min-w-100px text-end'>Actions</th> */}
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {deals &&
            deals.length > 0 &&
            deals.map(({_id, deals, title}, idx) => (
              <tr key={idx}>
                <td>{title}</td>
                <td>
                  {deals.length > 0 && deals[0]?.variation && deals[0]?.variation[0] ? (
                    Object.keys(deals[0].variation[0]).map((el, idx) => (
                      <Tag key={idx} color='magenta'>
                        {el.toUpperCase()}: {deals[0].variation[0][el]}
                      </Tag>
                    ))
                  ) : (
                    <Tag color='yellow'>No Variations</Tag>
                  )}
                </td>
                <td>Rs. {deals.length > 0 && deals[0].display_price}</td>
                <td>
                  <Tag
                    color={
                      deals.length > 0 && deals[0].offer_type.toUpperCase() === 'FLAT'
                        ? 'cyan'
                        : 'gold'
                    }
                  >
                    {deals.length > 0 && deals[0].offer_type.toUpperCase()}
                  </Tag>
                </td>
                <td>Rs. {deals.length > 0 && deals[0].offer_value}</td>
                <td className='text-end'>
                  <Link
                    to={'/deal/view/' + _id}
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                  </Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default DealsTable
