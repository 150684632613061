import {OrderListModel} from '../../../../app/modules/order-management/interface/Order'
import moment from 'moment'
import {Tag} from 'antd'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../helpers'
import {parseValueToCeil} from '../../../../utils/parseNumber'

interface Props {
  orderList: OrderListModel | undefined
}
const OrderTable = ({orderList}: Props) => {
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-150px'>Deals</th>
            <th className='min-w-150px'>Customer</th>
            <th className='min-w-120px'>No of Items</th>
            <th className='min-w-120px'>Price</th>
            <th className='min-w-120px'>Order Created</th>
            <th className='min-w-120px'>Actions</th>
            {/* <th className='min-w-100px text-end'>Actions</th> */}
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {orderList?.data.slice(0, 7).map((e, idx) => (
            <tr key={idx}>
              <td>
                {e.order_names.slice(0, 5).map((e, idx) => (
                  <Tag key={idx} color='magenta' className='mb-2'>
                    {e}
                  </Tag>
                ))}
              </td>
              <td>
                {/* {e.user_id.full_name} */}
                {e.express_checkout ? (
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='text-dark fw-bolder fs-6'>
                      {e.express_checkout.phone_number}
                    </span>
                    {e.express_checkout.email && (
                      <span className='text-muted fw-bold text-muted d-block fs-7'>
                        {e.express_checkout.email}
                      </span>
                    )}
                  </div>
                ) : (
                  <div className='d-flex justify-content-start flex-column'>
                    <span className='text-dark fw-bolder fs-6'>
                      {e.user_id && e.user_id.full_name}
                    </span>
                    <span className='text-muted fw-bold text-muted d-block fs-7'>{e._id}</span>
                  </div>
                )}
              </td>
              <td>{e.no_of_orders}</td>
              <td>Rs. {parseValueToCeil(e.total)}</td>
              <td>{moment(e.createdAt).fromNow()}</td>
              <td className='text-end'>
                <Link
                  to={'/order/view/' + e._id}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                >
                  <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  )
}

export default OrderTable
