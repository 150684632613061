/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Link, useParams} from 'react-router-dom'

import Pagination from '../../../components/Pagination'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Search from '../../../components/Search'
import {FaqListModel, FaqQuery} from '../interface/IFaq'
import {deleteFaq, getAllFaq} from '../api/faq'
import {parseDate} from '../../../../utils/dateAndTime'
import Spinner from '../../../components/Spinner'
import NoData from '../../../components/common/NoData'

interface IPageParams {
  page: string
}

const ListFaq: FC = () => {
  const [faqList, setFaqList] = useState<FaqListModel>()
  const [deleteId, setDeleteId] = useState<string>('')
  const {page} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [faqQuery, setFaqQuery] = useState<FaqQuery>({
    page,
    filter_by: 'all',
    search: '',
  })

  function getFaq(faqQuery: FaqQuery) {
    setLoading(true)
    getAllFaq(faqQuery)
      .then((data) => {
        setLoading(false)
        setFaqList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }
  useEffect(() => {
    getFaq(faqQuery)
  }, [faqQuery])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteFaq(id).then((data) => {
      toast.success('Success!')
      getFaq(faqQuery)
    })
  }
  function handleSearch(event: React.ChangeEvent<any>) {
    event.preventDefault()
    const {value} = event.target
    const query = {...faqQuery, search: value}
    setFaqQuery(query)
    if (value === '') {
      getFaq(query)
      return
    }
    getFaq(query)
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card faq*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}
                <Search
                  name={faqQuery.search!}
                  placeholder='Search FAQ'
                  handleSearch={handleSearch}
                />
                {/*end::Search*/}
                <select
                  className='form-select form-select-solid ms-3'
                  data-placeholder='Select option'
                  defaultValue='all'
                  value={faqQuery.filter_by}
                  onChange={(event) => {
                    setFaqQuery({...faqQuery, filter_by: event.target.value})
                  }}
                >
                  <option value='all' disabled>
                    Filter Status
                  </option>
                  <option value='all'>BOTH</option>
                  <option value='true'>ACTIVE</option>
                  <option value='false'>INACTIVE</option>
                </select>
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Modal - Create*/}
                <div className='d-flex justify-content-end' data-kt-promo-table-toolbar='base'>
                  <Link className='btn btn-primary' to='/faq/create'>
                    Create FAQ
                  </Link>
                </div>
                {/*end::Modal - Create*/}
                {/*begin::Modal - Delete*/}
                <ModalDelete id={deleteId} handleDelete={handleDelete} />
                {/*end::Modal - delete*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card faq*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_faqs_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Faq
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Status
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            style={{width: '106.433px'}}
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {faqList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {faqList.data.length === 0 && <NoData />}
                          {faqList.data.length !== 0 &&
                            faqList.data.map(({_id, answer, is_active, question, createdAt}) => {
                              return (
                                <tr className='odd' key={_id}>
                                  <td className='d-flex align-items-center'>
                                    {/*begin::Order details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/faq/view/${_id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {question}
                                      </Link>
                                      <span>
                                        {answer.slice(0, 80)}
                                        {answer.length > 80 && '...'}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    {is_active ? (
                                      <div className='badge badge-light-success fw-bolder'>
                                        ACTIVE
                                      </div>
                                    ) : (
                                      <div className='badge badge-light-danger fw-bolder'>
                                        INACTIVE
                                      </div>
                                    )}
                                  </td>
                                  <td>{parseDate(createdAt)}</td>
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Link
                                      to={`/faq/edit/${_id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(_id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='faq'
                  currentPage={page}
                  prev_page={faqList?.prev_page}
                  next_page={faqList?.next_page}
                  total_number_of_page={faqList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListFaq
