import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {merchantResetPassword} from '../redux/AuthCRUD'
import Button from '../../../components/common/Button'
import {useLocation} from 'react-router'
import {ResultModel} from '../../../interface/Result'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .required('Confirmation Password is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function MerchantResetPassword() {
  const {search} = useLocation()
  const queryParams = new URLSearchParams(search)
  const token = queryParams.get('token')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState<ResultModel>({error: undefined, result: ''})
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setSubmitting}) => {
      setLoading(true)
      const data = {
        password: values.password,
        token,
      }
      merchantResetPassword(data)
        .then(({data}) => {
          setMessage(data)
          setLoading(false)
        })
        .catch((error) => {
          setMessage(error.response.data)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Reset Password</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>
            Enter your new password and confirm password.
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {message.error === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{message.result}</div>
          </div>
        )}

        {message.error === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              {message.result}
              <Link
                to='/merchant/login'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
                tabIndex={-1}
              >
                Sign In ?
              </Link>
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('confirmPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword},
              {
                'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
              }
            )}
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <Button loading={loading} name='Submit' />
          <Link to='/auth/login'>
            <button
              type='button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
