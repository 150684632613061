export const bankList = [
  {
    value: 'Agricultural Development Bank Ltd.',
    label: 'Agricultural Development Bank Ltd.',
  },
  {
    value: 'Bank of Kathmandu Ltd.',
    label: 'Bank of Kathmandu Ltd.',
  },
  {
    value: 'Best Finance Company Ltd.',
    label: 'Best Finance Company Ltd.',
  },
  {
    value: 'Bhargav Bikas Bank Ltd.',
    label: 'Bhargav Bikas Bank Ltd.',
  },
  {
    value: 'Central Finance Ltd.',
    label: 'Central Finance Ltd.',
  },
  {
    value: 'Century Bank Ltd.',
    label: 'Century Bank Ltd.',
  },
  {
    value: 'Citizen Bank International Ltd.',
    label: 'Citizen Bank International Ltd.',
  },
  {
    value: 'City Express Finance Co. Ltd.',
    label: 'City Express Finance Co. Ltd.',
  },
  {
    value: 'Civil Bank Ltd.',
    label: 'Civil Bank Ltd.',
  },
  {
    value: 'Corporate Development Bank Ltd.',
    label: 'Corporate Development Bank Ltd.',
  },
  {
    value: 'Deva Bikash Bank Ltd.',
    label: 'Deva Bikash Bank Ltd.',
  },
  {
    value: 'Everest Bank Ltd.',
    label: 'Everest Bank Ltd.',
  },
  {
    value: 'Excel Development Bank Ltd.',
    label: 'Excel Development Bank Ltd.',
  },
  {
    value: 'Gandaki Bikash Bank Ltd.',
    label: 'Gandaki Bikash Bank Ltd.',
  },
  {
    value: 'Garima Bikas Bank Ltd.',
    label: 'Garima Bikas Bank Ltd.',
  },
  {
    value: 'Global IME Bank Ltd.',
    label: 'Global IME Bank Ltd.',
  },
  {
    value: 'Goodwill Finance Ltd.',
    label: 'Goodwill Finance Ltd.',
  },
  {
    value: 'Green Development Bank Ltd.',
    label: 'Green Development Bank Ltd.',
  },
  {
    value: 'Guheshwori Merchant Banking Finance Ltd.',
    label: 'Guheshwori Merchant Banking Finance Ltd.',
  },
  {
    value: 'Gurkhas Finance Ltd.',
    label: 'Gurkhas Finance Ltd.',
  },
  {
    value: 'Hamro Bikash Bank Ltd.',
    label: 'Hamro Bikash Bank Ltd.',
  },
  {
    value: 'Hathway Finance Co. Ltd.',
    label: 'Hathway Finance Co. Ltd.',
  },
  {
    value: 'Himalayan Bank Ltd.',
    label: 'Himalayan Bank Ltd.',
  },
  {
    value: 'ICFC Finance Ltd.',
    label: 'ICFC Finance Ltd.',
  },
  {
    value: 'Janaki Finance Co. Ltd.',
    label: 'Janaki Finance Co. Ltd.',
  },
  {
    value: 'Janata Bank Nepal Ltd.',
    label: 'Janata Bank Nepal Ltd.',
  },
  {
    value: 'Jebils Finance Ltd.',
    label: 'Jebils Finance Ltd.',
  },
  {
    value: 'Jyoti Bikash Bank Ltd.',
    label: 'Jyoti Bikash Bank Ltd.',
  },
  {
    value: 'Kabeli Bikash Bank Ltd.',
    label: 'Kabeli Bikash Bank Ltd.',
  },
  {
    value: 'Kailash Bikash Bank Ltd.',
    label: 'Kailash Bikash Bank Ltd.',
  },
  {
    value: 'Kamana Sewa Bikash Bank Ltd.',
    label: 'Kamana Sewa Bikash Bank Ltd.',
  },
  {
    value: 'Kanchan Development Bank Ltd.',
    label: 'Kanchan Development Bank Ltd.',
  },
  {
    value: 'Kankai Bikash Bank Ltd.',
    label: 'Kankai Bikash Bank Ltd.',
  },
  {
    value: 'Karnali Bikash Bank Ltd.',
    label: 'Karnali Bikash Bank Ltd.',
  },
  {
    value: 'Kumari Bank Ltd.',
    label: 'Kumari Bank Ltd.',
  },
  {
    value: 'Lalitpur Finance Co. Ltd.',
    label: 'Lalitpur Finance Co. Ltd.',
  },
  {
    value: 'Laxmi Bank Ltd.',
    label: 'Laxmi Bank Ltd.',
  },
  {
    value: 'Lumbini Bikash Bank Ltd.',
    label: 'Lumbini Bikash Bank Ltd.',
  },
  {
    value: 'Machapuchchhre Bank Ltd.',
    label: 'Machapuchchhre Bank Ltd.',
  },
  {
    value: 'Mahalaxmi Bikash Bank Ltd.',
    label: 'Mahalaxmi Bikash Bank Ltd.',
  },
  {
    value: 'Manjushree Finance Ltd.',
    label: 'Manjushree Finance Ltd.',
  },
  {
    value: 'Mega Bank Nepal Ltd.',
    label: 'Mega Bank Nepal Ltd.',
  },
  {
    value: 'Mission Development Bank Ltd.',
    label: 'Mission Development Bank Ltd.',
  },
  {
    value: 'Miteri Development Bank Ltd.',
    label: 'Miteri Development Bank Ltd.',
  },
  {
    value: 'Muktinath Bikash Bank Ltd.',
    label: 'Muktinath Bikash Bank Ltd.',
  },
  {
    value: 'Nabil Bank Ltd.',
    label: 'Nabil Bank Ltd.',
  },
  {
    value: 'Nepal Bangladesh Bank Ltd.',
    label: 'Nepal Bangladesh Bank Ltd.',
  },
  {
    value: 'Nepal Bank Ltd.',
    label: 'Nepal Bank Ltd.',
  },
  {
    value: 'Nepal Community Development Bank Ltd.',
    label: 'Nepal Community Development Bank Ltd.',
  },
  {
    value: 'Nepal Credit and Commerce Bank Ltd.',
    label: 'Nepal Credit and Commerce Bank Ltd.',
  },
  {
    value: 'Nepal Finance Ltd.',
    label: 'Nepal Finance Ltd.',
  },
  {
    value: 'Nepal Investment Bank Ltd.',
    label: 'Nepal Investment Bank Ltd.',
  },
  {
    value: 'Nepal SBI Bank Ltd.',
    label: 'Nepal SBI Bank Ltd.',
  },
  {
    value: 'NIC Asia Bank Ltd.',
    label: 'NIC Asia Bank Ltd.',
  },
  {
    value: 'NMB Bank Ltd.',
    label: 'NMB Bank Ltd.',
  },
  {
    value: 'Om Development Bank Ltd.',
    label: 'Om Development Bank Ltd.',
  },
  {
    value: 'Payoneer',
    label: 'Payoneer',
  },
  {
    value: 'Pokhara Finance Ltd.',
    label: 'Pokhara Finance Ltd.',
  },
  {
    value: 'Prabhu Bank Ltd.',
    label: 'Prabhu Bank Ltd.',
  },
  {
    value: 'Prime Commercial Bank Ltd.',
    label: 'Prime Commercial Bank Ltd.',
  },
  {
    value: 'Progressive Finance Co. Ltd.',
    label: 'Progressive Finance Co. Ltd.',
  },
  {
    value: 'Purnima Bikash Bank Ltd.',
    label: 'Purnima Bikash Bank Ltd.',
  },
  {
    value: 'Rastriya Banijya Bank Ltd.',
    label: 'Rastriya Banijya Bank Ltd.',
  },
  {
    value: 'Reliance Finance Ltd.',
    label: 'Reliance Finance Ltd.',
  },
  {
    value: 'Sahayogi Vikash Bank Ltd.',
    label: 'Sahayogi Vikash Bank Ltd.',
  },
  {
    value: 'Sanima Bank Ltd.',
    label: 'Sanima Bank Ltd.',
  },
  {
    value: 'Saptakoshi Development Bank Ltd.',
    label: 'Saptakoshi Development Bank Ltd.',
  },
  {
    value: 'Shangri-La Development Bank Ltd.',
    label: 'Shangri-La Development Bank Ltd.',
  },
  {
    value: 'Shine Resunga Development Bank Ltd.',
    label: 'Shine Resunga Development Bank Ltd.',
  },
  {
    value: 'Shree Investment and Finance Co. Ltd.',
    label: 'Shree Investment and Finance Co. Ltd.',
  },
  {
    value: 'Siddhartha Bank Ltd.',
    label: 'Siddhartha Bank Ltd.',
  },
  {
    value: 'Sindhu Bikash Bank Ltd.',
    label: 'Sindhu Bikash Bank Ltd.',
  },
  {
    value: 'Srijana Finance Ltd.',
    label: 'Srijana Finance Ltd.',
  },
  {
    value: 'Standard Chartered Bank Nepal Ltd.',
    label: 'Standard Chartered Bank Nepal Ltd.',
  },
  {
    value: 'Sunrise Bank Ltd.',
    label: 'Sunrise Bank Ltd.',
  },
  {
    value: 'Synergic Finance Ltd.',
    label: 'Synergic Finance Ltd.',
  },
  {
    value: 'Tinau Bikash Bank Ltd.',
    label: 'Tinau Bikash Bank Ltd.',
  },
  {
    value: 'Tourism Development Bank Ltd',
    label: 'Tourism Development Bank Ltd',
  },
  {
    value: 'United Finance Ltd.',
    label: 'United Finance Ltd.',
  },
  {
    value: 'Western Development Bank Ltd.',
    label: 'Western Development Bank Ltd.',
  },
  {
    value: 'World Merchant Banking and Finance Ltd.',
    label: 'World Merchant Banking and Finance Ltd.',
  },
]
