import moment from 'moment'

export function parseDate(date) {
  return new Date(date).toLocaleString()
}

export function parseMomentDate(date) {
  return moment(date).format('MMMM Do YYYY')
}

export function parseDateOnly(date) {
  var dateObj = new Date(date)
  var month = dateObj.getUTCMonth() + 1
  var day = dateObj.getUTCDate()
  var year = dateObj.getUTCFullYear()
  return month + '/' + day + '/' + year
}
