import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import CreateEditProduct from './components/CreateEditProduct'
import ListProduct from './components/ListProduct'
import StockChange from './components/stockchange/StockChange'
import StockIn from './components/stockchange/StockIn'
import ViewProduct from './components/ViewProduct'

const ProductPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/product/stock/add/:id'>
          <PageTitle breadcrumbs={[]}>Stock</PageTitle>
          <StockChange />
        </Route>
        <Route path='/product/stock'>
          <PageTitle breadcrumbs={[]}>Stock In</PageTitle>
          <StockIn />
        </Route>
        <Route path='/product/edit/:id'>
          <PageTitle breadcrumbs={[]}>Update Product</PageTitle>
          <CreateEditProduct />
        </Route>
        <Route path='/product/view/:id'>
          <PageTitle breadcrumbs={[]}>View Product</PageTitle>
          <ViewProduct />
        </Route>
        <Route path='/product/add'>
          <PageTitle breadcrumbs={[]}>Add Product</PageTitle>
          <CreateEditProduct />
        </Route>
        <Route path='/product/:page'>
          <PageTitle breadcrumbs={[]}>List Product</PageTitle>
          <ListProduct />
        </Route>
        <Redirect from='/product' exact={true} to='/product/1' />
        <Redirect to='/product/1' />
      </Switch>
    </>
  )
}

export default ProductPage
