import React, {FC} from 'react'
import {FilterParams} from '../../interface/IMerchant'

interface Props {
  name: string
  menu: Array<string>
  filterParams: FilterParams
  setFilterParams: any
}

const MultilineFilter: FC<Props> = ({name, menu, filterParams, setFilterParams}) => {
  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }
  return (
    <>
      <select
        className='form-select form-select-solid'
        data-placeholder='Select option'
        defaultValue='all'
        // value={filterBy}
        name='is_verified'
        onChange={(event) => {
          handleFilter(event)
        }}
      >
        <option value='all' disabled>
          {name}
        </option>
        {menu &&
          menu.map((item, index) => (
            <option
              value={`${item === 'Both' ? '' : item === 'Verified' ? 'true' : 'false'}`}
              key={index}
            >
              {item}
            </option>
          ))}
      </select>
    </>
  )
}

export default MultilineFilter
