import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import ListDeal from './components/ListDeal'
import ViewDeal from './components/ViewDeal'

const DealPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/deal/view/:id'>
          <PageTitle breadcrumbs={[]}>View Deals</PageTitle>
          <ViewDeal />
        </Route>
        <Route path='/deal/:pageNo'>
          <PageTitle breadcrumbs={[]}>List Deal</PageTitle>
          <ListDeal />
        </Route>
        <Redirect from='/deal' exact={true} to='/deal/1' />
        <Redirect to='/deal/1' />
      </Switch>
    </>
  )
}

export default DealPage
