import {Checkbox, Divider} from 'antd'

interface Props {
  options: string[]
  checkedList: string[]
  onChange: any
  onCheckAllChange: any
  checkAll: any
  indeterminate: any
}

const CheckboxGroup = Checkbox.Group

const AttributeCheckbox = ({
  options,
  checkedList,
  checkAll,
  onChange,
  onCheckAllChange,
  indeterminate,
}: Props) => {
  return (
    <div className='mb-8'>
      <Divider />
      <CheckboxGroup options={options} value={checkedList} onChange={onChange} />
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
        All of them
      </Checkbox>
    </div>
  )
}

export default AttributeCheckbox
