import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditBanner from './banner/EditBanner'
import ListBanner from './banner/ListBanner'
import ViewBanner from './banner/ViewBanner'

const HeaderOfferPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/banner/edit/:id'>
          <PageTitle breadcrumbs={[]}>Banner Edit</PageTitle>
          <EditBanner />
        </Route>
        <Route path='/banner/view/:id'>
          <PageTitle breadcrumbs={[]}>Banner View</PageTitle>
          <ViewBanner />
        </Route>
        <Route path='/banner/:page'>
          <PageTitle breadcrumbs={[]}>Banner List</PageTitle>
          <ListBanner />
        </Route>
        <Redirect from='/banner' exact={true} to='/banner/1' />
        <Redirect to='/banner/1' />
      </Switch>
    </>
  )
}

export default HeaderOfferPage
