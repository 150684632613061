/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getNewsletter, putNewsletter} from '../api/newsletter'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {NewsletterModel} from '../interface/Newsletter'
import Button from '../../../components/common/Button'
import GoBack from '../../../components/partials/GoBack'
import {handleErrorMessage} from '../../../components/handleErrorMessage'

export interface IPageParams {
  id: string
}
const URL = '/newsletter'
const EditNewsletter: FC = () => {
  const {id} = useParams<IPageParams>()
  const [newsletter, setNewsletter] = useState<NewsletterModel>()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    getNewsletter(id).then(({data}) => {
      setNewsletter(data.result)
    })
  }, [id])

  function handleChange(event: React.ChangeEvent<any>) {
    const target = event.target
    const value = target.value
    const name = target.name
    const result = {...newsletter, [name]: value} as NewsletterModel
    setNewsletter(result)
  }

  function handleSubmit(event: React.ChangeEvent<any>) {
    event.preventDefault()
    setLoading(true)
    putNewsletter(id, newsletter as NewsletterModel)
      .then((data) => {
        setLoading(false)
        // history.push(`/newsletter/view/${id}`)
        history.push(URL)
        toast.success('Success!')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        const message = handleErrorMessage(error.response.data.result)
        toast.error(message[0])
      })
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit Newsletter</PageTitle>

      {newsletter && (
        <form onSubmit={handleSubmit}>
          <div className='card pt-4 mb-6 mb-xl-9'>
            {/*begin::Card body*/}
            <div className='card-body pt-0 pb-5'>
              {/*begin::Table*/}
              <table className='table align-middle table-row-dashed gy-5'>
                {/*begin::Table body*/}
                <tbody className='fs-6 fw-bold text-gray-600'>
                  <tr>
                    <td>Newsletter Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={newsletter.name}
                        onChange={handleChange}
                        name='name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Newsletter Email</td>
                    <td>
                      <input
                        type='email'
                        className='form-control'
                        value={newsletter.email}
                        onChange={handleChange}
                        name='email'
                        required
                      />
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
              <div className='d-flex justify-content-end align-items-center mt-15'>
                {/*begin::Button*/}
                <GoBack url={URL} />
                {/*end::Button*/}
                <Button loading={loading} name={'Update'} />
              </div>
            </div>
            {/*end::Card body*/}
          </div>
        </form>
      )}
    </>
  )
}

export default EditNewsletter
