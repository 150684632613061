import axios from 'axios'
import {AdminModel, MerchantModel} from '../models/UserModel'
import {parseJwt} from '../../../../utils/parseJwt'
import {MerchantResult} from '../models/UserListModel'
import {ResultModel} from '../../../interface/Result'

const API_URL = process.env.REACT_APP_API_URL
const API_MERCHANT_URL = process.env.REACT_APP_API_MERCHANT_URL

export const GET_ADMIN_BY_ACCESSTOKEN_URL = `${API_URL}/get-admin`
export const GET_MERCHANT_BY_ACCESSTOKEN_URL = `${API_MERCHANT_URL}/get-merchant`
export const ADMIN_LOGIN_URL = `${API_URL}/login`
export const MERCHANT_LOGIN_URL = `${API_MERCHANT_URL}/login`
export const MERCHANT_REGISTER_URL = `${API_MERCHANT_URL}/register`
export const ADMIN_REQUEST_PASSWORD_URL = `${API_URL}/reset_password`
export const MERCHANT_REQUEST_PASSWORD_URL = `${API_MERCHANT_URL}/reset_password`

// Server should return AuthModel
export function adminLogin(email: string, password: string) {
  return axios.post(ADMIN_LOGIN_URL, {email, password})
}

export function merchantLogin(email: string, password: string) {
  return axios.post(MERCHANT_LOGIN_URL, {email, password})
}

export function merchantRegister(data: {
  display_name: string
  email: string
  password: string
  address: string
  category: string
  contact_person: string
  contact_number: string
  telephone_number: string
  identification_number: string
  changepassword: string
  accept_terms: boolean
}) {
  return axios.post<MerchantResult>(MERCHANT_REGISTER_URL, data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function adminRequestPassword(email: string) {
  return axios.post<{result: string}>(ADMIN_REQUEST_PASSWORD_URL, {email})
}

export function adminResetPassword(data: {password: string; token: string | null}) {
  return axios.post<ResultModel>(`${ADMIN_REQUEST_PASSWORD_URL}/verify`, data)
}

export function merchantRequestPassword(email: string) {
  return axios.post<{result: string}>(MERCHANT_REQUEST_PASSWORD_URL, {email})
}

export function merchantResetPassword(data: {password: string; token: string | null}) {
  return axios.post<ResultModel>(`${MERCHANT_REQUEST_PASSWORD_URL}/verify`, data)
}

export function getUserByToken(state: any) {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  const accessToken: string = state?.auth?.accessToken ? state.auth.accessToken : state
  const isAdmin = accessToken && parseJwt(accessToken).user.role === 'admin'
  if (isAdmin) {
    return axios.get<AdminModel>(GET_ADMIN_BY_ACCESSTOKEN_URL)
  } else {
    return axios.get<MerchantModel>(GET_MERCHANT_BY_ACCESSTOKEN_URL)
  }
}

// export function getMerchantByToken() {
//   return axios.get<UserModel>(GET_MERCHANT_BY_ACCESSTOKEN_URL)
// }
