import axios from 'axios'
import {NewsletterListModel, NewsletterModelResult, NewsletterModel} from '../interface/Newsletter'

const API_URL = process.env.REACT_APP_API_URL

const NEWSLETTER_GETALL_URL = `${API_URL}/newsletters`
const NEWSLETTER_REQ_URL = `${API_URL}/newsletter/`

export const getAllNewsletter = async (page: string): Promise<NewsletterListModel> => {
  const res = await axios.get(NEWSLETTER_GETALL_URL + `?page=${page}`)
  console.log(res.data)
  return res.data.result
}

export const getNewsletter = (id: string): Promise<NewsletterModelResult> => {
  return axios.get(NEWSLETTER_REQ_URL + id)
}

export const putNewsletter = (
  id: string,
  data: NewsletterModel
): Promise<NewsletterModelResult> => {
  return axios.put(NEWSLETTER_REQ_URL + id, data)
}

export const deleteNewsletter = (id: string): Promise<NewsletterModelResult> => {
  return axios.delete(NEWSLETTER_REQ_URL + id)
}

export const searchNewsletter = async (name: string): Promise<NewsletterListModel> => {
  const res = await axios.get(NEWSLETTER_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
