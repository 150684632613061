import axios from 'axios'
import {PromoListModel, PromoModelResult, PromoModel} from '../interface/Promo'

const API_URL = process.env.REACT_APP_API_URL

const PROMO_GETALL_URL = `${API_URL}/promos/all`
const PROMO_REQ_URL = `${API_URL}/promo/`

export const getAllPromo = async (page: string): Promise<PromoListModel> => {
  const res = await axios.get(PROMO_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getPromo = (id: string): Promise<PromoModelResult> => {
  return axios.get(PROMO_REQ_URL + id)
}

export const putPromo = (id: string, data: PromoModel): Promise<PromoModelResult> => {
  return axios.put(PROMO_REQ_URL + id, data)
}

export const postPromo = (data: PromoModel): Promise<PromoModelResult> => {
  return axios.post(PROMO_REQ_URL, data)
}

export const deletePromo = (id: string): Promise<PromoModelResult> => {
  return axios.delete(PROMO_REQ_URL + id)
}

export const searchPromo = async (name: string): Promise<PromoListModel> => {
  const res = await axios.get(PROMO_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
