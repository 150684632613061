/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Tabs} from 'antd'
import {Deal} from '../../../../app/pages/dashboard/interface/IDashboardModel'
import DealsTable from './DealsTable'

type Props = {
  className: string
  deals: {new_deals: Deal[]; expiring_deals: Deal[]}
}

const NewAndExpiringDeals: React.FC<Props> = ({className, deals}: Props) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Deal Statistics</span>
          <span className='text-muted mt-1 fw-bold fs-7'>Over 30+ deals recently</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <Tabs onChange={() => {}} type='card'>
          <Tabs.TabPane tab={<span style={{margin: 15}}>New Deals</span>} key='1'>
            {/* begin::Table container */}
            <DealsTable deals={deals.new_deals} />
            {/* end::Table container */}
          </Tabs.TabPane>
          <Tabs.TabPane tab={<span style={{margin: 15}}>Expiring Deals</span>} key='2'>
            <DealsTable deals={deals.expiring_deals} />
          </Tabs.TabPane>
        </Tabs>
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {NewAndExpiringDeals}
