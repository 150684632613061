import {IChangePassword} from './../interface/changepassword'
import axios from 'axios'
import {MerchantModel} from '../../../auth/models/UserModel'
import {
  AddressModal,
  BankAccountModal,
  BusinessInformationModal,
  MerchantAccountResult,
  AccountStatsResult,
} from '../interface/account'

const API_URL = process.env.REACT_APP_API_MERCHANT_URL

const MERCHANT_ACCOUNT_URL = `${API_URL}/account`

export const getAccountStats = async (): Promise<AccountStatsResult> => {
  return axios.get(`${MERCHANT_ACCOUNT_URL}/stats`)
}

export const putMerchantAccountDetails = async (
  data: MerchantModel
): Promise<MerchantAccountResult> => {
  return axios.put(MERCHANT_ACCOUNT_URL, data)
}

export const putMerchantBusinessInformation = async (
  data: BusinessInformationModal
): Promise<MerchantAccountResult> => {
  return axios.put(MERCHANT_ACCOUNT_URL + '/business', data)
}

export const putMerchantBankAccount = async (
  data: BankAccountModal
): Promise<MerchantAccountResult> => {
  return axios.put(MERCHANT_ACCOUNT_URL + '/bank', data)
}

export const putMerchantAddress = async (
  data: AddressModal,
  copy_button: boolean
): Promise<MerchantAccountResult> => {
  if (!copy_button) {
    return axios.put(MERCHANT_ACCOUNT_URL + '/warehouse', data)
  } else {
    return axios.put(MERCHANT_ACCOUNT_URL + '/return', data)
  }
}
export const putChangePassword = async ({
  oldPassword,
  newPassword,
}: IChangePassword): Promise<{data: MerchantAccountResult}> => {
  return axios.put(MERCHANT_ACCOUNT_URL + '/changepassword', {
    old_password: oldPassword,
    new_password: newPassword,
  })
}
