import {FC, useEffect, useState} from 'react'
import {Form, Input, InputNumber, message, Popconfirm, Table, Tag, Typography} from 'antd'
import {SearchOutlined, PercentageOutlined} from '@ant-design/icons'
import {getAllCategory} from '../../category/api/category'
import {CategoryModel} from '../../category/interface/Category'
import {cancel} from 'redux-saga/effects'
import {putCategoryCommission, putCategoryCommissions} from '../api/Commission'
import axios from 'axios'

const Segment: FC = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<CategoryModel[]>([])
  const [form] = Form.useForm()
  const [editingKey, setEditingKey] = useState('')
  const isEditing = (record: CategoryModel) => record._id === editingKey

  const fetchData = () => {
    setLoading(true)
    axios.get(process.env.REACT_APP_API_URL + '/category/').then((e) => {
      setData(e.data.result)
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const edit = (record: Partial<CategoryModel>) => {
    form.setFieldsValue({name: '', age: '', address: '', ...record})
    setEditingKey(record._id as string)
  }
  const cancel = () => {
    setEditingKey('')
  }
  const save = async (record: CategoryModel) => {
    const ids = [record._id]
    const tmp = record as any
    function recursiveAddIds(tmp: any) {
      if (tmp.children) {
        var item = tmp.children
        for (var i = 0; i < item.length; i++) {
          ids.push(item[i]._id)
          recursiveAddIds(item[i])
        }
      }
    }
    recursiveAddIds(tmp)
    const {commission_percent} = form.getFieldsValue()
    putCategoryCommissions(record._id, {commission_percent: commission_percent, ids}).then(() => {
      message.success(`Changes applied. New rate is @ ${commission_percent}%`)
      setEditingKey('')
      fetchData()
    })
  }

  const columns = [
    {
      title: 'Segment',
      dataIndex: 'name',
      key: 'segment',
      editing: false,
      render: (text: string) => text,
    },
    {
      title: 'Commission',
      dataIndex: 'commission_percent',
      key: 'commission',
      width: '20%',
      editing: true,
      render: (text: string) => {
        if (text === undefined) return <Tag color='red'>NOT SET</Tag>
        return <Tag color={+text > 20 ? 'green' : 'volcano'}>{text}%</Tag>
      },
    },
    {
      title: 'Action',
      key: 'action',
      editing: false,
      width: '20%',
      render: (_: any, record: CategoryModel) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Popconfirm title='This will change existing rate?' onConfirm={() => save(record)}>
              <Typography.Link style={{marginRight: 8}}>Save</Typography.Link>
            </Popconfirm>
            <a onClick={cancel}>Cancel</a>
          </span>
        ) : (
          <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
            Edit
          </Typography.Link>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col: any) => {
    if (!col.editing) {
      return col
    }
    return {
      ...col,
      onCell: (record: CategoryModel) => ({
        record,
        inputType: col.dataIndex === 'commission' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean
    dataIndex: string
    title: any
    inputType: 'number' | 'text'
    record: CategoryModel
    index: number
    children: React.ReactNode
  }

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = (
      <InputNumber
        min={1}
        max={99}
        value={100}
        style={{width: 130}}
        placeholder='Enter Rate %'
        addonAfter={<PercentageOutlined />}
      />
    )

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{margin: 0}}
            rules={[
              {
                required: true,
                message: 'Required!',
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  return (
    <div className='card shadow-sm'>
      <div className='card-header'>
        <h3 className='card-title'>Commission Management</h3>
        {/* <div style={{alignItems: 'center', display: 'flex'}}>
          <Input
            bordered={false}
            placeholder='Search a category'
            prefix={<SearchOutlined />}
            style={{width: 300, paddingLeft: 20, borderLeft: '1px solid #ccc'}}
            size='small'
            allowClear
            onPressEnter={(e) => console.log(e.target)}
          />
        </div> */}
      </div>
      <div className='card-body'>
        <Form component={false} form={form}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={loading}
            dataSource={data.map((e: any) => ({...e, key: e._id}))}
            columns={mergedColumns}
          />
        </Form>
      </div>
    </div>
  )
}

export default Segment
