import React, {useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {
  businessInformationSchema,
  BusinessInformationModal,
  MerchantBusinessInformationProps,
} from '../../interface/account'
import {putMerchantBusinessInformation} from '../../api/account'
import {toast} from 'react-toastify'
import ImageUpload from '../../../../../components/MultiImageUpload'
import {putMerchantBusinessDetails} from '../../../../user-management/api/merchant'
import {Button, Image} from 'antd'

export interface IImageLoading {
  business_document: boolean
  pan_document: boolean
}

const BusinessInformation: React.FC<MerchantBusinessInformationProps> = ({
  user,
  isAdmin,
  userId,
}) => {
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [panImageUrl, setPanImageUrl] = useState('')
  const [imageLoading, setImageLoading] = useState<IImageLoading>({
    business_document: false,
    pan_document: false,
  })
  const [visible, setVisible] = useState(false)

  const formik = useFormik<BusinessInformationModal>({
    initialValues: user,
    validationSchema: businessInformationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      setLoading(true)
      if (imageUrl) {
        values = {...values, business_document: imageUrl}
      }
      if (panImageUrl) {
        values = {...values, pan_document: panImageUrl}
      }
      if (!isAdmin) {
        putMerchantBusinessInformation(values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      } else {
        putMerchantBusinessDetails(userId, values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      }
    },
  })

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading({...imageLoading, business_document: true})
      return
    }
    if (info.file.status === 'done') {
      setImageUrl(info.file.response.url)
      setImageLoading({...imageLoading, business_document: false})
    }
  }
  function changePANAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading({...imageLoading, pan_document: true})
      return
    }
    if (info.file.status === 'done') {
      setPanImageUrl(info.file.response.url)
      setImageLoading({...imageLoading, pan_document: false})
    }
  }

  return (
    <form onSubmit={formik.handleSubmit} className='form'>
      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Business Name</span>
          </label>

          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('legal_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.legal_name && formik.errors.legal_name,
                },
                {
                  'is-valid': formik.touched.legal_name && !formik.errors.legal_name,
                }
              )}
            />

            {formik.touched.legal_name && formik.errors.legal_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.legal_name}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Address</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('address')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.address && formik.errors.address,
                },
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.address}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>City/Town</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('city')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.city && formik.errors.city,
                },
                {
                  'is-valid': formik.touched.city && !formik.errors.city,
                }
              )}
            />
            {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.city}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Country</span>
          </label>
          <div className='col-lg-8'>
            <select
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('country')}
            >
              <option value=''>Select a country</option>
              <option value='nepal'>Nepal</option>
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.country}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Person In Charge</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('person_in_charge')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.person_in_charge && formik.errors.person_in_charge,
                },
                {
                  'is-valid': formik.touched.person_in_charge && !formik.errors.person_in_charge,
                }
              )}
            />
            {formik.touched.person_in_charge && formik.errors.person_in_charge && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.person_in_charge}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Business Registration No(PAN/VAT)</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('registration_number')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid':
                    formik.touched.registration_number && formik.errors.registration_number,
                },
                {
                  'is-valid':
                    formik.touched.registration_number && !formik.errors.registration_number,
                }
              )}
            />
            {formik.touched.registration_number && formik.errors.registration_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.registration_number}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Upload Business Document</span>
          </label>
          <div className='col-lg-8'>
            <ImageUpload
              image={formik.values.business_document}
              imageLoading={imageLoading.business_document}
              imageUrl={imageUrl}
              changeAvatar={changeAvatar}
            />
            {formik.errors.business_document && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.business_document}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Upload PAN Document</span>
          </label>
          <div className='col-lg-8'>
            <ImageUpload
              image={formik.values.pan_document}
              imageLoading={imageLoading.pan_document}
              imageUrl={panImageUrl}
              changeAvatar={changePANAvatar}
            />
            {formik.errors.pan_document && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.pan_document}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Province</span>
          </label>
          <div className='col-lg-8'>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              {...formik.getFieldProps('zone')}
            />
            {formik.touched.zone && formik.errors.zone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.zone}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Area</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('area')}
            />
            {formik.touched.area && formik.errors.area && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.area}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>District</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('region')}
            />
            {formik.touched.region && formik.errors.region && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.region}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Person In Charge Phone Number</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='number'
              {...formik.getFieldProps('person_in_charge_phone_number')}
              className='form-control form-control-lg form-control-solid'
            />
            {formik.touched.person_in_charge_phone_number &&
              formik.errors.person_in_charge_phone_number && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.person_in_charge_phone_number}</div>
                </div>
              )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Person In Charge Email</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('person_in_charge_email')}
              className='form-control form-control-lg form-control-solid'
            />
            {formik.touched.person_in_charge_email && formik.errors.person_in_charge_email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.person_in_charge_email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='cursor-pointer'>
          <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default BusinessInformation
