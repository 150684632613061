import {MerchantModel} from '../../../auth/models/UserModel'
import * as Yup from 'yup'

interface Merchant {
  isAdmin: boolean
  userId?: string
}
export interface MerchantAccountProps extends Merchant {
  user: MerchantModel
}
export interface MerchantBusinessInformationProps extends Merchant {
  user: BusinessInformationModal
}

export interface MerchantBankAccountProps extends Merchant {
  user: BankAccountModal
}

export interface MerchantAddressModal extends Merchant {
  user: AddressModal
  warehouse_address?: AddressModal
  copy_button: boolean
}
export interface AddressModal {
  full_name: string
  address: string
  phone_number: string
  email: string
  city: string
  country: string
  zone: string
  region: string
  area: string
}

export interface BusinessInformationModal {
  legal_name: string
  address: string
  city: string
  country: string
  person_in_charge: string
  registration_number: string
  business_document: string
  pan_document: string
  zone?: string
  area?: string
  region?: string
  person_in_charge_phone_number?: Number | undefined
  person_in_charge_email?: string
}

export interface BankAccountModal {
  account_title: string
  account_number: string
  bank_name: string
  branch_name: string
  tax_category: string
  cheque_image: string
}

export interface SellerDetails {
  full_name?: string
  email?: string
  phone_number?: string
  display_name?: string
  holiday_mode?: boolean
  holiday_mode_from?: Date
  holiday_mode_to?: Date
  description?: string
  image?: string
  role?: string
  settlement_days?: string
  category?: any
}

export interface MerchantAccountResult {
  error: boolean | null
  result: string
}

export interface AccountStats {
  total_order: number
  total_sales: number
}

export interface AccountStatsResult {
  data: {
    error: boolean
    result: AccountStats
  }
}

export const profileDetailsSchema = Yup.object().shape({
  full_name: Yup.string().required('Full name is required'),
  holiday_mode: Yup.boolean(),
  settlement_days: Yup.number(),
})

export const profileDetailsValues: MerchantModel = {
  full_name: '',
  email: '',
  phone_number: '',
  display_name: '',
  holiday_mode: false,
  image: '',
}

export const businessInformationSchema = Yup.object().shape({
  legal_name: Yup.string().required('Legal name is required'),
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  person_in_charge: Yup.string().required('Person in charge is required'),
  registration_number: Yup.string().required('Registration Number is required'),
  business_document: Yup.string().required('Business Document is required'),
  pan_document: Yup.string().required('PAN Document is required'),
})

export const businessInformationValues: BusinessInformationModal = {
  legal_name: '',
  address: '',
  city: '',
  country: '',
  person_in_charge: '',
  registration_number: '',
  business_document: '',
  pan_document: '',
  zone: '',
  area: '',
  region: '',
  person_in_charge_email: '',
}

export const bankAccountSchema = Yup.object().shape({
  account_title: Yup.string().required('Account title is required'),
  account_number: Yup.string().required('Account number is required'),
  // bank_name: Yup.string().required('Bank Name is required'),
  branch_name: Yup.string().required('Branch Name is required'),
  tax_category: Yup.string().required('Tax Category is required'),
})

export const bankAccountValues: BankAccountModal = {
  account_title: '',
  account_number: '',
  bank_name: '',
  branch_name: '',
  tax_category: '',
  cheque_image: '',
}

export const addressSchema = Yup.object().shape({
  full_name: Yup.string().required('Full name is required'),
  address: Yup.string().required('Address is required'),
  phone_number: Yup.string()
    .max(10, 'Maximum 10 digits')
    .min(10, 'Minimum 10 digits')
    .required('Phone number is required'),
  email: Yup.string().email().required('Email is required'),
  city: Yup.string().required('City/Town is required'),
  country: Yup.string().required('Country is required'),
  zone: Yup.string().required('Zone is required'),
  region: Yup.string().required('Region is required'),
  area: Yup.string().required('Area is required'),
})

export const addressValues: AddressModal = {
  full_name: '',
  address: '',
  phone_number: '',
  email: '',
  city: '',
  country: '',
  zone: '',
  region: '',
  area: '',
}

export const accountStatsValue: AccountStats = {
  total_order: 0,
  total_sales: 0,
}
