import axios from 'axios'
import {OrderListModel} from '../../../order-management/interface/Order'
import {IDashboardModel} from '../interface/IDashboardModel'
import {IFilterParams} from '../interface/IDashboardModel'

const API_URL = process.env.REACT_APP_API_MERCHANT_URL

const DASHBORD_URL = `${API_URL}/dashboard`

export const getDashboard = async (): Promise<IDashboardModel> => {
  const res = await axios.get(DASHBORD_URL)
  return res.data.result
}

export const getDashboardOrder = async (filterParams: IFilterParams): Promise<OrderListModel> => {
  const {filterBy = 'all', page = '1'} = filterParams
  const res = await axios.get(`${API_URL}/orders/${filterBy}?page=${page}`)
  return res.data.result
}
