import axios from 'axios'
import { CategoryResult, CategoryModel} from '../../category/interface/Category'

const API_URL = process.env.REACT_APP_API_URL

const CATEGORY_REQ_URL = `${API_URL}/category/`
const BULK_ADD = `${API_URL}/categorys/`

export const putCategoryCommission = (id: string, data: {commission_percent:number}): Promise<CategoryResult> => {
  return axios.put(CATEGORY_REQ_URL + id, data)
}
export const putCategoryCommissions = (id: string, data: {commission_percent:number, ids:any}): Promise<CategoryResult> => {
  return axios.put(BULK_ADD, data)
}