import axios from 'axios'
import {OrderListModel, OrderModelResult, OrderModel, IFilterParams} from '../interface/Order'

const API_URL = process.env.REACT_APP_API_URL

const ORDER_GETALL_URL = `${API_URL}/orders/`
const ORDER_REQ_URL = `${API_URL}/order/`

export const getAllOrder = async (filterParams: IFilterParams): Promise<OrderListModel> => {
  const {filterBy, page, search = '', merchantId, date} = filterParams
  const res = await axios.get(
    ORDER_GETALL_URL +
      `${filterBy}?page=${page}&merchant_id=${merchantId}&date=${date}&search_text=${search}`
  )
  return res.data.result
}

export const getOrder = (id: string): Promise<OrderModelResult> => {
  return axios.get(ORDER_REQ_URL + id)
}

export const putOrder = (
  id: string,
  item_id: string,
  status: string
): Promise<OrderModelResult> => {
  return axios.put(`${ORDER_REQ_URL}${id}?item_id=${item_id}`, {status: status})
}

export const postOrder = (data: OrderModel): Promise<OrderModelResult> => {
  return axios.post(ORDER_REQ_URL, data)
}

export const deleteOrder = (id: string): Promise<OrderModelResult> => {
  return axios.delete(ORDER_REQ_URL + id)
}

export const searchOrder = async (name: string): Promise<OrderListModel> => {
  const res = await axios.get(ORDER_GETALL_URL + `all?search_text=${name}`)
  return res.data.result
}
