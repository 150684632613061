/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getProduct} from '../api/product'
import {Link, useParams, useHistory} from 'react-router-dom'
import {ProductModel} from '../../../product/interface/Product'
import {capitalize} from '../../../../../utils/capitalize'
import {parseDate} from '../../../../../utils/dateAndTime'
import ViewItem from '../../../../components/common/ViewItem'
import Variation from '../../../../components/common/variation/Variation'

export interface IPageParams {
  id: string
}
const ViewProduct: FC = () => {
  const {id} = useParams<IPageParams>()
  const [product, setProduct] = useState<ProductModel>()
  let history = useHistory()

  useEffect(() => {
    getProduct(id, 'view')
      .then(({data}) => {
        setProduct(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  if (product) {
    var {_id, name, actual_price, image, stock, description, categoryId, variation, createdAt} =
      product
  }
  return (
    <>
      {/*begin::Card*/}
      {product && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::User Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={product.image || '/media/icons/duotune/general/box2.svg'} alt='avatar' />
              </div>
              {/*end::Avatar*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {capitalize(product?.name)}
                </div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::User Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link to={`/product/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div className='collapse show'>
              <div className='pb-5 fs-6'>
                <ViewItem name={'id'} value={_id} />
                <ViewItem name={'name'} value={name} />
                <ViewItem name={'stock'} value={stock} />
                <ViewItem name={'original price'} value={actual_price} />
                <ViewItem name={'description'} value={description} />
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>CATEGORY NAME</div>
                <div className='text-gray-600'>{capitalize(categoryId.name)}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                {variation && variation.length > 0 && <Variation variation={variation} />}
                {/*end::Details item*/}
                {/*begin::Details item*/}
                {product.image && (
                  <>
                    {' '}
                    <div className='fw-bolder mt-5'>IMAGE</div>
                    <img src={image} alt='product' height='100px' width='100px' />
                  </>
                )}
                {/*end::Details item*/}
                {/*begin::Details item*/}
                {/* <div className='fw-bolder mt-5'>Created Date</div>
                <div className='text-gray-600'>{parseDate(createdAt)}</div> */}
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewProduct
