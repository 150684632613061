/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {FaqModel} from '../interface/IFaq'
import {Form, InputNumber, Switch} from 'antd'
import {getFaq, postFaq, putFaq} from '../api/faq'
import {useParams} from 'react-router-dom'
import Button from '../../../components/common/Button'
import handleError from '../../../components/handleError'

interface IPageParams {
  id: string
}
const FAQ_URL = '/faq/1'

const CreateEditFaq: FC = () => {
  const history = useHistory()
  const [form] = Form.useForm()

  const {id} = useParams<IPageParams>()
  const isCreateMode = !id

  const [loading, setLoading] = useState<boolean>(false)

  function onFinish(values: FaqModel) {
    setLoading(true)
    if (!isCreateMode) {
      putFaq(id, values)
        .then(() => {
          setLoading(false)
          history.push('/faq/')
        })
        .catch((error) => {
          setLoading(false)
          handleError(form, error.response.data.result)
        })
    } else {
      postFaq(values)
        .then(() => {
          setLoading(false)
          history.push('/faq/')
        })
        .catch((error) => {
          setLoading(false)
          handleError(form, error.response.data.result)
        })
    }
  }

  useEffect(() => {
    if (!isCreateMode) {
      getFaq(id)
        .then(({data}) => {
          const result = data.result
          console.log(result)
          form.setFieldsValue({
            ...result,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [])

  return (
    <>
      <div className='card shadow-sm'>
        <div className='card-header'>
          <h3 className='card-title'>Faq</h3>
        </div>
        <Form form={form} initialValues={{featured: true}} onFinish={onFinish}>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <label className='required form-label'>Question</label>
                <Form.Item
                  name='question'
                  rules={[{required: true, message: 'An question is required!'}]}
                >
                  <input
                    className='form-control form-control-solid'
                    placeholder='Help with Signing In'
                  />
                </Form.Item>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label className='required form-label'>Answer</label>
                <Form.Item
                  name='answer'
                  rules={[{required: true, message: 'An answer is required!'}]}
                >
                  <textarea
                    className='form-control form-control-solid'
                    rows={10}
                    placeholder='If you forgot your password—or if it isn’t working—you can reset your password using the username address associated with your account.'
                  ></textarea>
                </Form.Item>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label className='form-label'>Sorting Order</label>
                <Form.Item name='sorting_order'>
                  <InputNumber
                    size='small'
                    className='form-control form-control-solid mb-8'
                    placeholder='1'
                  />
                </Form.Item>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <label className='form-label'>Is Active</label>
                <Form.Item name='is_active' valuePropName='checked'>
                  <Switch checkedChildren={<span>No</span>} unCheckedChildren={<span>Yes</span>} />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className='card-footer'>
            <button
              type='button'
              className='btn btn-danger me-4'
              onClick={() => history.push(FAQ_URL)}
            >
              Cancel
            </button>
            <Button loading={loading} name={!isCreateMode ? 'Update' : 'Create'} />
          </div>
        </Form>
      </div>
    </>
  )
}

export default CreateEditFaq
