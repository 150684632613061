import { Form, Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

export default function ImageUpload(props: {
  image: any
  // imageUrl?: string
  imageLoading: boolean
  changeAvatar: any
}) {
  // const { image, imageUrl, imageLoading, changeAvatar } = props
  const { image, imageLoading, changeAvatar } = props
  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  return (

    <Form.Item name='image'>
      <Upload
        name='file'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={false}
        action={process.env.REACT_APP_IMAGE_UPLOAD as string}
        onChange={changeAvatar}
        beforeUpload={beforeUpload}
      >
        {/* {image || imageUrl ? (
        <img src={imageUrl ? imageUrl : image} alt='avatar' style={{ width: '100%' }} />
      ) : (
        uploadButton
      )} */}

        {image ? (
          <img src={image} alt='avatar' style={{ width: '100%' }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </Form.Item>
  )
}