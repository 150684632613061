// Jesus File
//TODO: refactor
import {Document, Page, View, Text, StyleSheet} from '@react-pdf/renderer'
import {parseValueToTwoDecimal} from '../../../../../../utils/parseNumber'
import {ResultInterface} from '../../interface/settlement'

interface Props {
  statement: ResultInterface | undefined
}

const PDFStatement = ({statement}: Props) => {
  return (
    <Document>
      <Page style={styles.body}>
        <View style={{...styles.row, marginBottom: '20px', borderBottom: '1px solid gray'}}>
          <Text style={styles.title}>Opening Balance</Text>
          <Text style={styles.description}>Unpaid balance since the last settlement</Text>
          <Text style={styles.number}>Rs. 100</Text>
        </View>
        <Text style={styles.maintitle}>Orders</Text>
        <Text style={styles.subtitle}>Items</Text>
        {/* <View style={styles.row}>
          <Text style={styles.item}>Random Lipstick Base II</Text>
          <Text style={styles.number}>Rs. 900</Text>
        </View> */}
        {statement &&
          statement.order.items.map((e, idx) => (
            <View key={idx} style={styles.row}>
              <Text style={styles.item}>{e.title}</Text>
              <Text style={styles.number}>Rs. {e.price}</Text>
            </View>
          ))}
        <Text style={styles.subtitle}>Claims</Text>
        <View style={styles.row}>
          <Text style={styles.item}>No claims at all</Text>
          <Text style={styles.number}>Rs. 0</Text>
        </View>
        <Text style={styles.subtitle}>Credit</Text>
        <View style={styles.row}>
          <Text style={styles.item}>No credits</Text>
          <Text style={styles.number}>Rs. 0</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subtitle}>GoDeals Fees</Text>
          {/* <Text style={styles.item}>Charges on ABC products</Text> */}
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.order.fee)}</Text>
        </View>
        <Text style={styles.subtitle}>Penalty</Text>
        <View style={styles.row}>
          <Text style={styles.item}>No penalty charged</Text>
          <Text style={styles.number}>Rs. 0</Text>
        </View>
        <Text style={styles.subtitle}>VAT</Text>
        <View style={styles.row}>
          <Text style={styles.item}>13% of sales</Text>
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.order.vat)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subtitle}>Subtotal</Text>
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.order.subtotal)}</Text>
        </View>
        <Text style={{border: '1px solid gray', margin: '10px', marginBottom: '20px'}}></Text>
        <Text style={styles.maintitle}>Refunds</Text>
        <Text style={styles.subtitle}>Items</Text>
        {/* <View style={styles.row}>
          <Text style={styles.item}>D-Tan Cleanser + Peel Up Mask</Text>
          <Text style={styles.number}>- (Rs. 900)</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.item}>D-Tan Cleanser + Peel Up Mask</Text>
          <Text style={styles.number}>- (Rs. 1200)</Text>
        </View> */}
        {statement &&
          statement.refund.items.map((e, idx) => (
            <View key={idx} style={styles.row}>
              <Text style={styles.item}>{e.title}</Text>
              <Text style={styles.number}>Rs. {parseValueToTwoDecimal(e.price)}</Text>
            </View>
          ))}
        <Text style={styles.subtitle}>Claims</Text>
        <View style={styles.row}>
          <Text style={styles.item}>No claims at all</Text>
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.refund.claims)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subtitle}>GoDeals Fees</Text>
          {/* <Text style={styles.item}>Charges on ABC products</Text> */}
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.refund.fee)}</Text>
        </View>
        <Text style={styles.subtitle}>Penalty</Text>
        <View style={styles.row}>
          <Text style={styles.item}>No penalty charged</Text>
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.refund.penalty)}</Text>
        </View>
        <Text style={styles.subtitle}>VAT</Text>
        <View style={styles.row}>
          <Text style={styles.item}>13% of sales</Text>
          <Text style={styles.number}>Rs. {parseValueToTwoDecimal(statement?.refund.vat)}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.subtitle}>Subtotal</Text>
          <Text style={styles.number}>
            Rs. {parseValueToTwoDecimal(statement?.refund.subtotal)}
          </Text>
        </View>
        <View style={{...styles.row, paddingTop: '20px', borderTop: '1px solid gray'}}>
          <Text style={styles.title}>Closing Balance</Text>
          <Text style={styles.description}>Total Amount to be paid</Text>
          <Text style={styles.number}>
            Rs. {parseValueToTwoDecimal(statement?.closing_balance)}
          </Text>
        </View>
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 50,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '5px',
    alignItems: 'center',
  },
  description: {
    fontSize: 12,
    color: 'gray',
  },
  title: {
    fontSize: 12,
  },
  subtitle: {
    marginBottom: '10px',
    marginTop: '20px',
    fontSize: 14,
  },
  maintitle: {
    fontSize: 16,
  },
  number: {
    fontSize: 12,
    color: 'gray',
  },
  item: {
    fontSize: 12,
    color: 'gray',
  },
})

export default PDFStatement
