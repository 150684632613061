import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import ViewOrder from './order/ViewOrder'
import ListOrder from './order/ListOrder'

const OrderPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/order/view/:id'>
          <PageTitle breadcrumbs={[]}>Order View</PageTitle>
          <ViewOrder />
        </Route>
        <Route path='/order/:pageNo'>
          <PageTitle breadcrumbs={[]}>Order List</PageTitle>
          <ListOrder />
        </Route>
        <Redirect from='/order' exact={true} to='/order/1' />
        <Redirect to='/order/1' />
      </Switch>
    </>
  )
}

export default OrderPage
