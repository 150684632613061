import {IFilterParams} from '../interface/IFilterParams'
import axios from 'axios'
import {DealListModel, DealModel, DealResult} from '../../../../components/deal/InterfaceDeal'
import {ProductFilterList, ResultModel} from '../../../../interface/Result'

const API_URL = process.env.REACT_APP_API_MERCHANT_URL

const MERCHANT_ALLDEAL_URL = `${API_URL}/deals/`
const MERCHANT_DEAL_URL = `${API_URL}/deal/`

export const getAllDeal = async (filterParams: IFilterParams): Promise<DealListModel> => {
  const {page, filterBy, date, search, productId = ''} = filterParams
  const res = await axios.get(
    MERCHANT_ALLDEAL_URL +
      `${filterBy}?page=${page}&search_text=${search}&date=${date}&product_id=${productId}`
  )
  return res.data.result
}

export const getDeal = (id: string, mode: string): Promise<DealResult> => {
  return axios.get(`${MERCHANT_DEAL_URL}${id}?show=${mode}`)
}

export const putDeal = (id: string, data: DealModel): Promise<DealResult> => {
  return axios.put(MERCHANT_DEAL_URL + id, data)
}

export const putDealStockIn = (id: string, data: DealModel): Promise<{data: ResultModel}> => {
  return axios.put(`${MERCHANT_DEAL_URL}stockin/${id}`, data)
}

export const putDealStockStatus = (data: {
  deal_id: string
  deal_index: number
  status: boolean
}): Promise<{data: ResultModel}> => {
  return axios.patch(`${MERCHANT_DEAL_URL}changestockstatus`, data)
}

export const patchDeal = (id: string, status: Boolean): Promise<DealResult> => {
  return axios.patch(MERCHANT_DEAL_URL + id, {status})
}

export const postDeal = (data: DealModel): Promise<DealResult> => {
  return axios.post(MERCHANT_DEAL_URL, data)
}

export const deleteDeal = (id: string): Promise<DealResult> => {
  return axios.delete(MERCHANT_DEAL_URL + id)
}

export const getProductsForFilter = (): Promise<ProductFilterList> => {
  return axios.get(`${API_URL}/products-for-filter`)
}
