import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {FC} from 'react'
import Categories from './components/Categories'

const CommissionPage: FC = () => {
  return (
    <>
      <Switch>
        {/* <Route path='/commission/segment'>
          <PageTitle breadcrumbs={[]}>Manage Commission Rates</PageTitle>
          <Segment />
        </Route> */}
        <Route path='/commission'>
          <PageTitle breadcrumbs={[]}>Manage Commission Rates</PageTitle>
          <Categories />
        </Route>
        <Redirect to='/commission' />
      </Switch>
    </>
  )
}

export default CommissionPage
