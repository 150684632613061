import React, {FC, useContext, useEffect, useState} from 'react'
import {Form, Select, Card, Button, InputNumber, Input} from 'antd'
import ProductContext from '../../contexts/StockChangeProductContext'
import CreateEditProductContext from '../../contexts/CreateEditProductContext'

interface Props {
  items: any
  index: number
  attributes: any
  remove: any
  form: any
  parentIndex?: number
  label: string[]
}

const Attribute: FC<Props> = ({items, index, attributes, remove, form, parentIndex, label}) => {
  const {key, name, fieldKey, ...restField} = items
  const {disabled} = useContext(ProductContext)
  const {handleStockChange} = useContext(CreateEditProductContext)
  const [stock, setStock] = useState<number>(0)
  console.log(label)
  const newLabel = label.filter((e) => e !== 'stock').filter((e) => e !== 'sku')

  useEffect(() => {
    const variation = form.getFieldValue('variation')
    if (variation && variation.length > 0) {
      const sum = (accumulator: number, curr: number) => accumulator + curr
      const stock = variation
        .map((e: any) => {
          if (e && e.stock) {
            return e.stock
          }
        })
        .reduce(sum)
      handleStockChange(stock)
    }
  }, [stock])

  useEffect(() => {
    if (disabled) {
      const fields = form.getFieldsValue()
      form.setFieldsValue({
        ...fields,
        stock: 0,
        variation: fields.variation.map(({stock, ...keepProperty}: any) => keepProperty),
      })
    }
  }, [])

  return (
    <Card
      key={key}
      type='inner'
      title={`Options ${index + 1}`}
      style={{marginBottom: 16}}
      extra={
        <Button type='text' danger onClick={() => remove(name)}>
          Remove
        </Button>
      }
    >
      {/* {newLabel.map((item, idx) => {
        return (
          <Form.Item
            {...restField}
            name={[name, item]}
            fieldKey={[fieldKey, item]}
            rules={[{required: true, message: 'This field is required'}]}
            label={item}
            key={item}
          >
            <Select
              showSearch
              placeholder='Select attribute type'
              optionFilterProp='children'
              onSelect={(value) => {}}
              disabled={disabled}
            ></Select>
          </Form.Item>
        )
      })} */}
      <Form.Item
        {...restField}
        name={[name, 'sku']}
        rules={[{required: true, message: 'This field is required'}]}
        label='SKU'
      >
        <Input placeholder='e.g. SWHITE10' style={{width: 120}} disabled={disabled} />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'stock']}
        rules={[
          {required: true, message: 'This field is required'},

          {
            type: 'integer',
            min: 0,
            message: 'Stock should be positive',
          },
        ]}
        label='Stock Qty'
        value={stock}
        onChange={(event: React.ChangeEvent<any>) => {
          setStock(event?.target.value)
        }}
      >
        <InputNumber placeholder='100' />
      </Form.Item>
    </Card>
  )
}

export default Attribute
