/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {capitalize} from '../../../../../utils/capitalize'
import {getDeal} from '../api/deal'
import ViewContent from '../../../../components/deal/ViewContent'
import {DealModel} from '../../../../components/deal/InterfaceDeal'

export interface IPageParams {
  id: string
}

const ViewDeal: FC = () => {
  const {id} = useParams<IPageParams>()
  const [deal, setDeal] = useState<DealModel>()
  let history = useHistory()

  useEffect(() => {
    getDeal(id, 'view')
      .then(({data}) => {
        setDeal(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])

  return (
    <>
      {/*begin::Card*/}
      {deal && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::User Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={deal.images[0]} alt='avatar' />
              </div>
              {/*end::Avatar*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {capitalize(deal?.title)}
                </div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::User Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <ViewContent deal={deal} />
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewDeal
