import {FC} from 'react'
import {ErrorMessage} from './handleErrorMessage'

interface IMessage {
  isError: boolean
  message: ErrorMessage[]
}

const Message: FC<IMessage> = ({isError, message}) => {
  return (
    <>
      {message && (
        <div className={`mb-lg-15 alert alert-${isError ? 'danger' : 'success'}`}>
          <div className='alert-text font-weight-bold'>
            {message.map((e, index) => (
              <div key={index}>{e}</div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
export {Message}
