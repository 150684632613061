import axios from 'axios'
import {
  CategoryListModel,
  CategoryResult,
  CategoryModel,
  IFilterParams,
} from '../interface/Category'

const API_URL = process.env.REACT_APP_API_URL

const CATEGORY_GETALL_URL = `${API_URL}/categorys/`
const CATEGORY_REQ_URL = `${API_URL}/category/`

export const getAllCategory = async (
  filterParams: IFilterParams,
  LEVEL: Number
): Promise<CategoryListModel> => {
  const {limit, search = '', page = '', parentCategoryId = ''} = filterParams
  let url =
    CATEGORY_GETALL_URL +
    LEVEL +
    `?page=${page}&parent_category=${parentCategoryId}&limit=${limit}&search_text=${search}`
  const res = await axios.get(url)
  return res.data.result
}

export const getCategory = (id: string): Promise<CategoryResult> => {
  return axios.get(CATEGORY_REQ_URL + id)
}

export const getSubCategory = (level: number, id: string): Promise<any> => {
  return axios.get(CATEGORY_REQ_URL + level + '?parent_category=' + id)
}

export const putCategory = (id: string, data: CategoryModel): Promise<CategoryResult> => {
  return axios.put(CATEGORY_REQ_URL + id, data)
}

export const postCategory = (data: CategoryModel): Promise<CategoryResult> => {
  return axios.post(CATEGORY_REQ_URL, data)
}

export const deleteCategory = (id: string): Promise<CategoryResult> => {
  return axios.delete(CATEGORY_REQ_URL + id)
}

export const searchCategory = async (
  name: string,
  LEVEL: Number,
  parent_category: string = ''
): Promise<CategoryListModel> => {
  let url = CATEGORY_GETALL_URL + LEVEL + `?search_text=${name}`
  if (parent_category) {
    url += `&parent_category=${parent_category}`
  }
  const res = await axios.get(url)
  return res.data.result
}
