/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'

import {deleteMerchant, getAllMerchant} from '../api/merchant'
import {MerchantListModel} from '../../auth/models/UserListModel'
import {Link, useParams} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import Pagination from '../../../components/Pagination'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Col, DatePicker, Row} from 'antd'
import {RangeValue} from 'rc-picker/lib/interface'
import {Moment} from 'moment'
import {FilterParams} from '../interface/IMerchant'
import MultilineFilter from './filter/Filter'
import FilterByDropdown from './filter/FilterByDropdown'
import NoData from '../../../components/common/NoData'
import {handleErrorMessage} from '../../../components/handleErrorMessage'

const {RangePicker} = DatePicker

export interface IPageParams {
  pageNo: string
}

const filterValues = {
  page: '1',
  date: '',
  is_verified: '',
  province: '',
  area: '',
  district: '',
  warehouse_address: '',
  return_address: '',
  search: '',
}

const mapFilter = [
  {name: 'province', placeholder: 'Search Province'},
  {name: 'district', placeholder: 'Search District'},
  {name: 'warehouse_address', placeholder: 'Search WarehouseAddress'},
  {name: 'return_address', placeholder: 'Search ReturnAddress'},
]

const ListMerchant: FC = () => {
  const [userList, setUserList] = useState<MerchantListModel>()
  // const [page, setPage] = useState<string>('1')
  const {pageNo} = useParams<IPageParams>()
  const [id, setId] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [filterParams, setFilterParams] = useState<FilterParams>(filterValues)

  useEffect(() => {
    getMerchants(filterParams)
  }, [filterParams])

  function getMerchants(filterParams: FilterParams) {
    getAllMerchant(filterParams).then((data) => {
      setLoading(false)
      setUserList(data)
    })
  }
  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteMerchant(id)
      .then((data) => {
        toast.success('Success!')
        getMerchants(filterParams)
      })
      .catch((error) => {
        const message = handleErrorMessage(error.response.data.result)
        toast.error(message[0])
      })
    setId('')
  }

  const handleDatePickerChange = (date: RangeValue<Moment>, dateString: [string, string]) => {
    setFilterParams({...filterParams, date: dateString})
  }

  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }

  useEffect(() => {
    setFilterParams({...filterParams, page: pageNo})
  }, [pageNo])

  return (
    <>
      <PageTitle breadcrumbs={[]}>User Management</PageTitle>

      <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
        {/*begin::Toolbar*/}
        <div className='toolbar' id='kt_toolbar'>
          {/*begin::Container*/}
          <div id='kt_toolbar_container' className='container-fluid d-flex flex-stack'>
            <div
              data-kt-swapper='true'
              data-kt-swapper-mode='prepend'
              data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
              className='page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0'
            >
              {/*begin::Title*/}
              <h1 className='d-flex align-items-center text-dark fw-bolder fs-3 my-1'>
                Merchant List
              </h1>
              {/*end::Title*/}
            </div>
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Toolbar*/}
        {/*begin::Post*/}
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          {/*begin::Container*/}
          <div className='container-xxl'>
            {/*begin::Card*/}
            <div className='card shadow-sm mb-8'>
              <div className='card-header'>
                <h3 className='card-title'>Filter Options</h3>

                <div className='mt-3 justify-content-end'>
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={() => {
                      setFilterParams(filterValues)
                    }}
                  >
                    Reset
                  </button>
                </div>
              </div>
              <div className='card-body'>
                {/*begin::Search*/}
                <Row gutter={[16, 24]} className='mb-4'>
                  <Col className='gutter-row'>
                    <input
                      type='text'
                      className='form-control form-control-solid w-250px'
                      placeholder='Search merchant'
                      onChange={handleFilter}
                      value={filterParams.search}
                      name='search'
                    />
                  </Col>
                  <Col className='gutter-row'>
                    <RangePicker
                      className='form-control form-control-solid me-3'
                      style={{display: 'inline-flex'}}
                      onChange={(date, dateString) => handleDatePickerChange(date, dateString)}
                    />
                  </Col>
                  <Col className='gutter-row'>
                    <MultilineFilter
                      name={'area'}
                      placeholder={'Search Area'}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 24]}>
                  {mapFilter.map(({name, placeholder}, index) => (
                    <Col className='gutter-row'>
                      <MultilineFilter
                        name={name}
                        placeholder={placeholder}
                        filterParams={filterParams}
                        setFilterParams={setFilterParams}
                        key={index}
                      />
                    </Col>
                  ))}
                  <Col className='gutter-row'>
                    <FilterByDropdown
                      name='Filter'
                      menu={['Verified', 'Not Verified', 'Both']}
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className='card'>
              {/*begin::Card header*/}
              <div className='card-header border-0 pt-6'>
                {/*begin::Card title*/}
                <div className='card-title'>
                  {/*begin::Search*/}
                  {/* <div className='d-flex align-items-center position-relative my-1'></div> */}
                  {/*end::Search*/}
                </div>
                {/*begin::Card title*/}
                {/*begin::Card toolbar*/}
                <div className='card-toolbar'>
                  <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                    {/*begin::Add merchant*/}
                    <Link className='btn btn-primary' to='/merchant/add'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <rect
                            opacity='0.5'
                            x='11.364'
                            y='20.364'
                            width={16}
                            height={2}
                            rx={1}
                            transform='rotate(-90 11.364 20.364)'
                            fill='black'
                          />
                          <rect x='4.36396' y='11.364' width={16} height={2} rx={1} fill='black' />
                        </svg>
                      </span>
                      Add Merchant
                    </Link>
                    {/*end::Add merchant*/}
                  </div>
                  {/*begin::Modal - Delete*/}
                  <div
                    className='modal fade'
                    id='kt_modal_delete_user'
                    tabIndex={-1}
                    aria-hidden='true'
                  >
                    {/*begin::Modal dialog*/}
                    <div className='modal-dialog modal-dialog-centered mw-650px'>
                      {/*begin::Modal content*/}
                      <div className='modal-content'>
                        {/*begin::Modal header*/}
                        <div className='modal-header' id='kt_modal_delete_user_header'>
                          {/*begin::Modal title*/}
                          <h2 className='fw-bolder'>Are you sure you want to delete the user?</h2>
                          {/*end::Modal title*/}
                          {/*begin::Close*/}
                          <div
                            className='btn btn-icon btn-sm btn-active-icon-primary'
                            data-kt-users-modal-action='close'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_delete_user'
                          >
                            {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
                            <span className='svg-icon svg-icon-1'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={24}
                                height={24}
                                viewBox='0 0 24 24'
                                fill='none'
                              >
                                <rect
                                  opacity='0.5'
                                  x={6}
                                  y='17.3137'
                                  width={16}
                                  height={2}
                                  rx={1}
                                  transform='rotate(-45 6 17.3137)'
                                  fill='black'
                                />
                                <rect
                                  x='7.41422'
                                  y={6}
                                  width={16}
                                  height={2}
                                  rx={1}
                                  transform='rotate(45 7.41422 6)'
                                  fill='black'
                                />
                              </svg>
                            </span>
                            {/*end::Svg Icon*/}
                          </div>
                          {/*end::Close*/}
                        </div>
                        {/*end::Modal header*/}
                        {/*begin::Modal body*/}
                        <div className='modal-body scroll-y mx-5 mx-xl-15'>
                          {/*begin::Form*/}
                          <div>
                            <button
                              type='submit'
                              className='btn btn-danger me-2'
                              data-kt-users-modal-action='submit'
                              onClick={(event) => handleDelete(event, id as string)}
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_delete_user'
                            >
                              <span className='indicator-label'>Delete</span>
                            </button>
                            <button
                              type='reset'
                              className='btn btn-light me-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_delete_user'
                            >
                              Cancel
                            </button>
                          </div>

                          {/*end::Form*/}
                        </div>
                        {/*end::Modal body*/}
                      </div>
                      {/*end::Modal content*/}
                    </div>
                    {/*end::Modal dialog*/}
                  </div>
                  {/*end::Modal - Delete */}

                  {/*begin::Modal - Add*/}
                  {/* <MerchantRegisterModal handleGetMerchants={getMerchants} /> */}
                  {/*end::Modal - Add*/}
                </div>
                {/*end::Card toolbar*/}
              </div>
              {/*end::Card header*/}
              {/*begin::Card body*/}
              <div className='card-body pt-0'>
                {/*begin::Table*/}
                <div
                  id='kt_table_users_wrapper'
                  className='dataTables_wrapper dt-bootstrap4 no-footer'
                >
                  <div className='table-responsive'>
                    {loading ? (
                      <div className='container'>
                        <div className='text-center'>
                          <span className='spinner-border spinner-border-lg align-middle ms-2' />
                        </div>
                      </div>
                    ) : (
                      <table
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        id='kt_table_users'
                      >
                        {/*begin::Table head*/}
                        <thead>
                          {/*begin::Table row*/}
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            {/* <th
                            className='w-10px pe-2 sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '29.25px'}}
                            aria-label=''
                          >
                            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                data-kt-check='true'
                                data-kt-check-target='#kt_table_users .form-check-input'
                                defaultValue={1}
                              />
                            </div>
                          </th> */}
                            <th
                              className='min-w-125px sorting'
                              tabIndex={0}
                              aria-controls='kt_table_users'
                              rowSpan={1}
                              colSpan={1}
                              style={{width: '240.65px'}}
                              aria-label='User: activate to sort column ascending'
                            >
                              User
                            </th>
                            <th
                              className='min-w-125px sorting'
                              tabIndex={0}
                              aria-controls='kt_table_users'
                              rowSpan={1}
                              colSpan={1}
                              style={{width: '134.65px'}}
                              aria-label='Phone Number: activate to sort column ascending'
                            >
                              Phone Number
                            </th>
                            <th
                              className='min-w-125px sorting'
                              tabIndex={0}
                              aria-controls='kt_table_users'
                              rowSpan={1}
                              colSpan={1}
                              style={{width: '140.417px'}}
                              aria-label='Joined Date: activate to sort column ascending'
                            >
                              Joined Date
                            </th>
                            <th
                              className='min-w-125px sorting'
                              tabIndex={0}
                              aria-controls='kt_table_users'
                              rowSpan={1}
                              colSpan={1}
                              style={{width: '134.65px'}}
                              aria-label='Status: activate to sort column ascending'
                            >
                              Status
                            </th>
                            <th
                              className='text-end min-w-100px sorting_disabled'
                              rowSpan={1}
                              colSpan={1}
                              style={{width: '106.433px'}}
                              aria-label='Actions'
                            >
                              Actions
                            </th>
                          </tr>
                          {/*end::Table row*/}
                        </thead>
                        {/*end::Table head*/}
                        {/*begin::Table body*/}

                        {userList && (
                          <tbody className='text-gray-600 fw-bold'>
                            {userList.data.length === 0 && <NoData />}
                            {userList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Checkbox*/}
                                  {/* <td>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      defaultValue={1}
                                    />
                                  </div>
                                </td> */}
                                  {/*end::Checkbox*/}
                                  {/*begin::User=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to={`/merchant/view/${item._id}`}>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          <img
                                            src={item.image}
                                            alt='merchant list'
                                            height='50px'
                                            width='50px'
                                          />
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::User details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/merchant/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.display_name}
                                      </Link>
                                      <span>{item.email}</span>
                                    </div>
                                    {/*begin::User details*/}
                                  </td>
                                  {/*end::User=*/}
                                  {/*begin::Phone Number=*/}
                                  <td>{item.phone_number}</td>
                                  {/*end::Phone Number=*/}
                                  {/*begin::Joined Date=*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*end::Joined Date=*/}
                                  {/*begin::Status*/}
                                  <td>
                                    {item.is_verified ? (
                                      <div className='badge badge-light-success fw-bolder'>
                                        Verified
                                      </div>
                                    ) : (
                                      <div className='badge badge-light-danger fw-bolder'>
                                        Not Verified
                                      </div>
                                    )}
                                  </td>
                                  {/*begin::Status*/}
                                  <td className='text-end'>
                                    <Link
                                      to={`/merchant/view/${item._id}`}
                                      className='menu-link px-3'
                                    >
                                      <i className='fa fa-eye'></i>
                                    </Link>
                                    <Link
                                      to={`/merchant/edit/${item._id}`}
                                      className='menu-link px-3'
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/art/art005.svg'
                                        className='svg-icon-3'
                                      />
                                    </Link>
                                    <a
                                      href='#'
                                      className='menu-link px-3'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete_user'
                                      onClick={() => setId(item._id)}
                                    >
                                      <KTSVG
                                        path='/media/icons/duotune/general/gen027.svg'
                                        className='svg-icon-3'
                                      />
                                    </a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        )}
                        {/*end::Table body*/}
                      </table>
                    )}
                  </div>
                  <Pagination
                    name='merchant'
                    prev_page={userList?.prev_page}
                    next_page={userList?.next_page}
                    total_number_of_page={userList?.total_number_of_page}
                    currentPage={filterParams.page}
                  />
                </div>
                {/*end::Table*/}
              </div>
              {/*end::Card body*/}
            </div>
            {/*end::Card*/}
          </div>
          {/*end::Container*/}
        </div>
        {/*end::Post*/}
      </div>
    </>
  )
}

export default ListMerchant
