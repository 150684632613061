/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {getAmenities} from '../api/amenities'
import {Link, useParams, useHistory} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {AmenitiesModel} from '../interface/Amenities'

export interface IPageParams {
  id: string
}
const ViewAmenities: FC = () => {
  const {id} = useParams<IPageParams>()
  const [amenities, setAmenities] = useState<AmenitiesModel>()
  let history = useHistory()

  useEffect(() => {
    getAmenities(id)
      .then(({data}) => {
        setAmenities(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {amenities && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link to={`/amenities/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_amenities_view_details' className='collapse show'>
              {console.log(amenities)}
              <div className='pb-5 fs-6'>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>ID</div>
                <div className='text-gray-600'>{amenities?._id}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>NAME</div>
                <div className='text-gray-600'>{amenities.name}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Icon</div>
                <div className='text-gray-600'>{amenities.icon}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Description</div>
                <div className='text-gray-600'>{amenities.description}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Created Date</div>
                <div className='text-gray-600'>{parseDate(amenities?.createdAt)}</div>
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewAmenities
