/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {deleteDeal, getAllDeal, getProductsForFilter, patchDeal} from '../api/deal'
import {Link, useParams} from 'react-router-dom'
import {AutoComplete, Col, DatePicker, Row, Switch, Tooltip} from 'antd'
import {Moment} from 'moment'
import {toast} from 'react-toastify'
import {RangeValue} from 'rc-picker/lib/interface'
import {parseDate} from '../../../../utils/dateAndTime'
import Pagination from '../../../components/Pagination'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Search from '../../../components/Search'
import {DealListModel} from '../../../components/deal/InterfaceDeal'
import Status from '../../../components/deal/Status'
import useMerchant from '../../../components/useMerchant'
import Spinner from '../../../components/Spinner'
import NoData from '../../../components/common/NoData'
import {IFilterParams} from '../interface/IFilterParams'

const {RangePicker} = DatePicker

interface IPageParams {
  pageNo: string
}

const filterValues = {
  page: '1',
  search: '',
  filterBy: 'all',
  productId: '',
  merchantId: '',
  date: '',
  limit: '',
}

const ListDeal: FC = () => {
  const [dealList, setDealList] = useState<DealListModel>()
  // const [search, setSearch] = useState<string>('')
  const [deleteId, setDeleteId] = useState<string>('')
  let {pageNo} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [products, setProducts] = useState([{value: 'Var'}, {value: 'Per'}])
  const [filterParams, setFilterParams] = useState<IFilterParams>(filterValues)
  const [merchants] = useMerchant()
  //
  function getDeals(filterParams: IFilterParams) {
    console.log(filterParams)
    setLoading(true)
    getAllDeal(filterParams)
      .then((data) => {
        setLoading(false)
        setDealList(data)
      })
      .catch((err) => {
        setLoading(false)
        toast.error('Error!')
      })
  }

  function initializeProducts() {
    getProductsForFilter()
      .then((data) => {
        const res = data.data.result
        setProducts([
          ...res.map((e) => {
            return {value: e.name, id: e._id}
          }),
        ])
      })
      .catch((err) => {})
  }

  // function initializeMerchants() {
  //   getMerchantsForFilter()
  //     .then((data) => {
  //       const res = data.data.result
  //       setMerchants([
  //         ...res.map((e) => {
  //           return { value: e.display_name, id: e._id }
  //         }),
  //       ])
  //     })
  //     .catch((err) => { })
  // }
  useEffect(() => {
    getDeals(filterParams)
  }, [filterParams])

  useEffect(() => {
    initializeProducts()
    // initializeMerchants()
  }, [])

  useEffect(() => {
    setFilterParams({...filterParams, page: pageNo})
  }, [pageNo])

  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteDeal(id).then((data) => {
      toast.success('Success!')
      getDeals(filterParams)
    })
  }

  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }

  // function handleSearch(event: React.ChangeEvent<any>) {
  //   event.preventDefault()
  //   const {value} = event.target
  //   setSearch(value)
  //   if (value === '') {
  //     getDeals(page)
  //     return
  //   }
  //   searchDeal(search).then((data) => {
  //     setDealList(data)
  //   })
  // }

  const handleDatePickerChange = (date: RangeValue<Moment>, dateString: [string, string]) => {
    setFilterParams({...filterParams, date: dateString})
  }

  function onChange(checked: boolean, id: string) {
    patchDeal(id, checked).then((data) => {
      toast.success('Success!')
    })
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div id='kt_content_container' className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card header*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}

                <Row gutter={[16, 24]} className='mb-4'>
                  <Col className='gutter-row'>
                    <Search
                      name={filterParams?.search!}
                      placeholder='deal'
                      handleSearch={handleFilter}
                    />
                  </Col>

                  <Col className='gutter-row'>
                    <AutoComplete
                      options={products}
                      style={{display: 'block'}}
                      filterOption={(inputValue, option) => {
                        return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }}
                      onSelect={(value, options) => {
                        setFilterParams({...filterParams, page: '1', productId: options.id})
                      }}
                    >
                      <input
                        className='form-control form-control-solid w-250px me-2'
                        placeholder='Search Products'
                      />
                    </AutoComplete>
                  </Col>
                  <Col className='gutter-row'>
                    <AutoComplete
                      options={merchants}
                      style={{display: 'block'}}
                      filterOption={(inputValue, option) => {
                        return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }}
                      onSelect={(value, options) => {
                        setFilterParams({...filterParams, page: '1', merchantId: options.id})
                      }}
                    >
                      <input
                        className='form-control form-control-solid w-250px'
                        placeholder='Search Merchants'
                      />
                    </AutoComplete>
                  </Col>
                  <Col>
                    <select
                      className='form-select form-select-solid'
                      data-placeholder='Select option'
                      defaultValue='all'
                      value={filterParams.filterBy}
                      onChange={(event) => {
                        setFilterParams({...filterParams, page: '1', filterBy: event.target.value})
                      }}
                    >
                      <option value='all' disabled>
                        Filter Status
                      </option>
                      <option value='1'>TO BE APPROVED</option>
                      <option value='2'>REJECTED</option>
                      <option value='3'>APPROVED</option>
                      {/* <option value='4'>EXPIRED</option> */}
                    </select>
                  </Col>

                  <Col className='gutter-row'>
                    <button
                      type='submit'
                      className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder me-2'
                      onClick={() => {
                        setFilterParams({
                          ...filterParams,
                          filterBy: 'all',
                          productId: '',
                          merchantId: '',
                          date: '',
                          search: '',
                        })
                      }}
                    >
                      Reset
                    </button>
                  </Col>
                  <Col className='gutter-row'>
                    <RangePicker
                      className='form-control form-control-solid me-2'
                      style={{display: 'inline-flex'}}
                      onChange={(date, dateString) => handleDatePickerChange(date, dateString)}
                    />
                  </Col>
                </Row>

                {/*end::Search*/}
              </div>
              {/*end::Card title*/}

              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                <div className='d-flex justify-content-end'></div>
                {/*begin::Modal - Delete*/}
                <ModalDelete id={deleteId} handleDelete={handleDelete} />
                {/*end::Modal - delete*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div
                id='kt_table_deals_wrapper'
                className='dataTables_wrapper dt-bootstrap4 no-footer'
              >
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            Deal
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Status
                          </th>
                          <th className='min-w-125px' style={{width: '140.417px'}}>
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            style={{width: '146.433px'}}
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {dealList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {dealList.data.length === 0 && <NoData />}
                          {dealList.data.length !== 0 &&
                            dealList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::Deal=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link to={`/deal/view/${item._id}`}>
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.images[0] ? (
                                            <img
                                              src={item.images[0]}
                                              alt='banner'
                                              height='50px'
                                              width='50px'
                                            />
                                          ) : (
                                            item.title[0]
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::Deal details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/deal/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.title}
                                      </Link>
                                      <span>{item.merchant_id.display_name}</span>
                                    </div>
                                    {/*begin::Deal details*/}
                                  </td>
                                  {/*end::Deal=*/}
                                  {/*begin::isapproved*/}
                                  <td>
                                    <Status status={item.is_approved} />
                                  </td>
                                  {/*begin::isapproved*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Tooltip placement='top' title={'Editor Choice'}>
                                      <Switch
                                        defaultChecked={item.is_editor_choice || false}
                                        onChange={(checked) => onChange(checked, item._id!)}
                                        className='me-3'
                                      />
                                    </Tooltip>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name='deal'
                  currentPage={filterParams.page}
                  prev_page={dealList?.prev_page}
                  next_page={dealList?.next_page}
                  total_number_of_page={dealList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListDeal
