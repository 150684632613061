import axios from 'axios'
import {ProductListModel, ProductResult, ProductModel} from '../interface/Product'

const API_URL = process.env.REACT_APP_API_URL

const PRODUCT_GETALL_URL = `${API_URL}/products/all`
const PRODUCT_REQ_URL = `${API_URL}/product/`

export const getAllProduct = async (page: string, params?: any): Promise<ProductListModel> => {
  let url = PRODUCT_GETALL_URL + `?page=${page}`
  if (params?.search) {
    url += `&search_text=${params.search}`
  }
  if (params?.merchantId) {
    url += `&merchant_id=${params.merchantId}`
  }
  const res = await axios.get(url)
  return res.data.result
}

export const getProduct = (id: string): Promise<ProductResult> => {
  return axios.get(PRODUCT_REQ_URL + id)
}

export const putProduct = (id: string, data: ProductModel): Promise<ProductResult> => {
  return axios.put(PRODUCT_REQ_URL + id, data)
}

export const postProduct = (data: ProductModel): Promise<ProductResult> => {
  return axios.post(PRODUCT_REQ_URL, data)
}

export const deleteProduct = (id: string): Promise<ProductResult> => {
  return axios.delete(PRODUCT_REQ_URL + id)
}

export const getProductsForFilter = (): Promise<ProductResult> => {
  return axios.get(`${API_URL}/merchants-for-filter`)
}
