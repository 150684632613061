import moment from 'moment'
import {Tag} from 'antd'
import {Link} from 'react-router-dom'
import {OrderListModel} from '../../../order-management/interface/Order'
import {KTSVG} from '../../../../../_metronic/helpers'
import NoData from '../../../../components/common/NoData'

interface Props {
  orderList: OrderListModel | undefined
}
const OrderTable = ({orderList}: Props) => {
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        {/* begin::Table head */}
        <thead>
          <tr className='fw-bolder text-muted'>
            <th className='min-w-150px'>Deals</th>
            <th className='min-w-120px'>No of Items</th>
            <th className='min-w-120px'>Price</th>
            <th className='min-w-120px'>Order Created</th>
            {/* <th className='min-w-100px text-end'>Actions</th> */}
          </tr>
        </thead>
        {/* end::Table head */}
        {/* begin::Table body */}
        <tbody>
          {orderList?.data && orderList?.data.length > 0 ? (
            orderList?.data.slice(0, 4).map((e, idx) => (
              <tr key={idx}>
                <td>
                  {e.order_names.map((e, idx) => (
                    <Tag key={idx} color='magenta' className='mb-2'>
                      {e}
                    </Tag>
                  ))}
                </td>
                <td>{e.no_of_orders}</td>
                <td>Rs. {e.total}</td>
                <td>{moment(e.createdAt).fromNow()}</td>
                <td className='text-end'>
                  <Link
                    to={'/order/view/' + e._id}
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                  >
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <NoData />
          )}
        </tbody>
        {/* end::Table body */}
      </table>
    </div>
  )
}

export default OrderTable
