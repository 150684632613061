/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getHeaderOffer, putHeaderOffer} from '../api/header-offer'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {HeaderOfferModel} from '../interface/HeaderOffer'
import GoBack from '../../../components/partials/GoBack'
import Button from '../../../components/common/Button'

export interface IPageParams {
  id: string
}
const URL = '/header-offer'
const EditHeader: FC = () => {
  const {id} = useParams<IPageParams>()
  const [header, setHeader] = useState<HeaderOfferModel>()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getHeaderOffer(id).then(({data}) => {
      setHeader(data.result)
    })
  }, [id])

  function handleChange(event: React.ChangeEvent<any>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const result = {...header, [name]: value} as HeaderOfferModel
    setHeader(result)
  }

  function handleSubmit(event: React.ChangeEvent<any>) {
    event.preventDefault()
    setLoading(true)
    putHeaderOffer(id, header as HeaderOfferModel)
      .then((data) => {
        history.push(URL)
        // history.push(`/header-offer/view/${id}`)
        toast.success('Success!')
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Update Header</PageTitle>

      {header && (
        <div className='card pt-4 mb-6 mb-xl-9'>
          <form onSubmit={handleSubmit}>
            {/*begin::Card body*/}
            <div className='card-body pt-0 pb-5'>
              {/*begin::Table*/}
              <table className='table align-middle table-row-dashed gy-5'>
                {/*begin::Table body*/}

                <tbody className='fs-6 fw-bold text-gray-600'>
                  <tr>
                    <td>Header Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={header.name}
                        onChange={handleChange}
                        name='name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Header Callback</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={header.callback}
                        onChange={handleChange}
                        name='callback'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Header Callback Name</td>
                    <td>
                      <input
                        type='text'
                        className='form-control'
                        value={header.callback_name}
                        onChange={handleChange}
                        name='callback_name'
                        required
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Featured</td>
                    <td>
                      <div className='col-lg-8 d-flex align-items-center'>
                        <div className='form-check form-check-solid form-switch fv-row'>
                          <input
                            className='form-check-input w-45px h-30px'
                            type='checkbox'
                            name='is_featured'
                            checked={header.is_featured}
                            onChange={handleChange}
                          />
                          <label className='form-check-label'></label>
                        </div>
                      </div>
                      {/* <input
                      type='checkbox'
                      id='flexCheckChecked'
                      className='form-check-input'
                      onChange={handleChange}
                      name='is_featured'
                      checked={header.is_featured}
                    /> */}
                    </td>
                  </tr>
                </tbody>
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
              <div className='d-flex justify-content-end align-items-center mt-15'>
                {/*begin::Button*/}
                <GoBack url={URL} />
                {/*end::Button*/}
                {/*begin::Button*/}
                <Button loading={loading} name='Update' />
                {/* <button type='button' className='btn btn-primary'>
                  <span className='indicator-label'>Save</span>
                  <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2' />
                  </span>
                </button> */}
                {/*end::Button*/}
              </div>
            </div>
          </form>
          {/*end::Card body*/}
        </div>
      )}
    </>
  )
}

export default EditHeader
