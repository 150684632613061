import {CSSProperties} from 'react'
import {StylesConfig} from 'react-select'

export type SelectOptionType = {
  label: string
  value: string
}

const customControlStyles: CSSProperties = {
  color: 'red',
  backgroundColor: '#f5f8fa',
  borderColor: '#f5f8fa',
  padding: '0.225rem 0.225rem',
  fontSize: '1.15rem',
}

type IsMulti = false

export const selectStyle: StylesConfig<SelectOptionType, IsMulti> = {
  // menu: (provided, state) => ({
  //   color: '#5e6278',
  // }),
  control: (provided, state) => {
    return {
      ...provided,
      ...customControlStyles,
    }
  },
}
