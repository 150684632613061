import axios from 'axios'
import {HeaderOfferListModel, HeaderOfferResult, HeaderOfferModel} from '../interface/HeaderOffer'

const API_URL = process.env.REACT_APP_API_URL

const HEADER_OFFER_GETALL_URL = `${API_URL}/headers/all`
const HEADER_OFFER_REQ_URL = `${API_URL}/header/`

export const getAllHeaderOffer = async (page: string): Promise<HeaderOfferListModel> => {
  const res = await axios.get(HEADER_OFFER_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getHeaderOffer = (id: string): Promise<HeaderOfferResult> => {
  return axios.get(HEADER_OFFER_REQ_URL + id)
}

export const putHeaderOffer = (id: string, data: HeaderOfferModel): Promise<HeaderOfferResult> => {
  return axios.put(HEADER_OFFER_REQ_URL + id, data)
}

export const postHeaderOffer = (data: HeaderOfferModel): Promise<HeaderOfferResult> => {
  return axios.post(HEADER_OFFER_REQ_URL, data)
}

export const deleteHeaderOffer = (id: string): Promise<HeaderOfferResult> => {
  return axios.delete(HEADER_OFFER_REQ_URL + id)
}

export const searchHeaderOffer = async (name: string): Promise<HeaderOfferListModel> => {
  const res = await axios.get(HEADER_OFFER_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
