export function parseValueToCeil(value: number) {
  return Math.ceil(value)
}
export function parseValueToTwoDecimal(value?: number) {
  if (value && isNaN(value)) {
    return 0
  }
  if (value) {
    return value.toFixed(2)
  }
  return 0
}
