// import {Spin} from 'antd'

export default function Spinner() {
  return (
    <div className='container'>
      <div className='text-center'>
        <span className='spinner-border spinner-border-lg align-middle ms-2' />
        {/* <Spin style={{height: '80vh', marginTop: '40px'}} /> */}
      </div>
    </div>
  )
}
