/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import NoData from '../../../../components/common/NoData'
import {TopSellingDeal} from '../interface/IDashboardModel'

type Props = {
  className: string
  deals: TopSellingDeal[]
}

const TopDeals: React.FC<Props> = ({className, deals}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Top Deals</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>
          </span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                Month
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                Week
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Day
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {deals && deals.length > 0 ? (
                    deals.map(({_id, deals, deal_sales, image}, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className='symbol symbol-45px me-2'>
                              <span className='symbol-label'>
                                <img src={image[0]} className='h-50 align-self-center' alt='' />
                              </span>
                            </div>
                          </td>
                          <td>
                            <Link
                              to={`/deal/view/${_id}`}
                              className='text-dark fw-bolder text-hover-primary mb-1 fs-6'
                            >
                              {deals.title}
                            </Link>
                            <span className='text-muted fw-bold d-block'>
                              {deals.extra_information.slice(0, 20)}
                            </span>
                          </td>
                          <td className='text-end text-muted fw-bold'>NPR {deals.display_price}</td>
                          <td className='text-end'>
                            <span className='badge badge-light-success'>{deal_sales}</span>
                          </td>
                          <td className='text-end'>
                            <Link
                              to={`/deal/view/${_id}`}
                              className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                            >
                              <KTSVG
                                path='/media/icons/duotune/arrows/arr064.svg'
                                className='svg-icon-2'
                              />
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <NoData />
                  )}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopDeals}
