import {FC} from 'react'
import {OrderItem} from '../../../../modules/order-management/interface/Order'
import ViewItem from '../../ViewItem'
interface Props {
  value: OrderItem
  is_gift: boolean
}
const OrderInformation: FC<Props> = ({is_gift, value}) => {
  const {
    gift_message,
    variation,
    quantity,
    deal_original_price,
    deal_price,
    deal_title,
    commission_percent,
    status,
    order_cancel,
  } = value

  function getCommissionPrice(price: number, percent?: number) {
    return Number(percent) * price * 0.01
  }

  return (
    <div className='collapse show'>
      <div className='pb-5 fs-6'>
        <ViewItem name={'Title'} value={deal_title} />
        <ViewItem name={'Quantity'} value={quantity} />
        <ViewItem name={'Original Price'} value={deal_original_price} />
        <ViewItem name={'Discounted Price'} value={deal_price} />
        <ViewItem name={'Comission Percent'} value={commission_percent} />
        <ViewItem
          name={'Comission Price'}
          value={getCommissionPrice(deal_price, commission_percent)}
        />
        {variation && <div className='fw-bolder mt-5'>Variation</div>}
        {variation &&
          Object.keys(variation).map((item) => (
            <>
              <div className='text-gray-600'>
                {item.toUpperCase()}: {variation[item]}
              </div>
            </>
          ))}

        {is_gift && gift_message && (
          <>
            <div className='fw-bolder mt-5'>Gift Message</div>
            <div className='text-gray-600'>To: {gift_message.to}</div>
            <div className='text-gray-600'>From: {gift_message.from}</div>
            <div className='text-gray-600'>Email: {gift_message.email}</div>
            <div className='text-gray-600'>Message: {gift_message.message}</div>
          </>
        )}
        {status === 'CANCELED' && (
          <>
            <div className='fw-bolder mt-5'>Order Cancel Reason</div>
            <div className='text-gray-600'>Reason: {order_cancel?.cancel_reason}</div>
            {order_cancel?.additional_information && (
              <div className='text-gray-600'>
                Additional Information: {order_cancel.cancel_reason}
              </div>
            )}
            {order_cancel?.date && <div className='text-gray-600'>Date: {order_cancel.date}</div>}
          </>
        )}
      </div>
    </div>
  )
}

export default OrderInformation
