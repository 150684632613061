import axios from 'axios'
import {
  OrderListModel,
  OrderModelResult,
  OrderModel,
  PutOrderModel,
} from '../../../order-management/interface/Order'

const API_URL = process.env.REACT_APP_API_MERCHANT_URL

const ORDER_GETALL_URL = `${API_URL}/orders/`
const ORDER_REQ_URL = `${API_URL}/order/`

export const getAllOrder = async (page: string,filter_by?:string,date?:string[]|string,search?:string): Promise<OrderListModel> => {
  const res = await axios.get(ORDER_GETALL_URL + `${filter_by}?page=${page}&date=${date}&search_text=${search}`)
  return res.data.result
}

export const getOrder = (id: string): Promise<OrderModelResult> => {
  return axios.get(ORDER_REQ_URL + id)
}

export const putOrder = (
  id: string,
  item_id: string,
  status: string
): Promise<OrderModelResult> => {
  return axios.put(`${ORDER_REQ_URL}${id}?item_id=${item_id}`, {status: status})
}

export const searchOrder = async (name: string): Promise<OrderListModel> => {
  const res = await axios.get(ORDER_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
