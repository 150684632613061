import {useState} from 'react'
import {Image, Upload, message, Button} from 'antd'
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons'

export default function MultiImageUpload(props: {
  image: any
  imageUrl: string
  imageLoading: boolean
  changeAvatar: any
}) {
  const {image, imageUrl, imageLoading, changeAvatar} = props
  const [visible, setVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState(image || imageUrl)
  function beforeUpload(file: any) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  )
  return (
    <div>
      <Upload
        name='file'
        listType='picture-card'
        className='avatar-uploader'
        showUploadList={true}
        action={process.env.REACT_APP_IMAGE_UPLOAD as string}
        onChange={changeAvatar}
        beforeUpload={beforeUpload}
      >
        {previewImage ? (
          <img src={previewImage} alt='avatar' style={{width: '100%'}} />
        ) : (
          uploadButton
        )}
      </Upload>
      {previewImage && (
        <>
          <Button type='primary' className='w-100px' onClick={() => setVisible(true)}>
            View
          </Button>
          <Image
            width={200}
            style={{display: 'none'}}
            src={previewImage}
            preview={{
              visible,
              src: previewImage,
              onVisibleChange: (value) => {
                setVisible(value)
              },
            }}
          />
        </>
      )}
    </div>
  )
}
