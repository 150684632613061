/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getOrder} from '../api/order'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {OrderModel} from '../interface/Order'

export interface IPageParams {
  id: string
}
const EditOrder: FC = () => {
  const {id} = useParams<IPageParams>()
  const [order, setOrder] = useState<OrderModel>()
  const history = useHistory()

  useEffect(() => {
    getOrder(id).then(({data}) => {
      setOrder(data.result)
    })
  }, [id])

  function handleChange(event: React.ChangeEvent<any>) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    const result = {...order, [name]: value} as OrderModel
    setOrder(result)
  }

  function handleSubmit() {
    // const newOrder = {
    //   quantity: order?.quantity,
    //   status: order?.status,
    // }
    // putOrder(id, newOrder as PutOrderModel)
    //   .then((data) => {
    //     history.push(`/order/view/${id}`)
    //     toast.success('Success!')
    //   })
    //   .catch((error) => {
    //     console.log(error)
    //   })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit Order</PageTitle>

      {order && (
        <div className='card pt-4 mb-6 mb-xl-9'>
          {/*begin::Card body*/}
          <div className='card-body pt-0 pb-5'>
            {/*begin::Table*/}
            <table className='table align-middle table-row-dashed gy-5'>
              {/*begin::Table body*/}
              <tbody className='fs-6 fw-bold text-gray-600'>
                <tr>
                  <td>Order ID</td>
                  <td>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Order ID'
                      value={order._id}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>Order Product Name</td>
                  <td>
                    <input
                      type='text'
                      className='form-control'
                      // value={order.deal_id.title}
                      onChange={handleChange}
                      name='name'
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>Order Merchant Name</td>
                  <td>
                    <input
                      type='text'
                      className='form-control'
                      // value={order.merchant_id.display_name}
                      onChange={handleChange}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td>Order Quantity</td>
                  <td>
                    <input
                      type='number'
                      className='form-control'
                      // value={order.quantity}
                      onChange={handleChange}
                      name='quantity'
                    />
                  </td>
                </tr>
                <tr>
                  <td>Order Status</td>
                  <td>
                    <select name='status' className='form-select' onChange={handleChange}>
                      {/* <option value='ONROUTE'>On Route</option>
                      <option value='PROCESSING'>Processing</option>
                      <option value='PENDING'>Pending</option>
                      <option value='COMPLETE'>Complete</option>
                      <option value='CANCELED'>Canceled</option> */}
                      <option value='ACKNOWLEDGED'>Acknowledged</option>
                      <option value='PACKAGING'>PACKAGING</option>
                      <option value='SHIPPED'>Shipped</option>
                      <option value='DELIVERED'>Delivered</option>
                      <option value='CANCELED'>Canceled</option>
                      <option value='REJECTED'>Rejected</option>
                    </select>
                  </td>
                </tr>
              </tbody>
              {/*end::Table body*/}
            </table>
            {/*end::Table*/}
            <div className='d-flex justify-content-end align-items-center mt-15'>
              {/*begin::Button*/}
              <button onClick={() => history.goBack()} className='btn btn-light me-5'>
                Back
              </button>
              {/*end::Button*/}
              {/*begin::Button*/}
              <button type='button' className='btn btn-primary' onClick={handleSubmit}>
                <span className='indicator-label'>Save</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </button>
              {/*end::Button*/}
            </div>
          </div>
          {/*end::Card body*/}
        </div>
      )}
    </>
  )
}

export default EditOrder
