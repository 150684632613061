import React, {FC, useContext, useEffect, useState} from 'react'
import {Form, Select, Card, Button, InputNumber, Input} from 'antd'
import CreateEditProductContext from '../../contexts/CreateEditProductContext'
const {Option} = Select

interface Props {
  items: any
  index: number
  attributes: any
  remove: any
  form: any
  parentIndex?: number
  label: string[]
}

const Attribute: FC<Props> = ({items, index, attributes, remove, form, parentIndex, label}) => {
  const {key, name, fieldKey, ...restField} = items
  const {handleStockChange} = useContext(CreateEditProductContext)
  const [stock, setStock] = useState<number>(0)
  const newLabel = label.filter((e) => e !== 'stock').filter((e) => e !== 'sku')

  useEffect(() => {
    const variation = form.getFieldValue('variation')
    if (variation) {
      const sum = (accumulator: number, curr: number) => accumulator + curr
      const stock = variation
        .map((e: any) => {
          if (e && e.stock) {
            return e.stock
          }
        })
        .reduce(sum)
      handleStockChange(stock)
    }
  }, [stock])

  // useEffect(() => {
  //   if (disabled) {
  //     const fields = form.getFieldsValue()
  //     console.log(fields.variation.map(({stock, ...keepProperty}: any) => keepProperty))
  //     form.setFieldsValue({
  //       ...fields,
  //       stock: 0,
  //       variation: fields.variation.map(({stock, ...keepProperty}: any) => keepProperty),
  //     })
  //   }
  // }, [])

  // const [options, setOptions] = useState([])
  // useEffect(() => {
  // if (form.getFieldValue()?.variation) {
  //   const title = form.getFieldValue()?.variation[index]?.id
  //   const options = title && attributes.find((item: any) => item._id === title)
  //   options && setOptions(options.values)
  // }
  // if (form.getFieldValue()?.deals && typeof parentIndex !== 'undefined') {
  //   const deals = form.getFieldValue().deals
  //   const title = deals[parentIndex].variation[index]?.id
  //   const options = title && attributes.find((item: any) => item._id === title)
  //   options && setOptions(options.values)
  // }
  // }, [])

  return (
    <Card
      key={key}
      type='inner'
      title={`Options ${index + 1}`}
      style={{marginBottom: 16}}
      extra={
        <Button type='text' danger onClick={() => remove(name)}>
          Remove
        </Button>
      }
    >
      {/* <Form.Item
        {...restField}
        name={[name, 'id']}
        fieldKey={[fieldKey, 'id']}
        rules={[{required: true, message: 'This field is required'}]}
        label={label[0]}
      >
        <Select
          showSearch
          placeholder='Select attribute type'
          optionFilterProp='children'
          onSelect={(value) => {
            if (typeof parentIndex !== 'undefined') {
              let newDeal = form.getFieldsValue().deals
              newDeal[parentIndex].variation[index].selected = undefined
              form.setFieldsValue({deals: newDeal})
            }
            for (var i = 0; i < attributes.length; i++) {
              if (attributes[i]['_id'] === value) {
                setOptions(attributes[i]['values'])
                break
              }
            }
          }}
        >
          {attributes.length > 0 &&
            attributes.map((e: any, idx: number) => (
              <Option key={idx} value={e._id}>
                {e.title}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'selected']}
        fieldKey={[fieldKey, 'selected']}
        rules={[{required: true, message: 'This field is required'}]}
        label={label[1]}
      >
        <Select mode='tags' placeholder='Select Values'>
          {options.map((e, idx) => (
            <Option key={idx} value={idx}>
              {e}
            </Option>
          ))}
        </Select>
      </Form.Item> */}
      {newLabel.map((item, idx) => {
        return (
          <Form.Item
            {...restField}
            name={[name, item]}
            fieldKey={[fieldKey, item]}
            rules={[{required: true, message: 'This field is required'}]}
            label={item}
            key={item}
          >
            <Select
              showSearch
              placeholder='Select attribute type'
              optionFilterProp='children'
              onSelect={(value) => {}}
            >
              {/* {getAttributes(attributes, item, idx)} */}
              {attributes
                .filter((el: any) => el['title'] === item)[0]
                ['values'].map((e: any, index: number) => {
                  return (
                    <Option key={idx + index} value={e}>
                      {e}
                    </Option>
                  )
                })}
            </Select>
          </Form.Item>
        )
      })}
      <Form.Item
        {...restField}
        name={[name, 'sku']}
        rules={[{required: true, message: 'This field is required'}]}
        label='SKU'
      >
        <Input placeholder='e.g. SWHITE10' style={{width: 120}} />
      </Form.Item>
      <Form.Item
        {...restField}
        name={[name, 'stock']}
        rules={[
          {required: true, message: 'This field is required'},

          {
            type: 'integer',
            min: 0,
            message: 'Stock should be positive',
          },
        ]}
        label='Stock Qty'
        value={stock}
        onChange={(event: React.ChangeEvent<any>) => {
          setStock(event?.target.value)
        }}
      >
        <InputNumber placeholder='100' />
      </Form.Item>
    </Card>
  )
}

export default Attribute
