/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../../_metronic/helpers'
import NoData from '../../../../components/common/NoData'
import {RecentProduct} from '../interface/IDashboardModel'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  product: RecentProduct[]
}

const RecentProducts: React.FC<Props> = ({className, product}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Recent Products</span>
          <span className='text-muted fw-bold fs-7'>Top recent products</span>
        </h3>

        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        {/* begin::Items */}
        <div>
          {product && product.length > 0 ? (
            product.map(({_id, name, actual_price, description, image}, index) => {
              return (
                <div className='d-flex flex-stack mb-5' key={index}>
                  {/* begin::Section */}
                  <div className='d-flex align-items-center me-2'>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-3'>
                      <div className='symbol-label bg-light'>
                        <img src={image} alt='' className='h-50' />
                      </div>
                    </div>
                    {/* end::Symbol */}

                    {/* begin::Title */}
                    <div>
                      <Link
                        to={`/product/view/${_id}`}
                        className='fs-6 text-gray-800 text-hover-primary fw-bolder'
                      >
                        {name}
                      </Link>
                      <div className='fs-7 text-muted fw-bold mt-1'>
                        {description.slice(0, 20)}...
                      </div>
                    </div>
                    {/* end::Title */}
                  </div>
                  {/* end::Section */}

                  {/* begin::Label */}
                  <div className='badge badge-light fw-bold py-4 px-3'>NPR {actual_price}</div>
                  {/* end::Label */}
                </div>
              )
            })
          ) : (
            <NoData />
          )}
        </div>
        {/* end::Body */}
      </div>
    </div>
  )
}

export {RecentProducts}
