import axios from 'axios'
import {CategoryModel} from '../../../category/interface/Category'
import {ProductListModel, ProductModel, ProductResult} from '../../../product/interface/Product'
import {VariationListModel} from '../../../variation-management/interface/IVariation'
import {ResultModel} from '../../../../interface/Result'
import {IFilterParams} from '../interface/IFilterParams'

const API_URL = process.env.REACT_APP_API_MERCHANT_URL

const PRODUCT_GETALL_URL = `${API_URL}/products/all`
const PRODUCT_REQ_URL = `${API_URL}/product/`
const VARIATION_REQ_URL = `${API_URL}/variation`

export interface CategoryResult {
  result: Array<CategoryModel>
}

interface ProductStockInWithVariation {
  product_id: string
  data: {
    quantity: number
    variation_index: number
  }[]
}

interface ProductStockInWithoutVariation {
  product_id: string
  stock: number
}

export const getAllProduct = async (filterParams: IFilterParams): Promise<ProductListModel> => {
  const {page, search, limit} = filterParams
  const res = await axios.get(
    PRODUCT_GETALL_URL + `?page=${page}&search_text=${search}&limit=${limit}`
  )
  return res.data.result
}

export const getProduct = (id: string, show: string): Promise<ProductResult> => {
  return axios.get(`${PRODUCT_REQ_URL}${id}?show=${show}`)
}

export const putProduct = (id: string, data: ProductModel): Promise<ProductResult> => {
  return axios.put(PRODUCT_REQ_URL + id, data)
}

export const postProduct = (data: ProductModel): Promise<ProductResult> => {
  return axios.post(PRODUCT_REQ_URL, data)
}

export const deleteProduct = (id: string): Promise<ProductResult> => {
  return axios.delete(PRODUCT_REQ_URL + id)
}

export const postProductStockInWithVariation = async (
  data: ProductStockInWithVariation
): Promise<ResultModel> => {
  const res = await axios.post(`${PRODUCT_REQ_URL}variation/stockin`, data)
  return res.data.result
}

export const postProductStockInWithoutVariation = async (
  data: ProductStockInWithoutVariation
): Promise<ResultModel> => {
  const res = await axios.post(`${PRODUCT_REQ_URL}stockin`, data)
  return res.data.result
}

export const searchProduct = async (name: string): Promise<ProductListModel> => {
  const res = await axios.get(PRODUCT_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}

export const getSubCategory = async (): Promise<CategoryResult> => {
  const res = await axios.get(`${API_URL}/subcategory`)
  return res.data
}

export const getVariations = async (id: string): Promise<VariationListModel> => {
  const res = await axios.get(`${VARIATION_REQ_URL}/${id}`)
  return res.data.result
}
