import {useState, useEffect} from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

const CATEGORY_URL = `${API_URL}/categorys`

interface CategoryResult {
  error: any
  result: {
    _id: string
    name: string
  }[]
}

export const getCategoryIdName = async (
  level: string,
  parent_category?: string
): Promise<CategoryResult> => {
  let url = `${CATEGORY_URL}?level=${level}`
  if (parent_category) {
    url = `${CATEGORY_URL}?level=${level}&parent_category=${parent_category}`
  }
  const res = await axios.get(url)
  return res.data
}

const useCategory = (level: string, option?: string, parent_category?: string, isAdmin = true) => {
  const [categorys, setCategorys] = useState<{value: string; label: string}[]>([
    {value: '', label: ''},
  ])

  function initializeCategorys() {
    isAdmin &&
      getCategoryIdName(level, parent_category)
        .then((data) => {
          setCategorys([
            ...data.result.map((e) => {
              return option === 'react-select'
                ? {value: e._id, label: e.name}
                : {
                    label: e.name,
                    value: e.name,
                    id: e._id,
                  }
            }),
          ])
        })
        .catch((err) => {})
  }

  useEffect(() => {
    initializeCategorys()
  }, [])

  return [categorys]
}

export default useCategory
