import {createContext} from 'react'

const CreateEditProductContext = createContext<{
  handleStockChange: (stock: number) => void
  checkedList: string[]
  setCheckedList: any
}>({
  handleStockChange: (stock: number) => {},
  checkedList: [],
  setCheckedList: () => {},
})

export default CreateEditProductContext
