/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function MerchantAsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <AsideMenuItemWithSub to='/product' title='Products'>
        <AsideMenuItem to='/product/add' title='Add New Product' hasBullet={true} />
        <AsideMenuItem to='/product/1' title='Manage Products' hasBullet={true} />
        <AsideMenuItem to='/product/stock' title='Stock In' hasBullet={true} />

        {/* <AsideMenuItem to='/product/images' title='Manage Images' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to='/deal' title='Deals'>
        <AsideMenuItem to='/deal/add' title='Add New Deal' hasBullet={true} />
        <AsideMenuItem to='/deal/1' title='Manage Deals' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem to='/order/1' title='Orders' />
      {/* <AsideMenuItemWithSub to='/order' title='Order & Reviews'>
        <AsideMenuItem to='/order/review/1' title='Manage Reviews' hasBullet={true} />
        <AsideMenuItem to='/order/dropoff/1' title='Schedule Dropoffs' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      <AsideMenuItemWithSub to='/finance' title='Finance'>
        <AsideMenuItem to='/finance/statement' title='Account Statement' hasBullet={true} />
        {/* <AsideMenuItem to='/finance/overview' title='Order Overview' hasBullet={true} />
        <AsideMenuItem to='/finance/transaction' title='Transaction Overview' hasBullet={true} /> */}
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub to='/support' title='Seller Support'>
        <AsideMenuItem to='/support/help-center' title='Help Center' hasBullet={true} />
        <AsideMenuItem to='/support/contact-us' title='Contact Us' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      <AsideMenuItemWithSub to='/account' title='Account & Settings'>
        <AsideMenuItem to='/account/profile' title='General Settings' hasBullet={true} />
        <AsideMenuItem to='/account/amenities' title='Amenities' hasBullet={true} />
        <AsideMenuItem to='/account/location' title='Location' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub to='/seller' title='Documents'>
        <AsideMenuItem to='/seller/templates' title='Templates' hasBullet={true} />
      </AsideMenuItemWithSub> */}
    </>
  )
}
