import React, {useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {
  AddressModal,
  addressSchema,
  addressValues,
  MerchantAddressModal,
} from '../../interface/account'
import {putMerchantAddress} from '../../api/account'
import {toast} from 'react-toastify'
import {
  putMerchantReturnAddress,
  putMerchantWarehouseAddress,
} from '../../../../user-management/api/merchant'

const Address: React.FC<MerchantAddressModal> = ({
  user,
  warehouse_address,
  copy_button,
  isAdmin,
  userId,
}) => {
  const [loading, setLoading] = useState(false)
  const formik = useFormik<AddressModal>({
    initialValues: user ? user : addressValues,
    validationSchema: addressSchema,
    onSubmit: (values) => {
      setLoading(true)
      if (!isAdmin) {
        putMerchantAddress(values, copy_button)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      } else if (copy_button) {
        putMerchantReturnAddress(userId, values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      } else {
        putMerchantWarehouseAddress(userId, values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      }
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} className='form'>
      <div className='card-body p-9'>
        {copy_button && (
          <div className='row mb-7'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span>Same as Warehouse Address</span>
            </label>
            <div className='col-lg-8 d-flex align-items-center'>
              <div className='form-check form-check-solid form-switch fv-row'>
                <input
                  className='form-check-input w-45px h-30px'
                  type='checkbox'
                  onChange={(event) => {
                    formik.setValues(warehouse_address as AddressModal)
                  }}
                />
                <label className='form-check-label'></label>
              </div>
            </div>
          </div>
        )}

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Full Name</span>
          </label>

          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('full_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.full_name && formik.errors.full_name,
                },
                {
                  'is-valid': formik.touched.full_name && !formik.errors.full_name,
                }
              )}
            />

            {formik.touched.full_name && formik.errors.full_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.full_name}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Address</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('address')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.address && formik.errors.address,
                },
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.address}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Phone Number</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('phone_number')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
                },
                {
                  'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                }
              )}
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.phone_number}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Email</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.email && formik.errors.email,
                },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>City/Town</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('city')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.city && formik.errors.city,
                },
                {
                  'is-valid': formik.touched.city && !formik.errors.city,
                }
              )}
            />
            {formik.touched.city && formik.errors.city && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.city}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Country/Region</span>
          </label>
          <div className='col-lg-8'>
            <select
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('country')}
            >
              <option value=''>Select a country</option>
              <option value='nepal'>Nepal</option>
            </select>
            {formik.touched.country && formik.errors.country && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.country}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Province</span>
          </label>
          <div className='col-lg-8'>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              {...formik.getFieldProps('zone')}
            />
            {formik.touched.zone && formik.errors.zone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.zone}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Area</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('area')}
            />
            {formik.touched.area && formik.errors.area && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.area}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>District</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid'
              {...formik.getFieldProps('region')}
            />
            {formik.touched.region && formik.errors.region && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.region}</div>
              </div>
            )}
          </div>
        </div>
        <div className='cursor-pointer'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default Address
