import React, {useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {
  bankAccountSchema,
  BankAccountModal,
  MerchantBankAccountProps,
  bankAccountValues,
} from '../../interface/account'
import {putMerchantBankAccount} from '../../api/account'
import {toast} from 'react-toastify'
import {bankList} from './BankList'
import Select from 'react-select'
import {selectStyle} from '../../../../../components/Select'
import {Upload, message} from 'antd'
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons'
import {putMerchantBankDetails} from '../../../../user-management/api/merchant'

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

const BankAccount: React.FC<MerchantBankAccountProps> = ({user, isAdmin, userId}) => {
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [imageUrl, setImageUrl] = useState('')
  const [imageLoading, setImageLoading] = useState(false)

  const uploadButton = (
    <div>
      {imageLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  )

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading(true)
      return
    }
    if (info.file.status === 'done') {
      setImageUrl(info.file.response.url)
      setImageLoading(false)
    }
  }

  const formik = useFormik<BankAccountModal>({
    initialValues: user ? user : bankAccountValues,
    validationSchema: bankAccountSchema,
    onSubmit: (values) => {
      setLoading(true)
      if (selectedOption) {
        values = {...values, bank_name: selectedOption.label}
      }
      if (imageUrl) {
        values = {...values, cheque_image: imageUrl}
      }
      if (!isAdmin) {
        putMerchantBankAccount(values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      } else {
        putMerchantBankDetails(userId, values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      }
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} className='form'>
      <div className='card-body p-9'>
        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Account Title</span>
          </label>

          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('account_title')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.account_title && formik.errors.account_title,
                },
                {
                  'is-valid': formik.touched.account_title && !formik.errors.account_title,
                }
              )}
            />

            {formik.touched.account_title && formik.errors.account_title && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.account_title}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Account Number</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('account_number')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.account_number && formik.errors.account_number,
                },
                {
                  'is-valid': formik.touched.account_number && !formik.errors.account_number,
                }
              )}
            />
            {formik.touched.account_number && formik.errors.account_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.account_number}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Bank Name</span>
          </label>
          <div className='col-lg-8'>
            <Select
              defaultValue={
                selectedOption
                  ? selectedOption
                  : {label: formik.values.bank_name, value: formik.values.bank_name}
              }
              onChange={setSelectedOption}
              options={bankList}
              styles={selectStyle}
            />
            {/* <select
              className='form-select form-select-solid form-select-lg'
              {...formik.getFieldProps('bank_name')}
            >
              <option value=''>Select Bank Account</option>
              {bankList.map((item, index) => (
                <option value={item.value} key={index}>
                  {item.label}
                </option>
              ))}
            </select> */}
            {formik.touched.bank_name && formik.errors.bank_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.bank_name}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Branch Name</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('branch_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.branch_name && formik.errors.branch_name,
                },
                {
                  'is-valid': formik.touched.branch_name && !formik.errors.branch_name,
                }
              )}
            />
            {formik.touched.branch_name && formik.errors.branch_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.branch_name}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Tax Category</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('tax_category')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.tax_category && formik.errors.tax_category,
                },
                {
                  'is-valid': formik.touched.tax_category && !formik.errors.tax_category,
                }
              )}
            />
            {formik.touched.tax_category && formik.errors.tax_category && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.tax_category}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Upload Cheque Copy</span>
          </label>
          <div className='col-lg-8'>
            <Upload
              name='file'
              listType='picture-card'
              className='avatar-uploader'
              showUploadList={false}
              action={process.env.REACT_APP_IMAGE_UPLOAD as string}
              onChange={changeAvatar}
              beforeUpload={beforeUpload}
            >
              {formik.values.cheque_image || imageUrl ? (
                <img
                  src={imageUrl ? imageUrl : formik.values.cheque_image}
                  alt='avatar'
                  style={{width: '100%'}}
                />
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
        </div>

        <div className='cursor-pointer'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  )
}

export default BankAccount
