/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getHeaderOffer} from '../api/header-offer'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {Link, useParams, useHistory} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {HeaderOfferModel} from '../interface/HeaderOffer'
import {capitalize} from '../../../../utils/capitalize'

export interface IPageParams {
  id: string
}
const ViewHeader: FC = () => {
  const {id} = useParams<IPageParams>()
  const [header, setHeader] = useState<HeaderOfferModel>()
  let history = useHistory()

  useEffect(() => {
    getHeaderOffer(id)
      .then(({data}) => {
        setHeader(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {header && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link to={`/header-offer/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_header_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>ID</div>
                <div className='text-gray-600'>{header?._id}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>NAME</div>
                <div className='text-gray-600'>{header.name}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>CALLBACK</div>
                <div className='text-gray-600'>{header.callback}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>CALLBACK Name</div>
                <div className='text-gray-600'>{header.callback_name}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Featured</div>
                <div className='text-gray-600'>{header.is_featured ? 'Yes' : 'No'}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Created Date</div>
                <div className='text-gray-600'>{parseDate(header?.createdAt)}</div>
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewHeader
