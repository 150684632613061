/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getCategory} from '../api/category'
import {Link, useParams, useHistory} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {CategoryModel} from '../interface/Category'
import {capitalize} from '../../../../utils/capitalize'
import ViewItem from '../../../components/common/ViewItem'

export interface IPageParams {
  id: string
}
const ViewCategory: FC<{level: Number}> = ({level}) => {
  const {id} = useParams<IPageParams>()
  const [category, setCategory] = useState<CategoryModel>()
  let history = useHistory()

  useEffect(() => {
    getCategory(id)
      .then(({data}) => {
        setCategory(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {category && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::User Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={category.image} alt='avatar' />
              </div>
              {/*end::Avatar*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {capitalize(category?.name)}
                </div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::User Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link
                  to={`/${
                    level === 1 ? 'category' : level === 2 ? 'subcategory' : 'innercategory'
                  }/edit/${id}`}
                  className='btn btn-sm btn-light-primary'
                >
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_user_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>ID</div>
                <div className='text-gray-600'>{category?._id}</div>
                {/*end::Details item*/}
                {level > 1 && (
                  <>
                    <div className='fw-bolder mt-5'>Parent Category</div>
                    <div className='text-gray-600'>
                      <Link
                        to={`/${level === 2 ? 'category' : 'subcategory'}/view/${
                          category?.parent?.value
                        }`}
                        className='text-gray-800 text-hover-primary mb-1'
                      >
                        {category?.parent?.label}
                      </Link>
                    </div>
                  </>
                )}
                {/*begin::Details item*/}
                <ViewItem name={'NAME'} value={category.name} />
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <ViewItem name={'DESCRIPTION'} value={category.description} />
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <ViewItem name={'Featured'} value={category.is_featured ? 'Yes' : 'No'} />
                {category.is_featured && (
                  <>
                    <ViewItem name={'Featured Callback'} value={category.callback} />
                    <ViewItem name={'Featured Icon'} value={category.icon} />
                  </>
                )}

                {/*end::Details item*/}
                <ViewItem name={'commission percent'} value={category?.commission_percent} />
                {/*begin::Details item*/}
                <ViewItem name={'Created Date'} value={parseDate(category?.createdAt)} />
                <div className='fw-bolder mt-5'></div>
                <div className='text-gray-600'></div>
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewCategory
