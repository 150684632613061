import React from 'react'
import moment from 'moment'
import styles from './print.module.css'
// import {parseValueToCeil} from '../../../../../utils/parseNumber'
// import humanizeAmountToWord from './humanizeAmountToWord'

export default class ComponentToPrint extends React.PureComponent<{data: any}, {}> {
  render() {
    const data = this.props.data
    return (
      <div className={styles.printable_invoice}>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-8'>
              <h3>GO DEALS</h3>
              <h6>Kathmandu, Nepal 44600</h6>
              <h6>Tel: 9841243947, 9849920442, 9840807492</h6>
              <h6>Email: info@godeals.com.np</h6>
            </div>
            <div className='col-sm-4'>
              <img
                src='https://godeals.com.np/assets/img/logo.png'
                className='img-round mb-10'
                id='img'
                alt='GoDeals'
                style={{
                  width: '120px',
                }}
              />
            </div>
          </div>
          <hr />
        </div>
        <div className='container'>
          {/* <h1 className='text-center my-5'>TAX INVOICE</h1> */}
          <div className='card'>
            <div className='card-header'>
              <div className='row'>
                <div className='col-12'>
                  <span className='float-right'>
                    <strong>Invoice number:</strong> 123455
                  </span>
                </div>
                <div className='col'>
                  <span>
                    <strong>Invoice Date:</strong>
                    {moment(data.createdAt).format('MMMM Do YYYY, h:mm a')}
                  </span>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className='row'>
                {/* <div className='col-sm-8'>
                  <h5 className='mb-3'>Details of Merchant</h5>
                  <div>
                    <strong>Name:</strong>
                    {data?.merchant_id.display_name}
                  </div>
                  <div>
                    <strong>Pan Number:</strong> 304564234
                  </div>
                  <div>
                    <strong>Address:</strong> Mid-baneshor, Kathmandu
                  </div>
                  <div>
                    <strong>Email:</strong>
                    {data?.merchant_id.email}
                  </div>
                  <div>
                    <strong>Phone:</strong> +48 444 666 3333
                  </div>
                </div> */}
                <div className='col-sm-4'>
                  <h5 className='mb-3'>Details of Buyer</h5>
                  {data.express_checkout ? (
                    <>
                      <div>
                        <strong>Email:</strong>
                        {data.express_checkout.email}
                      </div>
                      <div>
                        <strong>Phone:</strong>
                        {data.express_checkout.phone_number}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <strong>Name:</strong> {data.user_id?.full_name}
                      </div>
                      {data?.user_id?.address && (
                        <div>
                          <strong>Address:</strong>
                          {data.user_id.address}
                        </div>
                      )}
                      <div>
                        <strong>Email:</strong>
                        {data.user_id?.email}
                      </div>
                      <div>
                        <strong>Phone:</strong>
                        {data.user_id?.phone_number}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='table-responsive-sm'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>S.N</th>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      data.items.map((e: any, idx: number) => {
                        return (
                          <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{e.deal_title}</td>
                            <td>{e.quantity}</td>
                            <td>{e.deal_price}</td>
                            <td>{e.quantity * e.deal_price}</td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
              <div className='row' style={{display: 'flex', justifyContent: 'flex-end'}}>
                <div className='col-lg-4 col-sm-5 ml-auto'>
                  <table className='table table-clear'>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Subtotal</strong>
                        </td>
                        <td>{data.sub_total}</td>
                      </tr>
                      {data.coupon_discount ? (
                        <tr>
                          <td>
                            <strong>Coupon Discount</strong>
                          </td>
                          <td>{data.coupon_discount}</td>
                        </tr>
                      ) : (
                        ''
                      )}
                      <tr>
                        <td>
                          <strong>Delivery Charge</strong>
                        </td>
                        <td>50</td>
                      </tr>
                      {/* <tr>
                        <td>
                          <strong>VAT</strong>
                        </td>
                        <td>{parseValueToCeil(0.13 * data.sub_total)}</td>
                      </tr> */}
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td>
                          <strong>{data.total}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* <p className='font-weight-normal'>
                <strong>Invoice Value(In Words)</strong> :
                {humanizeAmountToWord(data.total - data.coupon_discount)} only
              </p> */}
              <div className='row pt-5 mt-15'>
                <div className='col-lg-2'>
                  <p>Authorized Signature</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
