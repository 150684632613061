import {useState, useEffect} from 'react'
import {Upload, Modal, message} from 'antd'
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons'
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface'

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return (isJpgOrPng && isLt2M) || Upload.LIST_IGNORE
}

const MultiImage: React.FC<{
  imagesUrl: any
  setImagesUrl: any
  disabled?: boolean
}> = ({imagesUrl, setImagesUrl, disabled = false}) => {
  const [loading, setLoading] = useState(false)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>()

  useEffect(() => {
    if (imagesUrl && imagesUrl.length > 0) {
      setFileList(
        imagesUrl.map((e: any, index: any) => ({
          uid: index,
          name: `image${index}.png`,
          status: 'done',
          url: e,
        }))
      )
    }
  }, [imagesUrl])

  const handleChange: UploadProps['onChange'] = ({file, fileList}) => {
    if (file.status !== 'removed') {
      fileList.length > 0 &&
        setImagesUrl(
          fileList.map((e) => {
            if (e.response) {
              return e.response.url
            } else {
              return e.url
            }
          })
        )
      setFileList(fileList)
    }
  }
  const onRemove = (file: UploadFile<any>) => {
    if (fileList) {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    }
  }

  const handleCancel = () => setPreviewVisible(false)

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile)
    }

    setPreviewImage(file.url || (file.preview as string))
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  )

  return (
    <div className='mb-5'>
      <Upload
        name='file'
        action={process.env.REACT_APP_IMAGE_UPLOAD}
        listType='picture-card'
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={onRemove}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        // multiple={true}
        disabled={disabled}
      >
        {fileList && fileList.length >= 8 ? null : uploadButton}
      </Upload>

      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt='example' style={{width: '100%'}} src={previewImage} />
      </Modal>
    </div>
  )
}
export default MultiImage
