import React, {useEffect, useState, FC} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {DatePicker, Upload, Form, Radio, Switch, message, InputNumber} from 'antd'
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons'
import {toast} from 'react-toastify'
import {getPromo, putPromo} from '../api/promo'
import {PromoModelResult} from '../interface/Promo'
import moment from 'moment'
import GoBack from '../../../components/partials/GoBack'

const {RangePicker} = DatePicker

function beforeUpload(file: any) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!')
  }
  return isJpgOrPng && isLt2M
}

export interface IPageParams {
  id: string
}

const URL = '/promo'
const EditPromo: FC = () => {
  const {id} = useParams<IPageParams>()
  const [promoIsFlat, setpromoIsFlat] = useState(true)
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [offerValue, setOfferValue] = useState<number>()
  const [form] = Form.useForm()
  const router = useHistory()

  useEffect(() => {
    getPromo(id)
      .then(({data}: PromoModelResult) => {
        const res = data.result
        console.log(res)
        res.image && setImageUrl(res.image)
        res.offer_type && setpromoIsFlat(res.offer_type === 'promo')
        res.offer_value && setOfferValue(res.offer_value)
        let range = [
          moment(new Date(res.start_date), 'DD/MM/YYYY'),
          moment(new Date(res.end_date), 'DD/MM/YYYY'),
        ]
        form.setFieldsValue({
          ...res,
          range,
          imageUrl,
          offerValue,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const onFinish = async (values: any) => {
    const data = {
      ...values,
      image: imageUrl,
      // promo_left: values.offer_value,
      start_date: values.range[0].toDate(),
      end_date: values.range[1].toDate(),
    }
    putPromo(id, data)
      .then(({data}) => {
        toast.success(data.result)
        router.push(URL)
      })
      .catch((error) => {
        toast.error(error.response.data.result)
      })
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  )

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      setImageUrl(info.file.response.url)
      setLoading(false)
    }
  }

  return (
    <div className='card shadow-sm'>
      <Form form={form} initialValues={{featured: true}} onFinish={onFinish}>
        <div className='card-header'>
          <h3 className='card-title'>Update Promo Code Details</h3>
          <div className='card-toolbar'>
            <GoBack url={URL} className='btn btn-sm btn-danger me-4' />
            <button type='submit' className='btn btn-sm btn-primary'>
              Update
            </button>
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <div className='col'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Title
              </label>
              <Form.Item name='name' rules={[{required: true, message: 'A title is required!'}]}>
                <input className='form-control form-control-solid' placeholder='Win NRs.500' />
              </Form.Item>
            </div>
            <div className='col'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Tagline
              </label>
              <Form.Item
                name='tagline'
                rules={[{required: true, message: 'A tagline is required!'}]}
              >
                <input
                  className='form-control form-control-solid'
                  placeholder='Instant off on all services'
                />
              </Form.Item>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Code
              </label>
              <Form.Item
                name='code'
                rules={[{required: true, message: 'Enter a valid coupon code!'}]}
              >
                <input className='form-control form-control-solid' placeholder='GDCHRISTMAS21' />
              </Form.Item>
            </div>
            <div className='col'>
              <label className='form-label'>Valid Period</label>
              <Form.Item name='range'>
                <RangePicker
                  className='form-control form-control-solid'
                  style={{display: 'inline-flex'}}
                  showTime
                />
              </Form.Item>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <div className='form-check form-check-custom form-check-solid mb-2'>
                <Form.Item
                  name='offer_type'
                  rules={[{required: true, message: 'Select a valid offer type!'}]}
                >
                  <Radio.Group
                    onChange={(event) => {
                      setpromoIsFlat(!promoIsFlat)
                      form.setFieldsValue({offer_type: event?.target.value})
                    }}
                  >
                    <Radio value='percent'>Percent Discount</Radio>
                    <Radio value='flat'>Flat Discount</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <Form.Item
                name='offer_value'
                rules={[
                  {required: true, message: 'The field is required!'},
                  {
                    type: 'integer',
                    min: 0,
                    message: 'Amount should be positive',
                  },
                ]}
              >
                {/* <div className='input-group'>
                  {promoIsFlat && <span className='input-group-text'>Rs.</span>} */}
                <InputNumber
                  // type='number'
                  className='form-control form-control-solid'
                  placeholder='e.g. 25'
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                  defaultValue={offerValue}
                  // onChange={(event: React.ChangeEvent<any>) => {
                  //   form.setFieldsValue({offer_value: event.target.value})
                  // }}
                />
                {/* <span className='input-group-text' id='basic-addon2'>
                    {!promoIsFlat ? '%' : '.00'}
                  </span>
                </div> */}
              </Form.Item>
            </div>
            <div className='col'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                User Limit
              </label>
              <p className='text-muted'>Mention the total times the promo code can be used.</p>
              <Form.Item
                name='user_limit'
                rules={[
                  {required: true, message: 'User limit is required!'},
                  {
                    type: 'integer',
                    min: 0,
                    message: 'User Limit should be positive',
                  },
                ]}
              >
                <InputNumber
                  // type='number'
                  className='form-control form-control-solid'
                  placeholder='e.g. 500'
                />
              </Form.Item>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Max Threshold
              </label>
              <p className='text-muted'>Enter the maximum amount that the promo code can bear.</p>
              <Form.Item
                name='max_threshold'
                rules={[
                  {required: true, message: 'Max threshold is required!'},
                  {
                    type: 'integer',
                    min: 0,
                    message: 'Max Threshold should be positive',
                  },
                ]}
              >
                <InputNumber
                  // type='number'
                  className='form-control form-control-solid'
                  placeholder='Win NRs.500'
                />
              </Form.Item>
            </div>
            <div className='col'>
              <label htmlFor='exampleFormControlInput1' className='required form-label'>
                Redeemable Upto
              </label>
              <p className='text-muted'>
                How many times can the same user apply for this promo code?
              </p>
              <Form.Item
                name='redeemable_upto'
                rules={[
                  {required: true, message: 'Redeemable upto is required!'},
                  {
                    type: 'integer',
                    min: 0,
                    message: 'Redemmable upto should be positive',
                  },
                ]}
              >
                <InputNumber
                  // type='number'
                  className='form-control form-control-solid'
                  placeholder='Win NRs.500'
                />
              </Form.Item>
            </div>
          </div>
          <div className='rounded border d-flex flex-column p-10 mb-8'>
            <label htmlFor='' className='form-label'>
              Explain what the promo code is about?
            </label>
            <Form.Item
              name='description'
              rules={[{required: true, message: 'Please add some intro to the promo code'}]}
            >
              <textarea
                className='form-control form-control form-control-solid'
                data-kt-autosize='true'
                rows={4}
              ></textarea>
            </Form.Item>
          </div>
          {/*begin::Image input*/}
          <p className='form-label'>Upload Image</p>
          <p className='text-muted'>Click on plus icon to choose an image file</p>
          <Upload
            name='file'
            listType='picture-card'
            className='avatar-uploader mb-8'
            showUploadList={false}
            action={process.env.REACT_APP_IMAGE_UPLOAD as string}
            onChange={changeAvatar}
            beforeUpload={beforeUpload}
          >
            {imageUrl ? <img src={imageUrl} alt='avatar' style={{width: '100%'}} /> : uploadButton}
          </Upload>
          {/*begin::Input group*/}
          <div className='d-flex flex-stack w-lg-50'>
            {/*begin::Label*/}
            <div className='me-5'>
              <label className='fs-6 fw-bold form-label'>Make it featured?</label>
              <div className='fs-7 fw-bold text-muted'>
                Featured promo codes will be listed in homepage.
              </div>
            </div>
            {/*end::Label*/}
            {/*begin::Switch*/}
            <label className='form-check form-switch form-check-custom form-check-solid'>
              <Form.Item name='is_featured' valuePropName='checked'>
                <Switch checkedChildren={<span>No</span>} unCheckedChildren={<span>Yes</span>} />
              </Form.Item>
            </label>
            {/*end::Switch*/}
          </div>
          {/*end::Input group*/}
        </div>
        <div className='card-footer'>
          <button className='me-4 btn-primary btn'>Update</button>
        </div>
      </Form>
    </div>
  )
}

export default EditPromo
