import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {MerchantAccountProps, profileDetailsSchema, SellerDetails} from '../../interface/account'
import {putMerchantAccountDetails} from '../../api/account'
import {toast} from 'react-toastify'
import {AutoComplete, DatePicker} from 'antd'
import moment, {Moment} from 'moment'
import {RangeValue} from 'rc-picker/lib/interface'
import ImageUpload from '../../../../../components/ImageUpload'
import Button from '../../../../../components/common/Button'
import {useHistory} from 'react-router'
import {putMerchantAccount} from '../../../../user-management/api/merchant'
import useCategory from '../../../../../components/useCategory'

const {RangePicker} = DatePicker

const ProfileDetails: React.FC<MerchantAccountProps> = ({user, isAdmin, userId}) => {
  const [loading, setLoading] = useState(false)
  const router = useHistory()
  const [category] = useCategory('2', undefined, undefined, isAdmin)
  const formik = useFormik<SellerDetails>({
    initialValues: user,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      // setLoading(true)
      // if (imageUrl) {
      //   values = { ...values, image: imageUrl }
      // }
      if (typeof values.category !== 'string') {
        values = {...values, category: values?.category._id}
      }
      if (!isAdmin) {
        putMerchantAccountDetails(values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      } else {
        putMerchantAccount(userId, values)
          .then(() => {
            setLoading(false)
            toast.success('Success')
          })
          .catch((error) => {
            setLoading(false)
            toast.error('Failed')
          })
      }
    },
  })

  // const [imageUrl, setImageUrl] = useState('')
  const [imageLoading, setImageLoading] = useState(false)

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // setImageUrl(info.file.response.url)
      formik.setFieldValue('image', info.file.response.url)
      setImageLoading(false)
    }
  }

  const handleDatePickerChange = (date: RangeValue<Moment>, dateString: [string, string]) => {
    formik.setFieldValue('holiday_mode_from', dateString[0])
    formik.setFieldValue('holiday_mode_to', dateString[1])
  }

  return (
    <form onSubmit={formik.handleSubmit} className='form'>
      <div className='card-body p-9'>
        {isAdmin && (
          <div className='row mb-7'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className='required'>Category</span>
            </label>
            <div className='col-lg-8'>
              <AutoComplete
                defaultValue={user && user?.category.name}
                options={category}
                style={{display: 'block'}}
                filterOption={(inputValue, option) => {
                  return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }}
                onSelect={(value, options) => {
                  formik.setFieldValue('category', options.id)
                }}
              >
                <input className='form-control form-control-solid' placeholder='Select Category' />
              </AutoComplete>
            </div>
          </div>
        )}

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Full Name</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('full_name')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.full_name && formik.errors.full_name,
                },
                {
                  'is-valid': formik.touched.full_name && !formik.errors.full_name,
                }
              )}
            />
            {formik.touched.full_name && formik.errors.full_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.full_name}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Email</span>
          </label>
          <div className='col-lg-8'>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              {...formik.getFieldProps('email')}
              disabled
            />
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Phone Number</span>
          </label>
          <div className='col-lg-8'>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              {...formik.getFieldProps('phone_number')}
              disabled
            />
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Display Name</span>
          </label>
          <div className='col-lg-8'>
            <input
              className='form-control form-control-lg form-control-solid'
              type='text'
              {...formik.getFieldProps('display_name')}
            />
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span className='required'>Settlement Duration</span>
          </label>
          <div className='col-lg-8'>
            <input
              type='text'
              {...formik.getFieldProps('settlement_days')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.settlement_days && formik.errors.settlement_days,
                },
                {
                  'is-valid': formik.touched.settlement_days && !formik.errors.settlement_days,
                }
              )}
            />
            {formik.touched.settlement_days && formik.errors.settlement_days && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.settlement_days}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Holiday Mode</span>
          </label>
          <div className='col-lg-8 d-flex align-items-center'>
            <div className='form-check form-check-solid form-switch fv-row'>
              <input
                className='form-check-input w-45px h-30px'
                type='checkbox'
                {...formik.getFieldProps('holiday_mode')}
                checked={formik.values.holiday_mode}
              />
              <label className='form-check-label'></label>
            </div>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Holiday Mode Range</span>
          </label>

          <div className='col-lg-8'>
            <RangePicker
              className='form-control form-control-solid'
              style={{display: 'inline-flex'}}
              showTime
              disabled={!formik.values.holiday_mode}
              onChange={(date, dateString) => handleDatePickerChange(date, dateString)}
              value={
                formik.values.holiday_mode_from &&
                formik.values.holiday_mode_to && [
                  moment(formik.values.holiday_mode_from),
                  moment(formik.values.holiday_mode_to),
                ]
              }
            />
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Description</span>
          </label>
          <div className='col-lg-8 d-flex'>
            <textarea
              className='form-control form-control form-control-solid'
              data-kt-autosize='true'
              rows={4}
              {...formik.getFieldProps('description')}
            ></textarea>
          </div>
        </div>

        <div className='row mb-7'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>
            <span>Upload Logo</span>
          </label>
          <div className='col-lg-8 d-flex'>
            <div className='form-check-solid fv-row'>
              <ImageUpload
                image={formik.values.image}
                imageLoading={imageLoading}
                // imageUrl={imageUrl}
                changeAvatar={changeAvatar}
              />
            </div>
          </div>
        </div>
        <div>
          {isAdmin && (
            <button type='button' className='btn  btn-danger me-4' onClick={() => router.goBack()}>
              Cancel
            </button>
          )}
          <Button loading={loading} name='Save Changes' />
        </div>
      </div>
    </form>
  )
}

export default ProfileDetails
