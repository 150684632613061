import React from 'react'

type ButtonProps = {
  loading: boolean
  name: string
  isNotValid?: boolean
}
const Button: React.FC<ButtonProps> = ({loading, name, isNotValid}) => {
  return (
    <button
      type='submit'
      className='btn btn-primary'
      disabled={loading || isNotValid ? isNotValid : undefined}
    >
      {!loading && name}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          Please wait...{' '}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  )
}

export default Button
