import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditProduct from './product/EditProduct'
import ListProduct from './product/ListProduct'
import ViewProduct from './product/ViewProduct'

const ProductPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/product/edit/:id'>
          <PageTitle breadcrumbs={[]}>Edit Product</PageTitle>
          <EditProduct />
        </Route>
        <Route path='/product/view/:id'>
          <PageTitle breadcrumbs={[]}>View Product</PageTitle>
          <ViewProduct />
        </Route>
        <Route path='/product/:pageNo'>
          <PageTitle breadcrumbs={[]}>List Product</PageTitle>
          <ListProduct />
        </Route>
        <Redirect from='/product' exact={true} to='/product/1' />
        <Redirect to='/product/1' />
      </Switch>
    </>
  )
}

export default ProductPage
