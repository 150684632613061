import axios from 'axios'
import {AmenitiesListModel, AmenitiesModelResult, AmenitiesModel} from '../interface/Amenities'

const API_URL = process.env.REACT_APP_API_URL

const AMENITIES_GETALL_URL = `${API_URL}/amenities/all`
const AMENITIES_REQ_URL = `${API_URL}/amenitie/`

export const getAllAmenities = async (page: string): Promise<AmenitiesListModel> => {
  console.log(AMENITIES_GETALL_URL + `?page=${page}`)
  const res = await axios.get(AMENITIES_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getAmenities = (id: string): Promise<AmenitiesModelResult> => {
  return axios.get(AMENITIES_REQ_URL + id)
}

export const putAmenities = (id: string, data: AmenitiesModel): Promise<AmenitiesModelResult> => {
  return axios.put(AMENITIES_REQ_URL + id, data)
}

export const postAmenities = (data: AmenitiesModel): Promise<AmenitiesModelResult> => {
  return axios.post(AMENITIES_REQ_URL, data)
}

export const deleteAmenities = (id: string): Promise<AmenitiesModelResult> => {
  return axios.delete(AMENITIES_REQ_URL + id)
}

export const searchAmenities = async (name: string): Promise<AmenitiesListModel> => {
  const res = await axios.get(AMENITIES_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
