import {FC, useContext, useEffect} from 'react'
import {Form, Button, Select, message} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import Attribute from './Attribute'
import {FormListFieldData} from 'antd/lib/form/FormList'
import CreateEditProductContext from '../../contexts/CreateEditProductContext'
import ProductContext from '../../contexts/StockChangeProductContext'

interface Props {
  attributes: {title: string; values: []; _id: string}[]
  form: any
  labels: string[]
}

const DynamicAttribute: FC<Props> = ({attributes, form, labels}) => {
  let fielDs: FormListFieldData[]
  const {handleStockChange} = useContext(CreateEditProductContext)
  const {disabled} = useContext(ProductContext)
  console.log(labels, form, attributes)

  return (
    <Form.List name='variation'>
      {(fields, {add, remove}) => {
        fielDs = fields
        if (labels.length === 0) {
          fielDs = []
        }
        console.log(fields)
        return (
          <>
            {fields &&
              fielDs.map((item, index) => (
                <div className='row' key={index}>
                  <div className='col'>
                    <Attribute
                      items={item}
                      index={index}
                      attributes={attributes}
                      remove={(index: any) => {
                        remove(index)
                        handleStockChange(0)
                      }}
                      form={form}
                      label={labels}
                    />
                  </div>
                </div>
              ))}
            {!disabled && (
              <Button
                type='dashed'
                onClick={() => {
                  if (labels.length) {
                    add()
                  } else {
                    message.error('Tick mark the applicable attributes first!')
                  }
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Product Attribute
              </Button>
            )}
          </>
        )
      }}
    </Form.List>
  )
}

export default DynamicAttribute
