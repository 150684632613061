/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Go Deals</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/user'
        title='User Management'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/admin/1' title='Admin' hasBullet={true} />
        <AsideMenuItem to='/merchant/1' title='Merchant' hasBullet={true} />
        <AsideMenuItem to='/customer/1' title='User' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/category'
        icon='/media/icons/duotune/art/art002.svg'
        title='Segment Management'
      />
      <AsideMenuItemWithSub
        to='/categoryy'
        title='Category Management'
        icon='/media/icons/duotune/ecommerce/ecm009.svg'
      >
        {/* <AsideMenuItem to='/category' title='Category' hasBullet={true} /> */}
        <AsideMenuItem to='/subcategory' title='Category' hasBullet={true} />
        <AsideMenuItem to='/innercategory' title='SubCategory' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/product'
        title='Product Management'
        icon='/media/icons/duotune/ecommerce/ecm004.svg'
      >
        <AsideMenuItem to='/product' title='Product' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/deal'
        title='Deal Management'
        icon='/media/icons/duotune/ecommerce/ecm005.svg'
      >
        <AsideMenuItem to='/deal' title='Deal' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/header-offer'
        title='Header Offer Management'
        icon='/media/icons/duotune/ecommerce/ecm008.svg'
      >
        <AsideMenuItem to='/header-offer' title='Header Offer' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/banner'
        title='Banner Management'
        icon='/media/icons/duotune/general/gen006.svg'
      >
        <AsideMenuItem to='/banner' title='Banner' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/brand'
        title='Brand Management'
        icon='/media/icons/duotune/general/gen028.svg'
      >
        <AsideMenuItem to='/brand' title='Brand' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/feature-ad'
        title='Feature Ad Management'
        icon='/media/icons/duotune/graphs/gra006.svg'
      >
        <AsideMenuItem to='/feature-ad' title='Feature Ad' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/promo'
        title='Promocode Management'
        icon='/media/icons/duotune/ecommerce/ecm003.svg'
      >
        <AsideMenuItem to='/promo' title='Promocode' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/settlement'
        title='Settlement Management'
        icon='/media/icons/duotune/art/art003.svg'
      >
        <AsideMenuItem to='/payout-request/new/1' title='Payout Request' hasBullet={true} />

        <AsideMenuItem to='/ledger/1' title='Ledger' hasBullet={true} />
        <AsideMenuItem to='/settlement/1' title='Settlement' hasBullet={true} />
        {/* <AsideMenuItem
          to='/payout-request'
          title='Payout Request'
          // icon='/media/icons/duotune/ecommerce/dollar.svg'
          hasBullet={true}
        /> */}
      </AsideMenuItemWithSub>

      <AsideMenuItem
        to='/commission'
        title='Commission Management'
        icon='/media/icons/duotune/ecommerce/dollar.svg'
      />
      {/* <AsideMenuItem to='/commission/categories' title='Categories' hasBullet={true} /> */}
      {/* </AsideMenuItemWithSub> */}
      <AsideMenuItemWithSub
        to='/order'
        title='Order Management'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/order' title='Order' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/amenities'
        title='Amenities Management'
        icon='/media/icons/duotune/ecommerce/ecm002.svg'
      >
        <AsideMenuItem to='/amenities' title='Amenities' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/newsletter'
        title='Newsletter Management'
        icon='/media/icons/duotune/general/gen005.svg'
      >
        <AsideMenuItem to='/newsletter' title='Newsletter' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/preference'
        title='Preference Management'
        icon='/media/icons/duotune/general/gen003.svg'
      >
        <AsideMenuItem to='/preference' title='Preference' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/notification'
        title='Notification Management'
        icon='/media/icons/duotune/general/gen007.svg'
      >
        <AsideMenuItem to='/notification' title='Notification' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/faq'
        title='Faq Management'
        icon='/media/icons/duotune/general/gen054.svg'
      >
        <AsideMenuItem to='/faq' title='Faq' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/variation'
        title='Attribute Management'
        icon='/media/icons/duotune/general/gen044.svg'
      >
        <AsideMenuItem to='/variation' title='Variation' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/location'
        title='Location Management'
        icon='/media/icons/duotune/maps/map007.svg'
      >
        <AsideMenuItem to='/location' title='Location' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/pages'
        title='Pages Management'
        icon='/media/icons/duotune/general/gen048.svg'
      >
        <AsideMenuItem to='/pages/terms-of-use' title='Terms of Use' hasBullet={true} />
        <AsideMenuItem to='/pages/privacy-statement' title='Privacy Statement' hasBullet={true} />
        <AsideMenuItem to='/pages/refund-policy' title='Refund Policy' hasBullet={true} />
        <AsideMenuItem to='/pages/shipment-policy' title='Shipment Policy' hasBullet={true} />
        <AsideMenuItem to='/pages/return-policy' title='Return Policy' hasBullet={true} />
        <AsideMenuItem to='/pages/support-help' title='Support/Help' hasBullet={true} />
        <AsideMenuItem to='/pages/about-godeals' title='About Godeals' hasBullet={true} />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub
        to='/footer'
        title='Footer Management'
        icon='/media/icons/duotune/general/gen056.svg'
      >
        <AsideMenuItem to='/footer' title='Footer' hasBullet={true} />
      </AsideMenuItemWithSub>

      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
