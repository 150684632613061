import {FC} from 'react'

interface IPageParams {
  status: string
}
const OrderStatus: FC<IPageParams> = ({status}) => {
  return (
    <div
      className={`badge badge-light-${
        status === 'DELIVERED'
          ? 'success'
          : status === 'ACKNOWLEDGED'
          ? 'primary'
          : status === 'PACKAGING'
          ? 'info'
          : status === 'SHIPPED'
          ? 'warning'
          : status === 'CANCELED'
          ? 'danger'
          : status === 'REJECTED'
          ? 'danger'
          : 'dark'
      } fw-bolder`}
    >
      {status ? status : 'Null'}
    </div>
  )
}
export default OrderStatus
