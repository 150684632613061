/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {deleteCategory, getAllCategory} from '../api/category'
import {Link, useParams} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {CategoryListModel, IFilterParams} from '../interface/Category'
import {toast} from 'react-toastify'
import Pagination from '../../../components/Pagination'
import ModalDelete from '../../../components/Modals/ModalDelete'
import Search from '../../../components/Search'
import {AutoComplete, Col, Row} from 'antd'
import handleError from '../../../components/handleError'
import Spinner from '../../../components/Spinner'
import NoData from '../../../components/common/NoData'

interface IPageParams {
  pageNo: string
}

const filterValues = {
  page: '1',
  search: '',
  parentCategoryId: '',
}

const ListCategory: FC<{level: Number}> = ({level}) => {
  const [categoryList, setCategoryList] = useState<CategoryListModel>()
  const [parentCategory, setParentCategory] = useState([{value: 'Var'}])
  const [deleteId, setDeleteId] = useState<string>('')
  const {pageNo} = useParams<IPageParams>()
  const [loading, setLoading] = useState<boolean>(true)
  const [filterParams, setFilterParams] = useState<IFilterParams>(filterValues)

  useEffect(() => {
    getCategorys(filterParams)
  }, [filterParams])

  useEffect(() => {
    setFilterParams({...filterParams, page: pageNo})
  }, [pageNo])

  function getCategorys(filterParams: IFilterParams) {
    getAllCategory(filterParams, level).then((data) => {
      setLoading(false)
      setCategoryList(data)
    })
  }
  function handleDelete(event: React.ChangeEvent<any>, id: string) {
    event.preventDefault()
    deleteCategory(id)
      .then((data) => {
        toast.success('Success!')
        getCategorys(filterParams)
      })
      .catch((error) => {
        handleError(null, error.response.data.result)
      })
  }

  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }

  // function handleSearch(event: React.ChangeEvent<any>) {
  //   event.preventDefault()
  //   const {value} = event.target
  //   setSearch(value)
  //   if (value === '') {
  //     getCategorys()
  //     return
  //   }
  //   searchCategory(search, level, parentCategoryId).then((data) => {
  //     setCategoryList(data)
  //   })
  // }

  useEffect(() => {
    level > 1 && initializeParentCategory()
  }, [])

  function initializeParentCategory() {
    getAllCategory({page: '', limit: 'MAX'}, Number(level) - 1).then(({data}) => {
      setLoading(false)
      setParentCategory([
        ...data.map((e) => {
          return {value: e.name, id: e._id}
        }),
      ])
    })
  }

  return (
    <div className='d-flex flex-column flex-column-fluid' id='kt_content'>
      {/*begin::Post*/}
      <div className='post d-flex flex-column-fluid' id='kt_post'>
        {/*begin::Container*/}
        <div className='container-xxl'>
          {/*begin::Card*/}
          <div className='card'>
            {/*begin::Card header*/}
            <div className='card-header border-0 pt-6'>
              {/*begin::Card title*/}
              <div className='card-title'>
                {/*begin::Search*/}

                <Row gutter={[16, 24]} className='mb-4'>
                  <Col className='gutter-row'>
                    <Search
                      placeholder={level === 1 ? 'segment' : 'category'}
                      name={filterParams && filterParams?.search!}
                      handleSearch={handleFilter}
                    />
                  </Col>
                  <Col className='gutter-row'>
                    {level > 1 && (
                      <AutoComplete
                        options={parentCategory}
                        style={{display: 'block'}}
                        filterOption={(inputValue, option) => {
                          return (
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          )
                        }}
                        onSelect={(value, options) => {
                          setFilterParams({
                            ...filterParams,
                            page: '1',
                            parentCategoryId: options.id,
                          })
                        }}
                      >
                        <input
                          className='form-control form-control-solid w-250px me-2'
                          placeholder='Parent Category'
                        />
                      </AutoComplete>
                    )}
                  </Col>
                </Row>
                {/*end::Search*/}
              </div>
              {/*begin::Card title*/}
              {/*begin::Card toolbar*/}
              <div className='card-toolbar'>
                {/*begin::Toolbar*/}
                <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
                  {/* <ModalAddCategory handleGetCategorys={getCategorys} level={level} /> */}
                  <Link
                    className='btn btn-primary'
                    to={`${
                      level === 1 ? '/category' : level === 2 ? '/subcategory' : '/innercategory'
                    }/add`}
                  >
                    {level === 1 ? 'Add Segment' : level === 2 ? 'Add Category' : 'Add Subcategory'}
                  </Link>
                </div>
                {/*end::Toolbar*/}
                {/*begin::Modal - Delete*/}
                <ModalDelete id={deleteId} handleDelete={handleDelete} />
                {/*end::Modal - delete*/}
              </div>
              {/*end::Card toolbar*/}
            </div>
            {/*end::Card header*/}
            {/*begin::Card body*/}
            <div className='card-body pt-0'>
              {/*begin::Table*/}
              <div className='dataTables_wrapper dt-bootstrap4 no-footer'>
                <div className='table-responsive'>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      {/*begin::Table head*/}
                      <thead>
                        {/*begin::Table row*/}
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          {/* <th
                            className='w-10px pe-2 sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '29.25px'}}
                            aria-label=''
                          >
                            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                data-kt-check='true'
                                data-kt-check-target='#kt_table_users .form-check-input'
                                defaultValue={1}
                              />
                            </div>
                          </th> */}
                          <th className='min-w-125px' style={{width: '240.65px'}}>
                            {level === 1 ? 'Segment' : level === 2 ? 'Category' : 'Subcategory'}
                          </th>
                          {level > 1 && (
                            <th className='min-w-125px' style={{width: '240.65px'}}>
                              Parent Category
                            </th>
                          )}
                          <th
                            className='min-w-125px'
                            tabIndex={0}
                            aria-controls='kt_table_users'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '134.65px'}}
                            aria-label='Role: activate to sort column ascending'
                          >
                            Description
                          </th>
                          <th
                            className='min-w-125px'
                            aria-controls='kt_table_users'
                            style={{width: '140.417px'}}
                            aria-label='Joined Date: activate to sort column ascending'
                          >
                            Created Date
                          </th>
                          <th
                            className='text-end min-w-100px sorting_disabled'
                            rowSpan={1}
                            colSpan={1}
                            style={{width: '106.433px'}}
                            aria-label='Actions'
                          >
                            Actions
                          </th>
                        </tr>
                        {/*end::Table row*/}
                      </thead>
                      {/*end::Table head*/}
                      {/*begin::Table body*/}
                      {categoryList && (
                        <tbody className='text-gray-600 fw-bold'>
                          {categoryList.data.length === 0 && <NoData />}
                          {categoryList.data.length !== 0 &&
                            categoryList.data.map((item) => {
                              return (
                                <tr className='odd' key={item._id}>
                                  {/*begin::User=*/}
                                  <td className='d-flex align-items-center'>
                                    {/*begin:: Avatar */}
                                    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                                      <Link
                                        to={`/${
                                          level === 1
                                            ? 'category'
                                            : level === 2
                                            ? 'subcategory'
                                            : 'innercategory'
                                        }/view/${item._id}`}
                                      >
                                        <div className='symbol-label fs-3 bg-light-primary text-primary'>
                                          {item.image ? (
                                            <img
                                              src={item.image}
                                              alt='banner'
                                              height='50px'
                                              width='50px'
                                            />
                                          ) : (
                                            'N'
                                          )}
                                        </div>
                                      </Link>
                                    </div>
                                    {/*end::Avatar*/}
                                    {/*begin::User details*/}
                                    <div className='d-flex flex-column'>
                                      <Link
                                        to={`/${
                                          level === 1
                                            ? 'category'
                                            : level === 2
                                            ? 'subcategory'
                                            : 'innercategory'
                                        }/view/${item._id}`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item.name}
                                      </Link>
                                      {/* <span>{item.description}</span> */}
                                    </div>
                                    {/*begin::User details*/}
                                  </td>
                                  {/*end::User=*/}
                                  {level > 1 && (
                                    <td>
                                      <Link
                                        to={`/${level === 2 ? 'category' : 'subcategory'}/view/${
                                          item?.parent?.value
                                        }`}
                                        className='text-gray-800 text-hover-primary mb-1'
                                      >
                                        {item?.parent?.label}
                                      </Link>
                                    </td>
                                  )}
                                  {/*begin::Description*/}
                                  <td>
                                    {item.description && item?.description.length > 100
                                      ? item.description?.slice(0, 100) + '...'
                                      : item.description}
                                  </td>
                                  {/*begin::Description*/}
                                  {/*begin::Joined*/}
                                  <td>{parseDate(item.createdAt)}</td>
                                  {/*begin::Joined*/}
                                  {/*begin::Action=*/}
                                  <td className='text-end'>
                                    <Link
                                      to={`/${
                                        level === 1
                                          ? 'category'
                                          : level === 2
                                          ? 'subcategory'
                                          : 'innercategory'
                                      }/edit/${item._id}`}
                                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                                    >
                                      Edit
                                    </Link>
                                    <a
                                      href='#'
                                      className='btn btn-danger btn-sm px-4'
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_delete'
                                      onClick={() => setDeleteId(item._id as string)}
                                    >
                                      Delete
                                    </a>
                                  </td>
                                  {/*end::Action=*/}
                                </tr>
                              )
                            })}
                        </tbody>
                      )}
                      {/*end::Table body*/}
                    </table>
                  )}
                </div>
                <Pagination
                  name={`${
                    level === 1 ? 'category' : level === 2 ? 'subcategory' : 'innercategory'
                  }`}
                  currentPage={filterParams.page}
                  prev_page={categoryList?.prev_page}
                  next_page={categoryList?.next_page}
                  total_number_of_page={categoryList?.total_number_of_page}
                />
              </div>
              {/*end::Table*/}
            </div>
            {/*end::Card body*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Post*/}
    </div>
  )
}

export default ListCategory
