/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {merchantRegister} from '../redux/AuthCRUD'
import {Link} from 'react-router-dom'
import {useHistory} from 'react-router'

const initialValues = {
  display_name: '',
  email: '',
  password: '',
  address: '',
  category: '',
  contact_person: '',
  contact_number: '',
  telephone_number: '',
  identification_number: '',
  changepassword: '',
  accept_terms: false,
}

const registrationSchema = Yup.object().shape({
  display_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Registered Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  address: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Address is required'),
  category: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Category is required'),
  contact_person: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Contact Person is required'),
  contact_number: Yup.string()
    .min(10, 'Minimum 10 symbols')
    .max(10, 'Maximum 10 symbols')
    .required('Contact Number is required'),
  telephone_number: Yup.string()
    .min(7, 'Minimum 7 symbols')
    .max(10, 'Maximum 10 symbols')
    .required('Telephone Number is required'),
  identification_number: Yup.string()
    .min(9, 'Minimum 9 symbols')
    .max(13, 'Maximum 13 symbols')
    .required('PAN/VAT is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  accept_terms: Yup.bool().required('You must accept the terms and conditions'),
})

export function MerchantRegistration() {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      merchantRegister(values)
        .then((data) => {
          setLoading(false)
          history.push('/merchant/login')
          // dispatch(auth.actions.login(accessToken))
        })
        .catch((error) => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Registration process has broken')
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Create an Account</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Already have an account?
          <Link to='#' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Forgot Password ?
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group RegisteredName */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-12'>
          <label className='form-label fw-bolder text-dark fs-6'>Registered Name</label>
          <input
            placeholder='Registered Name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('display_name')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.display_name && formik.errors.display_name,
              },
              {
                'is-valid': formik.touched.display_name && !formik.errors.display_name,
              }
            )}
          />
          {formik.touched.display_name && formik.errors.display_name && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.display_name}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Address */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Address</label>
        <input
          placeholder='Address'
          type='address'
          autoComplete='off'
          {...formik.getFieldProps('address')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.address && formik.errors.address},
            {
              'is-valid': formik.touched.address && !formik.errors.address,
            }
          )}
        />
        {formik.touched.address && formik.errors.address && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.address}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Address */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>PAN/VAT Number</label>
        <input
          placeholder='PAN/VAT Number'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('identification_number')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid':
                formik.touched.identification_number && formik.errors.identification_number,
            },
            {
              'is-valid':
                formik.touched.identification_number && !formik.errors.identification_number,
            }
          )}
        />
        {formik.touched.identification_number && formik.errors.identification_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.identification_number}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Category */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Category</label>
        <input
          placeholder='Category'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('category')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.category && formik.errors.category},
            {
              'is-valid': formik.touched.category && !formik.errors.category,
            }
          )}
        />
        {formik.touched.category && formik.errors.category && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.category}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group ContactPerson */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Contact Person</label>
        <input
          placeholder='Contact Person'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('contact_person')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.contact_person && formik.errors.contact_person},
            {
              'is-valid': formik.touched.contact_person && !formik.errors.contact_person,
            }
          )}
        />
        {formik.touched.contact_person && formik.errors.contact_person && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contact_person}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group ContactNumber */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Contact Number</label>
        <input
          placeholder='Contact Number'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('contact_number')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.contact_number && formik.errors.contact_number},
            {
              'is-valid': formik.touched.contact_number && !formik.errors.contact_number,
            }
          )}
        />
        {formik.touched.contact_number && formik.errors.contact_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.contact_number}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group ContactNumber */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Telephone Number</label>
        <input
          placeholder='Telephone Number'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('telephone_number')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.telephone_number && formik.errors.telephone_number},
            {
              'is-valid': formik.touched.telephone_number && !formik.errors.telephone_number,
            }
          )}
        />
        {formik.touched.telephone_number && formik.errors.telephone_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.telephone_number}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('accept_terms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            I Agree the{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              terms and conditions
            </Link>
            .
          </label>
          {formik.touched.accept_terms && formik.errors.accept_terms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.accept_terms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.accept_terms}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/merchant/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Login
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
