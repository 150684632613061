import {useEffect, useState} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import {AutoComplete, Form, InputNumber} from 'antd'
import Button from '../../../../../components/common/Button'
import {
  getProduct,
  postProductStockInWithoutVariation,
  postProductStockInWithVariation,
} from '../../api/product'
import {toast} from 'react-toastify'
import ProductContext from '../../contexts/StockChangeProductContext'
import GoBack from '../../../../../components/partials/GoBack'
import VariationForm from './VariationForm'

const URL = '/product/stock'
const StockChange = () => {
  const router = useHistory()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState<boolean>(false)
  const [variations, setVariations] = useState<any>([])
  const location = useLocation<any>()
  const [stock, setStock] = useState<number>(0)

  useEffect(() => {
    if (!location?.state?.id) {
      router.push(URL)
    }
    getProduct(location.state.id, 'true')
      .then(({data}) => {
        const result = data.result
        if (data?.result?.variation) {
          setVariations(data.result.variation)
        }
        // setSelectedOption(result.categoryId)
        result.categoryId = result.categoryId.name
        result.stock = 0
        form.setFieldsValue({
          ...result,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  const onFinish = (values: any) => {
    const data =
      values?.variation &&
      values.variation.map((e: any, index: number) => {
        return {
          quantity: e.stock || 0,
          variation_index: index,
        }
      })
    if (values?.variation && values.variation.length > 0) {
      postProductStockInWithVariation({product_id: location.state.id, data})
        .then((data) => {
          router.push(URL)
          toast.success(data)
        })
        .catch((err) => toast.error('Error Occured while updating stock'))
    } else {
      postProductStockInWithoutVariation({product_id: location.state.id, stock: values.stock})
        .then((data) => {
          router.push(URL)
          toast.success(data)
        })
        .catch((err) => {
          console.log(err)
          toast.error('Error Occured while updating stock')
        })
    }
  }

  return (
    <ProductContext.Provider
      value={{
        disabled: true,
      }}
    >
      <Form form={form} onFinish={onFinish}>
        <div className='card shadow-sm'>
          <div className='card-header'>
            <h3 className='card-title'>Add Stock</h3>
            <div className='card-toolbar'>
              <GoBack url={URL} className='btn btn-sm btn-danger me-4' />
              <button type='submit' className='btn btn-sm btn-primary'>
                Save
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <label htmlFor='name' className='required form-label'>
                  Name
                </label>
                <Form.Item name='name' rules={[{required: true, message: 'Name is required!'}]}>
                  <input
                    type='text'
                    disabled
                    className='form-control form-control-lg form-control-solid'
                    autoComplete='off'
                    // {...formik.getFieldProps('name')}
                    // className={clsx(
                    //   'form-control form-control-lg form-control-solid',
                    //   {
                    //     'is-invalid': formik.touched.name && formik.errors.name,
                    //   },
                    //   {
                    //     'is-valid': formik.touched.name && !formik.errors.name,
                    //   }
                    // )}
                  />
                </Form.Item>
              </div>
              <div className='col'>
                <label htmlFor='actual_price' className='required form-label'>
                  Original Price
                </label>
                <Form.Item
                  name='actual_price'
                  rules={[{required: true, message: 'Price is required!'}]}
                >
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    autoComplete='off'
                    disabled
                    // {...formik.getFieldProps('actual_price')}
                    // className={clsx(
                    //   'form-control form-control-lg form-control-solid',
                    //   {
                    //     'is-invalid': formik.touched.actual_price && formik.errors.actual_price,
                    //   },
                    //   {
                    //     'is-valid': formik.touched.actual_price && !formik.errors.actual_price,
                    //   }
                    // )}
                  />
                </Form.Item>
              </div>
            </div>
            <div className='row mb-8'>
              <div className='col'>
                <label htmlFor='categoryId' className='required form-label'>
                  Category Name
                </label>
                <Form.Item
                  name='categoryId'
                  rules={[{required: true, message: 'Category Name is required'}]}
                >
                  <AutoComplete
                    options={[]}
                    style={{display: 'block'}}
                    filterOption={(inputValue, option) => {
                      return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }}
                    onSelect={(value, option) => {}}
                    disabled
                  >
                    <input className='form-control form-control-solid' placeholder='Select' />
                  </AutoComplete>
                </Form.Item>
              </div>

              {variations && variations.length === 0 && (
                <div className='col'>
                  <label htmlFor='stock' className='form-label'>
                    Stock
                  </label>
                  <Form.Item
                    name='stock'
                    rules={[
                      {
                        type: 'integer',
                        min: 0,
                        message: 'Stock should be positive',
                      },
                    ]}
                  >
                    <InputNumber
                      size='small'
                      type='number'
                      className='form-control form-control-solid form-control-lg'
                      autoComplete='off'
                    />
                  </Form.Item>
                </div>
              )}
            </div>
            {variations && variations.length > 0 && (
              <VariationForm form={form} variations={variations} />
            )}
            <div className='rounded border d-flex flex-column p-10 mb-8 mt-8'>
              <label htmlFor='' className='form-label'>
                Description
              </label>
              <Form.Item
                name='description'
                rules={[{required: true, message: 'Description is required!'}]}
              >
                <textarea
                  className='form-control form-control form-control-solid'
                  data-kt-autosize='true'
                  disabled
                  rows={4}
                  // {...formik.getFieldProps('description')}
                ></textarea>
              </Form.Item>
            </div>
            {/*begin::Image input*/}
          </div>
          <div className='card-footer'>
            <Button loading={loading} name='Save' />
          </div>
        </div>
      </Form>
    </ProductContext.Provider>
  )
}

export default StockChange
