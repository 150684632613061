import {FC, useState} from 'react'
import clsx from 'clsx'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {postHeaderOffer} from '../api/header-offer'
import {toast} from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from '../../../components/common/Button'
import {getValidUrl} from '../../../../utils'
// import './style.css'

const headerSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  callback: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'The url must be valid.'
    )
    .required('Callback is required'),
  callback_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Callback Name is required'),
})

interface ModalProps {
  handleGetHeaderOffer: () => void
}

const ModalAddHeader: FC<ModalProps> = (props) => {
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const toogleModal = () => {
    setIsOpen(!isOpen)
  }

  const initialValues = {
    name: '',
    callback: '',
    callback_name: '',
    is_featured: false,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: headerSchema,
    validateOnMount: true,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      postHeaderOffer({...values, callback: getValidUrl(values.callback)})
        .then(() => {
          setLoading(false)
          toast.success('Success!')
          resetForm({})
          props.handleGetHeaderOffer()
          toogleModal()
        })
        .catch((error) => {
          console.log(error)
          setLoading(false)
          setSubmitting(false)
          setStatus('Failed')
        })
    },
  })
  return (
    <>
      <button
        type='button'
        className='btn btn-primary'
        // data-bs-toggle='modal'
        // data-bs-target='#kt_modal_add_header'
        onClick={() => {
          toogleModal()
        }}
      >
        {/*begin::Svg Icon | path: icons/duotune/arrows/arr075.svg*/}
        <span className='svg-icon svg-icon-2'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
          >
            <rect
              opacity='0.5'
              x='11.364'
              y='20.364'
              width={16}
              height={2}
              rx={1}
              transform='rotate(-90 11.364 20.364)'
              fill='black'
            />
            <rect x='4.36396' y='11.364' width={16} height={2} rx={1} fill='black' />
          </svg>
        </span>
        {/*end::Svg Icon*/}Add Header Offer
      </button>
      {/*begin::Add header*/}
      <Modal
        show={isOpen}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        dialogClassName='mw-650px'
        // style={{
        //   height: '70%',
        // }}
      >
        {/*begin::Modal header*/}
        <div className='modal-header'>
          {/*begin::Modal title*/}
          <h2 className='fw-bolder'>Add Header Offer</h2>
          {/*end::Modal title*/}
          {/*begin::Close*/}
          <div
            className='btn btn-icon btn-sm btn-active-icon-primary'
            data-kt-users-modal-action='close'
            onClick={toogleModal}
          >
            {/*begin::Svg Icon | path: icons/duotune/arrows/arr061.svg*/}
            <span className='svg-icon svg-icon-1'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width={24}
                height={24}
                viewBox='0 0 24 24'
                fill='none'
              >
                <rect
                  opacity='0.5'
                  x={6}
                  y='17.3137'
                  width={16}
                  height={2}
                  rx={1}
                  transform='rotate(-45 6 17.3137)'
                  fill='black'
                />
                <rect
                  x='7.41422'
                  y={6}
                  width={16}
                  height={2}
                  rx={1}
                  transform='rotate(45 7.41422 6)'
                  fill='black'
                />
              </svg>
            </span>
            {/*end::Svg Icon*/}
          </div>
          {/*end::Close*/}
        </div>
        {/*end::Modal header*/}
        {/*begin::Modal body*/}
        <div className='modal-body mx-5 mx-xl-15 my-7'>
          {/*begin::Form*/}
          <form
            className='form fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_header_form'
            onSubmit={formik.handleSubmit}
          >
            {formik.status && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}
            {/*begin::Scroll*/}
            <div
              className='d-flex flex-column me-n7 pe-7'
              id='kt_modal_add_user_scroll'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: false, lg: true}'
              data-kt-scroll-max-height='auto'
              data-kt-scroll-offset='300px'
              style={{maxHeight: '411px'}}
            >
              {/* begin::Form group Name */}
              <div className='row fv-row mb-7'>
                <div className='col-xl-12'>
                  <label className='form-label fw-bolder text-dark fs-6'>Name</label>
                  <input
                    type='text'
                    autoComplete='off'
                    {...formik.getFieldProps('name')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.name && formik.errors.name,
                      },
                      {
                        'is-valid': formik.touched.name && !formik.errors.name,
                      }
                    )}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.name}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* end::Form group */}

              {/* begin::Form group Callback */}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Callback</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('callback')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.callback && formik.errors.callback,
                    },
                    {
                      'is-valid': formik.touched.callback && !formik.errors.callback,
                    }
                  )}
                />
                {formik.touched.callback && formik.errors.callback && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.callback}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group Callback_name */}
              <div className='fv-row mb-7'>
                <label className='form-label fw-bolder text-dark fs-6'>Callback Name</label>
                <input
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('callback_name')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.callback_name && formik.errors.callback_name,
                    },
                    {
                      'is-valid': formik.touched.callback_name && !formik.errors.callback_name,
                    }
                  )}
                />
                {formik.touched.callback_name && formik.errors.callback_name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.callback_name}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}

              {/* begin::Form group Is Featured */}
              <div className='fv-row mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Featured</label>
                <div className='col-lg-8 d-flex align-items-center'>
                  <div className='form-check form-check-solid form-switch fv-row'>
                    <input
                      className='form-check-input w-45px h-30px'
                      type='checkbox'
                      {...formik.getFieldProps('is_featured')}
                    />
                    <label className='form-check-label'></label>
                  </div>
                </div>
              </div>
              {/* end::Form group */}

              {/*begin::Actions*/}
              <div className='text-center pt-0'>
                <button type='reset' className='btn btn-light me-3' onClick={toogleModal}>
                  Cancel
                </button>
                <Button
                  loading={loading}
                  name={'Submit'}
                  isNotValid={formik.isSubmitting || !formik.isValid}
                />
              </div>
              {/*end::Actions*/}
            </div>
          </form>
          {/*end::Form*/}
        </div>
        {/*end::Modal body*/}
      </Modal>
      {/*end::Add header*/}
    </>
  )
}

export default ModalAddHeader
