import clsx from 'clsx'
import {useFormik} from 'formik'
import {useState} from 'react'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import Select from 'react-select'
import Button from '../../../components/common/Button'
import ImageUpload from '../../../components/ImageUpload'
import useCategory from '../../../components/useCategory'
import {postMerchant} from '../api/merchant'
import {selectStyle} from '../../../components/Select'

const initialValues = {
  display_name: '',
  phone_number: '',
  email: '',
  password: '',
  cpassword: '',
  image: '',
  category: '',
}

const merchantSchema = Yup.object().shape({
  display_name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Display/Shop Name is required'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
  image: Yup.string().required('Image is required'),
  phone_number: Yup.string()
    .min(10, 'Minimum 10 symbols')
    .max(10, 'Maximum 10 symbols')
    .required('Phone Number is required'),
  category: Yup.string().required('Category is required'),
  password: Yup.string()
    .min(6, 'Minimum 6 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  cpassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export default function CreateMerchant() {
  const [loading, setLoading] = useState(false)
  const router = useHistory()
  // const [image, setImage] = useState('')
  const [imageLoading, setImageLoading] = useState(false)
  const [category, setCategory] = useCategory('1', 'react-select')
  const [error, setError] = useState<any>()

  const formik = useFormik({
    initialValues,
    validationSchema: merchantSchema,
    validateOnMount: true,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      // if (image) {
      //   values = { ...values, image }
      // }
      postMerchant(values)
        .then(() => {
          setLoading(false)
          toast.success('Success!')
          resetForm({})
          router.push('/merchant/')
        })
        .catch((error) => {
          // formik.setErrors
          setLoading(false)
          setSubmitting(false)
          const err = error.response?.data.result
          if (Array.isArray(err)) {
            setError(err)
          } else if (err) {
            setError([{msg: err}])
          }
        })
    },
  })

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // setImage(info.file.response.url)
      formik.setFieldValue('image', info.file.response.url)
      setImageLoading(false)
    }
  }
  return (
    <form onSubmit={formik.handleSubmit} className='form'>
      <div className='card shadow-sm'>
        <div className='card-header'>
          <h3 className='card-title'>Add Merchant Details</h3>
          <div className='card-toolbar'>
            <button
              type='submit'
              className='btn btn-sm btn-danger me-4'
              onClick={() => router.goBack()}
            >
              Cancel
            </button>
            <button type='button' className='btn btn-sm btn-primary'>
              Create
            </button>
          </div>
        </div>
        <div className='card-body'>
          {error ? (
            <div className='mb-lg-15 alert alert-danger'>
              {error.map((e: any) => (
                <div className='alert-text font-weight-bold'>{e.msg}</div>
              ))}
            </div>
          ) : (
            ''
          )}
          <div className='row mb-8'>
            <div className='col'>
              <label htmlFor='email' className='required form-label'>
                Email
              </label>
              <input
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.email && formik.errors.email,
                  },
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
              />
            </div>
            <div className='col'>
              <label htmlFor='phone_number' className='required form-label'>
                Phone Number
              </label>
              <input
                {...formik.getFieldProps('phone_number')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.phone_number && formik.errors.phone_number,
                  },
                  {
                    'is-valid': formik.touched.phone_number && !formik.errors.phone_number,
                  }
                )}
              />
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col'>
              <label htmlFor='display_name' className='required form-label'>
                Shop/Display Name
              </label>
              <input
                {...formik.getFieldProps('display_name')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.display_name && formik.errors.display_name,
                  },
                  {
                    'is-valid': formik.touched.display_name && !formik.errors.display_name,
                  }
                )}
              />
            </div>

            <div className='col'>
              <label htmlFor='display_name' className='required form-label'>
                Category
              </label>
              <Select
                options={category}
                name='category'
                onChange={(event: any) => {
                  formik.setFieldValue('category', event.value)
                }}
                styles={selectStyle}
                isClearable
              />
            </div>
          </div>
          <div className='row mb-8'>
            <div className='col'>
              <label htmlFor='password' className='required form-label'>
                Password
              </label>
              <input
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                name='password'
                type='password'
              />
            </div>
            <div className='col'>
              <label htmlFor='confirm_password' className='required form-label'>
                Confirm Password
              </label>
              <input
                {...formik.getFieldProps('cpassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.cpassword && formik.errors.cpassword,
                  },
                  {
                    'is-valid': formik.touched.cpassword && !formik.errors.cpassword,
                  }
                )}
                name='cpassword'
                type='password'
              />
            </div>
          </div>
          <div className='col'>
            <label htmlFor='image'>Image</label>

            {formik.touched.image && formik.errors.image && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.image}</span>
                </div>
              </div>
            )}
            <ImageUpload
              {...formik.getFieldProps('cpassword')}
              image={formik.values.image}
              // imageUrl={formik.values.image}
              imageLoading={imageLoading}
              changeAvatar={changeAvatar}
            />
          </div>
        </div>
        <div className='card-footer'>
          <Button loading={loading} name={'Create'} />
        </div>
      </div>
    </form>
  )
}
