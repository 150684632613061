/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {MerchantModel} from '../../auth/models/UserModel'
import {getMerchant} from '../api/merchant'
import General from '../../merchant/account-management/General'

export interface IPageParams {
  id: string
}
const EditMerchant: FC = (props) => {
  const {id} = useParams<IPageParams>()
  const [user, setUser] = useState<MerchantModel>()
  useEffect(() => {
    getMerchant(id).then(({data}) => {
      setUser(data.result)
    })
  }, [id])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit Merchant</PageTitle>

      {user && (
        <div className='card pt-4 mb-6 mb-xl-9'>
          {/*begin::Card body*/}
          <div className='card-body pt-0 pb-5'>
            {/*begin::Table wrapper*/}
            <div className='table-responsive'>
              {/*begin::Table*/}
              <table
                className='table align-middle table-row-dashed gy-5'
                id='kt_table_users_login_session'
              >
                {/*begin::Table body*/}
                <General user={user} isAdmin={true} />
                {/*end::Table body*/}
              </table>
              {/*end::Table*/}
            </div>
            {/*end::Table wrapper*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
    </>
  )
}

export default EditMerchant
