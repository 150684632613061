import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditBrand from './brand/EditBrand'
import ListBrand from './brand/ListBrand'
import ViewBrand from './brand/ViewBrand'

const BrandPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/brand/edit/:id'>
          <PageTitle breadcrumbs={[]}>Brand Edit</PageTitle>
          <EditBrand />
        </Route>
        <Route path='/brand/view/:id'>
          <PageTitle breadcrumbs={[]}>Brand View</PageTitle>
          <ViewBrand />
        </Route>
        <Route path='/brand/:page'>
          <PageTitle breadcrumbs={[]}>Brand List</PageTitle>
          <ListBrand />
        </Route>
        <Redirect from='/brand' exact={true} to='/brand/1' />
        <Redirect to='/brand/1' />
      </Switch>
    </>
  )
}

export default BrandPage
