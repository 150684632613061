/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useHistory} from 'react-router'
import {AutoComplete, Form, Input, InputNumber, Switch} from 'antd'
import {useParams} from 'react-router-dom'
import TextArea from 'antd/lib/input/TextArea'
import {toast} from 'react-toastify'
import {Select} from 'antd'
import {CategoryModel} from '../interface/Category'
import ImageUpload from '../../../components/ImageUpload'
import handleError from '../../../components/handleError'
import {getAllVariation} from '../../variation-management/api/variation'
import {VariationModel} from '../../variation-management/interface/IVariation'
import {getAllCategory, getCategory, postCategory, putCategory} from '../../category/api/category'
import {faIcons} from '../../../../utils/icons'
import './select.css'

interface IPageParams {
  id: string
}
interface Props {
  level: number
}

const {Option} = Select

const CreateEditCategory: FC<Props> = ({level}) => {
  const history = useHistory()
  const [form] = Form.useForm()

  const {id} = useParams<IPageParams>()
  const isCreateMode = !id

  const [loading, setLoading] = useState<boolean>(false)
  const [options, setOptions] = useState([{value: 'Var'}, {value: 'Per'}])
  const [selectedIcon, setSelectedIcon] = useState('')
  const [selectedParentCategory, setSelectedParentCategory] = useState({value: '', label: ''})
  const [hintIconData, setHintIconData] = useState(
    faIcons.map((e) => {
      return {
        value: e,
        label: (
          <div className='row'>
            <div className='col-2'>
              <i className={`fas fa-${e}`} style={{fontSize: '24px'}} />
            </div>
            <div className='col-10'>
              <span>&nbsp;{e}</span>
            </div>
          </div>
        ),
      }
    })
  )
  const [imageLoading, setImageLoading] = useState(false)
  const [hintVariationData, setHintVariationData] = useState<Array<VariationModel>>()
  const [isFeatured, setIsFeatured] = useState<boolean>(false)

  useEffect(() => {
    getAllVariation('')
      .then(({data}) => {
        setHintVariationData(data)
      })
      .catch((err) => {
        toast.error('Error Loading Variation')
      })
  }, [])

  function changeAvatar(info: any) {
    if (info.file.status === 'uploading') {
      setImageLoading(true)
      return
    }
    if (info.file.status === 'done') {
      form.setFieldsValue({image: info.file.response.url})
      setImageLoading(false)
    }
  }

  function onFinish(values: CategoryModel) {
    setLoading(true)
    let newValues
    if (selectedParentCategory?.value && selectedParentCategory?.label) {
      newValues = {
        ...values,
        parent: selectedParentCategory,
        level: level,
      }
    } else {
      newValues = {...values, level}
    }
    const redirectUrl = level === 1 ? '/category' : level === 2 ? '/subcategory' : '/innercategory'
    if (!isCreateMode) {
      putCategory(id, newValues)
        .then(() => {
          setLoading(false)
          history.push(redirectUrl)
        })
        .catch((error) => {
          setLoading(false)
          handleError(form, error.response.data.result)
        })
    } else {
      postCategory(newValues)
        .then(() => {
          setLoading(false)
          history.push(redirectUrl)
        })
        .catch((error) => {
          setLoading(false)
          handleError(form, error.response.data.result)
        })
    }
  }

  useEffect(() => {
    if (!isCreateMode) {
      getCategory(id)
        .then(({data}) => {
          const result = data.result
          result?.parent && setSelectedParentCategory(result?.parent)
          result.parent = result?.parent?.label
          setIsFeatured(result.is_featured)
          form.setFieldsValue({
            ...result,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
    getAllCategory({page: ''}, level - 1)
      .then(({data}) => {
        setOptions([
          ...data.map((e) => ({
            label: e.name,
            value: e.name,
            id: e._id,
            commission_percent: e.commission_percent,
          })),
        ])
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <>
      <div className='card shadow-sm'>
        <div className='card-header'>
          <h3 className='card-title'>
            {level === 1 ? 'Segment' : level === 2 ? 'Category' : 'Subcategory'} Details
          </h3>
        </div>
        <Form form={form} onFinish={onFinish}>
          <div className='card-body'>
            {level > 1 && (
              <div className='row'>
                <div className='col'>
                  <label className='required form-label'>
                    Parent {level === 1 ? 'Segment' : level === 2 ? 'Category' : 'Subcategory'}
                  </label>
                  <Form.Item
                    name='parent'
                    rules={[{required: true, message: 'A parent category is required!'}]}
                  >
                    <AutoComplete
                      options={options}
                      style={{display: 'block'}}
                      filterOption={(inputValue, option) => {
                        return option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }}
                      onSelect={(value, option) => {
                        form.setFieldsValue({commission_percent: option.commission_percent})
                        setSelectedParentCategory({
                          label: value,
                          value: option.id,
                        })
                      }}
                    >
                      <input className='form-control form-control-solid' placeholder='Select' />
                    </AutoComplete>
                  </Form.Item>
                </div>
              </div>
            )}
            <div className='row'>
              <div className='col'>
                <label className='required form-label'>Name</label>
                <Form.Item name='name' rules={[{required: true, message: 'A name is required!'}]}>
                  <Input className='form-control form-control-solid' placeholder='Name' />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className='form-label'>Commission Percent</label>
                <Form.Item
                  name='commission_percent'
                  rules={[
                    {
                      type: 'number',
                      message: 'Commission Percent must be number',
                    },
                  ]}
                >
                  <InputNumber className='form-control form-control-solid' placeholder='10' />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className='required form-label'>Description</label>
                <Form.Item
                  name='description'
                  rules={[{required: true, message: 'A description is required!'}]}
                >
                  <TextArea
                    className='form-control form-control-solid'
                    rows={6}
                    placeholder='Description.........'
                  />
                </Form.Item>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <label className='form-label'>Show on Mega Menu</label>
                <Form.Item name='show_on_mega_menu' valuePropName='checked'>
                  <Switch checkedChildren={<span>No</span>} unCheckedChildren={<span>Yes</span>} />
                </Form.Item>
              </div>
            </div>
            {level === 3 && (
              <div className='row'>
                <div className='col'>
                  <label className='form-label'>Featured</label>
                  <Form.Item name='is_featured' valuePropName='checked'>
                    <Switch
                      checkedChildren={<span>No</span>}
                      unCheckedChildren={<span>Yes</span>}
                      onChange={() => setIsFeatured(!isFeatured)}
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            {isFeatured && (
              <>
                <div className='row'>
                  <div className='col'>
                    <label className='required form-label'>Callback</label>
                    <Form.Item
                      name='callback'
                      rules={[
                        isFeatured && {required: true, message: 'This field is required'},
                        {
                          type: 'url',
                          message: 'This field must be a valid url.',
                        },
                      ]}
                    >
                      <Input
                        className='form-control form-control-solid'
                        placeholder='https://www.godeals.com.np/category'
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <label className='required form-label'>Icon</label>
                    {selectedIcon && (
                      <i className={`fas fa-${selectedIcon} ms-5`} style={{fontSize: '2rem'}} />
                    )}
                    <Form.Item
                      name='icon'
                      rules={[isFeatured && {required: true, message: 'This field is required'}]}
                    >
                      {/* <AutoComplete
                        allowClear={true}
                        options={hintIconData}
                        style={{display: 'block'}}
                        filterOption={(inputValue, option) => {
                          return (
                            option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          )
                        }}
                        onSelect={(value, option) => {
                          setSelectedIcon(value)
                        }}
                      >
                        <Input
                          className='form-control form-control-solid'
                          placeholder='Please select icon'
                        />
                      </AutoComplete> */}

                      <Select
                        showSearch
                        allowClear
                        placeholder='Select a product'
                        onSelect={(value, option) => {
                          setSelectedIcon(option.value)
                        }}
                        filterOption={(input, option) =>
                          option!.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        size='large'
                        style={{
                          background: '#f5f8fa',
                        }}
                        className='ant-select-selection ant-select-selector'
                      >
                        {hintIconData.map((e, idx) => (
                          <Select.Option key={idx} value={e.value}>
                            {e.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}

            <ImageUpload
              image={form.getFieldValue('image')}
              // imageUrl={imageUrl}
              imageLoading={imageLoading}
              changeAvatar={changeAvatar}
            />
            {level === 3 && (
              <>
                <label className='form-label'>Select Variation</label>
                <Form.Item name='variation_id'>
                  <Select
                    showSearch
                    allowClear
                    size='large'
                    mode='multiple'
                    style={{width: '100%'}}
                    placeholder='Select Variation'
                    filterOption={(input, option) =>
                      option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {hintVariationData &&
                      hintVariationData.map((item: any) => {
                        return (
                          <Option key={item.title} value={item._id}>
                            {item.title}
                          </Option>
                        )
                      })}
                  </Select>
                </Form.Item>
              </>
            )}
          </div>
          <div className='card-footer'>
            <button type='button' className='btn btn-danger me-4' onClick={() => history.goBack()}>
              Cancel
            </button>
            <button className='me-4 btn-primary btn'>{!isCreateMode ? 'Edit' : 'Create'}</button>
          </div>
        </Form>
      </div>
    </>
  )
}

export default CreateEditCategory
