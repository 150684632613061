/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Link} from 'react-router-dom'

interface PaginateProps {
  name: string
  prev_page?: number
  next_page?: number
  total_number_of_page?: number
  currentPage?: string
  pageNeighbours?: number
}
const Pagination: FC<PaginateProps> = ({
  name,
  prev_page,
  next_page,
  total_number_of_page,
  currentPage,
  pageNeighbours = 4,
}) => {
  const getPagination = (no_of_page: number, pageNeighbours: number) => {
    const pg = []
    let page = Number(currentPage)
    for (var j = 1; j <= no_of_page; j++) {
      pg.push(
        <li key={j} className={`paginate_button page-item ${j === page ? 'active' : ''}`}>
          <Link
            to={`/${name}/${j}`}
            aria-controls='kt_table_users'
            data-dt-idx={j}
            className='page-link'
          >
            {j}
          </Link>
        </li>
      )
    }
    return pg
  }
  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div className='dataTables_paginate paging_simple_numbers' id='kt_table_users_paginate'>
          <ul className='pagination'>
            <li
              className={`paginate_button page-item previous ${prev_page ? '' : 'disabled'}`}
              id='kt_table_users_previous'
            >
              <Link
                to={prev_page ? `/${name}/${prev_page}` : '#'}
                aria-controls='kt_table_users'
                data-dt-idx={0}
                className='page-link'
              >
                <i className='previous' />
              </Link>
            </li>
            {total_number_of_page && getPagination(total_number_of_page, pageNeighbours)}
            <li
              className={`paginate_button page-item next ${next_page ? '' : 'disabled'}`}
              id='kt_table_users_next'
            >
              <Link
                to={next_page ? `/${name}/${next_page}` : '#'}
                aria-controls='kt_table_users'
                className='page-link'
                data-dt-idx={`${total_number_of_page} + 1`}
              >
                <i className='next' />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Pagination
