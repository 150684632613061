import axios from 'axios'
import {VariationListModel, VariationModelResult, VariationModel} from '../interface/IVariation'

const API_URL = process.env.REACT_APP_API_URL

const VARIATION_GETALL_URL = `${API_URL}/variations/`
const VARIATION_REQ_URL = `${API_URL}/variation/`

export const getAllVariation = async (page: string): Promise<VariationListModel> => {
  const res = await axios.get(VARIATION_GETALL_URL + `?page=${page}`)
  return res.data.result
}

export const getVariation = (id: string): Promise<VariationModelResult> => {
  return axios.get(VARIATION_REQ_URL + id)
}

export const putVariation = (id: string, data: VariationModel): Promise<VariationModelResult> => {
  return axios.put(VARIATION_REQ_URL + id, data)
}

export const postVariation = (data: VariationModel): Promise<VariationModelResult> => {
  return axios.post(VARIATION_REQ_URL, data)
}

export const deleteVariation = (id: string): Promise<VariationModelResult> => {
  return axios.delete(VARIATION_REQ_URL + id)
}

export const searchVariation = async (name: string): Promise<VariationListModel> => {
  const res = await axios.get(VARIATION_GETALL_URL + `?search_text=${name}`)
  return res.data.result
}
