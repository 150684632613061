import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import CategoryPage from '../modules/category/CategoryPage'
import SubCategoryPage from '../modules/category/SubCategoryPage'
import InnerCategory from '../modules/category/SubCategoryPage2'
import HeaderOfferPage from '../modules/header-offer-management/HeaderOfferPage'
import BannerPage from '../modules/banner-management/BannerPage'
import ProductPage from '../modules/product/ProductPage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import FeatureAdPage from '../modules/feature-ad-management/FeatureAdPage'
import PromoPage from '../modules/promo-management/PromoPage'
import CommissionPage from '../modules/commission-management/CommissionPage'
import OrderPage from '../modules/order-management/OrderPage'
import BrandPage from '../modules/brand-management/BrandPage'
import AmenitiesPage from '../modules/amenities-management/AmenitiesPage'
import MerchantPage from '../modules/user-management/MerchantPage'
import DealPage from '../modules/deal-management/DealPage'
import NewsletterPage from '../modules/newsletter-management/NewsletterPage'
import FaqPage from '../modules/faq/FaqPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ListAdmin = lazy(() => import('../modules/user-management/admin/ListAdmin'))
  const ViewAdmin = lazy(() => import('../modules/user-management/admin/ViewAdmin'))
  const EditAdmin = lazy(() => import('../modules/user-management/admin/EditAdmin'))
  const ListCustomer = lazy(() => import('../modules/user-management/customer/ListCustomer'))
  const ViewCustomer = lazy(() => import('../modules/user-management/customer/ViewCustomer'))
  const EditCustomer = lazy(() => import('../modules/user-management/customer/EditCustomer'))
  const PreferencePage = lazy(() => import('../modules/preferences/PreferencePage'))
  const FooterPage = lazy(() => import('../modules/footer-management/FooterPage'))
  const VariationPage = lazy(() => import('../modules/variation-management/VariationPage'))
  const SettlementPage = lazy(() => import('../modules/settlement/SettlementPage'))
  const PayoutRequestPage = lazy(() => import('../modules/payout-request/PayoutRequestPage'))
  const NotificationPage = lazy(() => import('../modules/notification/NotificationPage'))
  const LedgerPage = lazy(() => import('../modules/ledger/LedgerPage'))
  const ContentPage = lazy(() => import('../modules/page-management/Page'))
  const LocationPage = lazy(() => import('../modules/location-management/Location'))

  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/admin/edit/:id' component={EditAdmin} />
        <Route path='/admin/view/:id' component={ViewAdmin} />
        <Route path='/admin/:page' component={ListAdmin} />

        <Route path='/merchant/' component={MerchantPage} />

        <Route path='/customer/edit/:id' component={EditCustomer} />
        <Route path='/customer/view/:id' component={ViewCustomer} />
        <Route path='/customer/:page' component={ListCustomer} />

        <Route path='/product/' component={ProductPage} />
        <Route path='/category/' component={CategoryPage} />
        <Route path='/subcategory/' component={SubCategoryPage} />
        <Route path='/innercategory/' component={InnerCategory} />

        <Route path='/header-offer/' component={HeaderOfferPage} />
        <Route path='/banner/' component={BannerPage} />
        <Route path='/feature-ad/' component={FeatureAdPage} />
        <Route path='/promo/' component={PromoPage} />
        <Route path='/commission/' component={CommissionPage} />
        <Route path='/order/' component={OrderPage} />
        <Route path='/brand/' component={BrandPage} />
        <Route path='/amenities/' component={AmenitiesPage} />
        <Route path='/deal/' component={DealPage} />
        <Route path='/newsletter/' component={NewsletterPage} />
        <Route path='/preference/' component={PreferencePage} />
        <Route path='/footer/' component={FooterPage} />
        <Route path='/variation/' component={VariationPage} />
        <Route path='/settlement/' component={SettlementPage} />
        <Route path='/payout-request/' component={PayoutRequestPage} />
        <Route path='/notification/' component={NotificationPage} />
        <Route path='/ledger/' component={LedgerPage} />
        <Route path='/faq/' component={FaqPage} />
        <Route path='/pages/' component={ContentPage} />
        <Route path='/location/' component={LocationPage} />

        <Route path='/builder' component={BuilderPageWrapper} />
        {/* <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} /> */}
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
