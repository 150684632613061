/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getPromo} from '../api/promo'
import {Link, useParams, useHistory} from 'react-router-dom'
import {PromoModel} from '../interface/Promo'
import {parseDate, parseDateOnly} from '../../../../utils/dateAndTime'
import {capitalize} from '../../../../utils/capitalize'

export interface IPageParams {
  id: string
}
const ViewPromo: FC = () => {
  const {id} = useParams<IPageParams>()
  const [promo, setPromo] = useState<PromoModel>()
  let history = useHistory()

  useEffect(() => {
    getPromo(id)
      .then(({data}) => {
        setPromo(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {promo && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::Promo Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={promo.image} alt='avatar' />
              </div>
              {/*end::Avatar*/}
              {/*begin::Name*/}
              <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-3'>
                {promo?.is_featured}
              </a>
              {/*end::Name*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {capitalize(promo.name)}
                </div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::Promo Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link to={`/promo/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_promo_view_details' className='collapse show'>
              <div className='pb-5 fs-6'>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>ID</div>
                <div className='text-gray-600'>{promo?._id}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>NAME</div>
                <div className='text-gray-600'>{promo.name}</div>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>TAGLINE</div>
                <div className='text-gray-600'>{promo.tagline}%</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>CODE</div>
                <div className='text-gray-600'>{promo.code}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>OFFER TYPE</div>
                <div className='text-gray-600'>{promo.offer_type}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>OFFER VALUE</div>
                <div className='text-gray-600'>
                  {promo.offer_type === 'flat' ? 'Rs' : ''}
                  {promo.offer_value}
                  {promo.offer_type === 'percent' ? '%' : ''}
                </div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>USER LIMIT</div>
                <div className='text-gray-600'>{promo.user_limit}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>MAX THRESHOLD</div>
                <div className='text-gray-600'>{promo.max_threshold}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>REDEEMABLE UPTO</div>
                <div className='text-gray-600'>{promo.redeemable_upto}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>START DATE</div>
                <div className='text-gray-600'>{parseDateOnly(promo.start_date)}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>END DATE</div>
                <div className='text-gray-600'>{parseDateOnly(promo.end_date)}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>DESCRIPTION</div>
                <div className='text-gray-600'>{promo.description}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Featured</div>
                <div className='text-gray-600'>{promo.is_featured ? 'Yes' : 'No'}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Created Date</div>
                <div className='text-gray-600'>{parseDate(promo?.createdAt)}</div>
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewPromo
