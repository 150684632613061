import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import EditFeatureAd from './feature-ad/EditFeatureAd'
import ListFeatureAd from './feature-ad/ListFeatureAd'
import ViewFeatureAd from './feature-ad/ViewFeatureAd'

const FeatureAdPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/feature-ad/edit/:id'>
          <PageTitle breadcrumbs={[]}>Feature Ad Edit</PageTitle>
          <EditFeatureAd />
        </Route>
        <Route path='/feature-ad/view/:id'>
          <PageTitle breadcrumbs={[]}>Feature Ad View</PageTitle>
          <ViewFeatureAd />
        </Route>
        <Route path='/feature-ad/:page'>
          <PageTitle breadcrumbs={[]}>Feature Ad List</PageTitle>
          <ListFeatureAd />
        </Route>
        <Redirect from='/feature-ad' exact={true} to='/feature-ad/1' />
        <Redirect to='/feature-ad/1' />
      </Switch>
    </>
  )
}

export default FeatureAdPage
