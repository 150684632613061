import {useState, useEffect, useContext} from 'react'
import {Switch, Checkbox, Divider, Form, InputNumber, message, Button, Input} from 'antd'
import {PlusOutlined, DeleteOutlined} from '@ant-design/icons'
import SelectAttributes from './SelectAttributes'
import DealContext from '../../contexts/DealContext'

const CheckboxGroup = Checkbox.Group

interface Props {
  name: number
  applicableVariants: any
  form: any
}

function ModifyAttributes({name, applicableVariants, form}: Props) {
  const {isCreate, changedVariant, isStockChange} = useContext(DealContext)
  // const {appliedVariants, applicableVariants} = variants

  // const appliedVariants = form.getFieldValue('deals')[name].variation
  const [showAttribute, setShowAttribute] = useState(false)
  const [appliedVariants, setAppliedVariants] = useState<any>(null)

  const [checkedList, setCheckedList] = useState<string[]>([])
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  let plainOptions: string[] = []

  const onChange = (list: any) => {
    // let {deals} = form.getFieldValue()
    // deals[name] = deals[name] ? deals[name] : {}
    // Object.assign(deals[name], {
    //   variation_items: applicableVariants
    //     .filter((e: any) => list.includes(e.title))
    //     .map((e: any) => e._id),
    // })
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e: any) => {
    setCheckedList(e.target.checked ? plainOptions : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    setAppliedVariants(form.getFieldValue('deals')[name].variation)
  }, [form, name])

  useEffect(() => {
    if (changedVariant) {
      setAppliedVariants(changedVariant)
    }
  }, [changedVariant])

  useEffect(() => {
    setShowAttribute(false)
    if (appliedVariants && appliedVariants.length) {
      setShowAttribute(true)
      let checkedItem = Object.keys(appliedVariants[0])
      setCheckedList(checkedItem.filter((e) => e !== 'sku').filter((e) => e !== 'stock'))
    }
  }, [appliedVariants])

  return (
    <>
      <label className='form-label me-3'>Do you want to modify attributes on this product?</label>
      <Switch
        checkedChildren='Yes'
        unCheckedChildren='No'
        onChange={(checked) => {
          if (isCreate) {
            if (applicableVariants && appliedVariants) return setShowAttribute(checked)
            message.error('No attributes available to modify')
          } else {
            if (applicableVariants) return setShowAttribute(checked)
          }
        }}
        checked={showAttribute}
        disabled={isStockChange}
      />
      <p className='text-muted'>By default, attributes are automatically taken from the product.</p>
      {showAttribute && renderAttributeCard()}
    </>
  )

  function renderAttributeCard() {
    plainOptions =
      (applicableVariants && applicableVariants.map((e: {title: string}) => e.title)) || []

    return (
      <>
        <Divider />
        <p>Mention the variant and add in available stocks:</p>
        <CheckboxGroup
          options={plainOptions}
          value={checkedList}
          onChange={onChange}
          disabled={isStockChange}
        />
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          disabled={isStockChange}
        >
          All of them
        </Checkbox>

        <div className='row mt-10'>
          <div className='col'>
            <Form.List name={[name, 'variation']} initialValue={appliedVariants}>
              {(fields, {add, remove}) => (
                <>
                  {checkedList.length > 0 &&
                    fields.map((field) => (
                      <div key={field.key}>
                        <Divider orientation='left'>Add variant and stock</Divider>
                        {checkedList.map((e: string) => (
                          <Form.Item
                            {...field}
                            labelCol={{span: 6}}
                            labelAlign='left'
                            label={e}
                            key={e}
                            name={[field.name, e]}
                          >
                            <SelectAttributes
                              restField={field}
                              name={name}
                              key={e}
                              e={e}
                              form={form}
                              variants={{applicableVariants, appliedVariants}}
                            />
                          </Form.Item>
                        ))}
                        {/* <Form.Item
                          labelColturn message.error('Select the variant boxes first!')
                        a={{span: 6}}
                          labelAlign='left'
                          name={[field.name, 'stock']}
                          label='Stock Qty:'
                        >
                          <InputNumber />
                        </Form.Item> */}

                        <Form.Item
                          name={[field.name, 'sku']}
                          rules={[{required: true, message: 'This field is required'}]}
                          labelCol={{span: 6}}
                          labelAlign='left'
                          label='SKU'
                        >
                          <Input disabled={isStockChange} />
                        </Form.Item>
                        <Form.Item
                          name={[field.name, 'stock']}
                          rules={[
                            {required: true, message: 'This field is required'},

                            {
                              type: 'integer',
                              min: 0,
                              message: 'Stock should be positive',
                            },
                          ]}
                          labelCol={{span: 6}}
                          labelAlign='left'
                          label='Stock Qty'
                        >
                          <InputNumber />
                        </Form.Item>

                        <Form.Item>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => {
                              remove(field.name)
                            }}
                            disabled={isStockChange}
                          >
                            Remove
                          </Button>
                        </Form.Item>
                      </div>
                    ))}
                  <Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => {
                        if (!checkedList.length)
                          return message.error('Select the variant boxes first!')
                        add()
                      }}
                      icon={<PlusOutlined />}
                      disabled={isStockChange}
                    >
                      Add New
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </div>
      </>
    )
  }
}

export default ModifyAttributes
