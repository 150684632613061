export function calculateDisplayPrice(is_flat, product_price, discount) {
  let discount_amount = 0
  if (is_flat) {
    discount_amount = product_price - discount
  } else {
    discount_amount = product_price - (discount / 100) * product_price
  }
  return Math.round(discount_amount)
}

export function getValidUrl(url) {
  if (url.startsWith('http')) {
    return url
  }
  return `https://${url}`
}
