/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  AddressModal,
  BankAccountModal,
  BusinessInformationModal,
  MerchantAccountProps,
  SellerDetails,
} from './interface/account'
import ProfileDetails from './components/profile/ProfileDetails'
import BusinessInformation from './components/profile/BusinessInformation'
import BankAccount from './components/profile/BankAccount'
import Address from './components/profile/Address'
import {MerchantModel} from '../../auth/models/UserModel'

const General: React.FC<MerchantAccountProps> = ({user, isAdmin}) => {
  const getSellerDetails = (user: MerchantModel) => {
    const sellerDetails = {
      full_name: user.full_name,
      settlement_days: user.settlement_days,
      email: user.email,
      description: user.description,
      phone_number: user.phone_number,
      image: user.image,
      display_name: user.display_name,
      holiday_mode: user.holiday_mode,
      holiday_mode_from: user.holiday_mode_from,
      holiday_mode_to: user.holiday_mode_to,
      category: {_id: user?.category?._id, name: user?.category?.name},
    }
    return sellerDetails
  }
  return (
    <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
      <div className='rounded border p-3'>
        <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
          <li className='nav-item'>
            <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
              Profile
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
              Business Information
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_3'>
              Bank Account
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_4'>
              Warehouse Address
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_5'>
              Return Address
            </a>
          </li>
        </ul>
        <div className='tab-content' id='myTabContent'>
          <div className='tab-pane fade active show' id='kt_tab_pane_1' role='tabpanel'>
            <ProfileDetails
              user={getSellerDetails(user) as SellerDetails}
              isAdmin={isAdmin}
              userId={user._id}
            />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
            <BusinessInformation
              user={user.business_information as BusinessInformationModal}
              isAdmin={isAdmin}
              userId={user._id}
            />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_3' role='tabpanel'>
            <BankAccount
              user={user.bank_account as BankAccountModal}
              isAdmin={isAdmin}
              userId={user._id}
            />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_4' role='tabpanel'>
            <Address
              user={user.warehouse_address as AddressModal}
              copy_button={false}
              isAdmin={isAdmin}
              userId={user._id}
            />
          </div>
          <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
            <Address
              user={user.return_address as AddressModal}
              warehouse_address={user.warehouse_address as AddressModal}
              copy_button={true}
              isAdmin={isAdmin}
              userId={user._id}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default General
