import {Button, Card, Form, Input, InputNumber, notification, Switch} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import DiscountField from './DiscountField'
import ModifyAttributes from './ModifyAttributes'
import {useContext} from 'react'
import DealContext from '../../contexts/DealContext'
import {putDealStockStatus} from '../../api/deal'
import {useParams} from 'react-router-dom'

interface Props {
  applicableVariants: any
  form: any
}

const DealChoice = ({applicableVariants, form}: Props) => {
  const {isStockChange} = useContext(DealContext)
  const {id} = useParams<{id: string}>()
  return (
    <Form.List name='deals' initialValue={Array(1).fill(null)}>
      {(fields, {add, remove}) => (
        <>
          {fields.map(({key, name, ...restField}, idx) => (
            <Card
              key={key}
              type='inner'
              title={`Deal Choice ${idx + 1}`}
              style={{marginBottom: 16}}
              extra={
                isStockChange ? (
                  <div className='col'>
                    <label className='form-label me-2'>IS DEAL OUT OF STOCK?</label>
                    <Form.Item
                      noStyle
                      {...restField}
                      name={[name, 'out_of_stock']}
                      valuePropName='checked'
                    >
                      <Switch
                        defaultChecked={false}
                        checkedChildren={<span>No</span>}
                        unCheckedChildren={<span>Yes</span>}
                        onChange={(checked) => {
                          putDealStockStatus({deal_id: id, deal_index: key, status: checked})
                            .then(({data}) => {
                              notification.success({message: data.result})
                            })
                            .catch((error) => {
                              notification.error({message: 'Error'})
                            })
                        }}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <Button type='text' danger onClick={() => remove(name)}>
                    Remove
                  </Button>
                )
              }
            >
              <div className='row'>
                <div className='col'>
                  <label className='required form-label'>Title</label>
                  <Form.Item
                    {...restField}
                    name={[name, 'title']}
                    rules={[{required: true, message: 'Missing title'}]}
                  >
                    <Input
                      placeholder='5 nights at Adaaran Select Hudhuranfushi'
                      disabled={isStockChange}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label className='required form-label'>Original Price</label>
                  <Form.Item
                    {...restField}
                    name={[name, 'original_price']}
                    rules={[
                      {required: true, message: 'Original Price is required.'},
                      {
                        type: 'integer',
                        min: 0,
                        message: 'Original Price should be positive',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{width: '100%'}}
                      placeholder='1000'
                      disabled={isStockChange}
                    />
                  </Form.Item>
                </div>
                {applicableVariants && applicableVariants.length === 0 && (
                  <div className='col'>
                    <label className='form-label'>
                      Stock {isStockChange && <>(The amount of this deal to stock in)</>}
                    </label>
                    <Form.Item
                      {...restField}
                      name={[name, 'stock']}
                      rules={[
                        {
                          type: 'integer',
                          min: 0,
                          message: 'Stock should be positive',
                        },
                      ]}
                    >
                      <InputNumber style={{width: '100%'}} placeholder='1000' />
                    </Form.Item>
                  </div>
                )}
              </div>

              <div className='row'>
                <div className='col'>
                  <DiscountField form={form} restField={restField} name={name} />
                </div>
                <div className='col'>
                  <label htmlFor='exampleFormControlInput1' className='form-label'>
                    Display Price
                  </label>
                  <p className='text-muted'>The price after the discount is given </p>
                  <Form.Item {...restField} name={[name, 'display_price']}>
                    <Input addonAfter='.00' addonBefore='Rs.' type='number' disabled />
                  </Form.Item>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label className='required form-label'>Extra Information</label>
                  <Form.Item
                    {...restField}
                    name={[name, 'extra_information']}
                    rules={[{required: true, message: 'Missing extra information'}]}
                  >
                    <Input
                      className='form-control'
                      placeholder='One king bed for two; 5-night minimum stay; 5 night min'
                      disabled={isStockChange}
                    />
                  </Form.Item>
                </div>
                {/* <div className='col'>
                  <label className='form-label'>Stock</label>
                  <Form.Item {...restField} name={[name, 'stock']}>
                    <input className='form-control form-control-solid' placeholder='100' />
                  </Form.Item>
                </div> */}
              </div>

              {applicableVariants && applicableVariants.length > 0 && (
                <div>
                  <ModifyAttributes
                    form={form}
                    name={name}
                    applicableVariants={applicableVariants}
                  />
                </div>
              )}
            </Card>
          ))}
          <Form.Item>
            <Button
              type='dashed'
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              disabled={isStockChange}
            >
              Add More Deal Choices
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default DealChoice
