import React, {FC} from 'react'
import {FilterParams} from '../../interface/IMerchant'
// import {Collapse} from 'antd'

// const {Panel} = Collapse

interface Props {
  name: string
  placeholder: string
  filterParams: FilterParams
  setFilterParams: any
}

const MultilineFilter: FC<Props> = ({name, placeholder, filterParams, setFilterParams}) => {
  const handleFilter = (event: React.ChangeEvent<any>) => {
    const {value, name} = event.target
    setFilterParams({...filterParams, [name]: value, page: '1'})
  }
  return (
    <>
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid w-250px  me-3'
        placeholder={placeholder}
        name={name}
        onChange={(event) => handleFilter(event)}
      />
    </>
  )
}

export default MultilineFilter
