import {FC} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import CreateDeal from './components/CreateDeal'
import EditDeal from './components/EditDeal'
import ListDeal from './components/ListDeal'
import ViewDeal from './components/ViewDeal'

const DealPage: FC = () => {
  return (
    <Switch>
      <Route path='/deal/edit/:id'>
        <PageTitle breadcrumbs={[]}>Update Deal</PageTitle>
        <EditDeal isStockChange={false} />
      </Route>
      <Route path='/deal/changestock/:id'>
        <PageTitle breadcrumbs={[]}>Stock In/Out Deal</PageTitle>
        <EditDeal isStockChange={true} />
      </Route>
      <Route path='/deal/view/:id'>
        <PageTitle breadcrumbs={[]}>View Deal</PageTitle>
        <ViewDeal />
      </Route>
      <Route path='/deal/add'>
        <PageTitle breadcrumbs={[]}>Add Deal</PageTitle>
        <CreateDeal />
      </Route>
      <Route path='/deal/:pageNo'>
        <PageTitle breadcrumbs={[]}>List Deal</PageTitle>
        <ListDeal />
      </Route>
      <Redirect from='/deal' exact={true} to='/deal/add' />
      <Redirect to='/deal/add' />
    </Switch>
  )
}

export default DealPage
