/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getFeatureAd} from '../api/feature-ad'
import {Link, useParams, useHistory} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {FeatureAdModel} from '../interface/FeatureAd'
import {capitalize} from '../../../../utils/capitalize'

export interface IPageParams {
  id: string
}
const ViewFeatureAd: FC = () => {
  const {id} = useParams<IPageParams>()
  const [featureAd, setFeatureAd] = useState<FeatureAdModel>()
  let history = useHistory()

  useEffect(() => {
    getFeatureAd(id)
      .then(({data}) => {
        setFeatureAd(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {featureAd && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::FeatureAd Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={featureAd.image} alt='avatar' />
              </div>
              {/*end::Avatar*/}
              {/*begin::Name*/}
              <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-3'>
                {featureAd?.is_featured}
              </a>
              {/*end::Name*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {capitalize(featureAd?.name)}
                </div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::FeatureAd Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link to={`/feature-ad/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_featureAd_view_details' className='collapse show'>
              {console.log(featureAd)}
              <div className='pb-5 fs-6'>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>ID</div>
                <div className='text-gray-600'>{featureAd?._id}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>NAME</div>
                <div className='text-gray-600'>{featureAd.name}</div>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>OFFER PERCENT</div>
                <div className='text-gray-600'>{featureAd.offer}%</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>CALLBACK</div>
                <div className='text-gray-600'>{featureAd.callback}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>CALLBACK NAME</div>
                <div className='text-gray-600'>{featureAd.callback_name}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Color</div>
                <div className='text-gray-600'>{capitalize(featureAd.color)}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Featured</div>
                <div className='text-gray-600'>{featureAd.is_featured ? 'Yes' : 'No'}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Created Date</div>
                <div className='text-gray-600'>{parseDate(featureAd?.createdAt)}</div>
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewFeatureAd
