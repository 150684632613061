/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getProduct, putProduct} from '../api/product'
import {useParams} from 'react-router-dom'
import {useHistory} from 'react-router'
import {toast} from 'react-toastify'
import {ProductModel} from '../interface/Product'
import {getAllMerchant} from '../../user-management/api/merchant'
import Select from 'react-select'
import {getAllCategory} from '../../category/api/category'
import {Field, Form, Formik} from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'

export interface IPageParams {
  id: string
}

const LEVEL = 3

const productSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(1000, 'Maximum 1000 symbols')
    .required('Name is required'),
  description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(1000, 'Maximum 1000 symbols')
    .required('Description is required'),
  start_date: Yup.date().required('Sales Start is required'),
  end_date: Yup.date().required('Sales End is required'),
  sub_title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Deal Title is required'),
  // images: Yup.string().required('Image is required'),
})

const EditProduct: FC = () => {
  const {id} = useParams<IPageParams>()
  const [product, setProduct] = useState<ProductModel>()
  const history = useHistory()

  const [loading, setLoading] = useState<boolean>(false)

  const [hintMerchantData, setHintMerchantData] = useState<any>()
  const [selectedMerchantOption, setSelectedMerchantOption] = useState<any>({})

  const [hintCategoryData, setHintCategoryData] = useState<any>()
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<any>({})

  useEffect(() => {
    getProduct(id).then(({data}) => {
      const product = data.result
      setProduct(product)
      console.log(product)
      // if (!product.categoryId._id) {
      //   setSelectedCategoryOption({value: product.categoryId._id, label: product.categoryId.name})
      // }
      // setSelectedMerchantOption({
      //   value: product.merchantId._id,
      //   label: product.merchantId.display_name,
      // })
    })
  }, [id])

  const handleImageDelete = (event: React.ChangeEvent<any>, values: string[], index: any) => {
    event.preventDefault()
    values.splice(index, 1)
  }

  const getAutoCompleteMerchantData = () => {
    getAllMerchant().then(({data}) => {
      const hintArray = data.map((item) => {
        return {
          value: item._id,
          label: item.display_name,
        }
      })
      setHintMerchantData(hintArray)
    })
  }
  const getAutoCompleteCategoryData = () => {
    getAllCategory({page: ''}, LEVEL).then(({data}) => {
      const hintArray = data.map((item) => {
        return {
          value: item._id,
          label: item.name,
        }
      })
      setHintCategoryData(hintArray)
    })
  }

  useEffect(() => {
    getAutoCompleteCategoryData()
    getAutoCompleteMerchantData()
  }, [])
  // function handleChange(event: React.ChangeEvent<any>) {
  //   const value = event.target.value
  //   const name = event.target.name
  //   const result = {...product, [name]: value} as ProductModel
  //   setProduct(result)
  // }

  return (
    <>
      {product && (
        <div className='card pt-4 mb-6 mb-xl-9'>
          {/*begin::Card body*/}
          <div className='card-body pt-0 pb-5'>
            {/*begin::Table*/}
            <Formik
              initialValues={product}
              validationSchema={productSchema}
              validateOnMount
              onSubmit={(values) => {
                setLoading(true)
                if (selectedMerchantOption) {
                  values = {...values, merchantId: selectedMerchantOption.value}
                }
                if (selectedCategoryOption) {
                  values = {...values, categoryId: selectedCategoryOption.value}
                }
                putProduct(id, values as ProductModel)
                  .then((data) => {
                    setLoading(false)
                    history.push(`/product/view/${id}`)
                    toast.success('Success!')
                  })
                  .catch((error) => {
                    setLoading(false)
                  })
              }}
            >
              {({values, errors, touched, isSubmitting, isValid, setFieldValue}) => (
                <Form>
                  <table className='table align-middle table-row-dashed gy-5'>
                    {/*begin::Table body*/}
                    <tbody className='fs-6 fw-bold text-gray-600'>
                      <tr>
                        <td>Product ID</td>
                        <td>
                          <Field
                            placeholder='Product ID'
                            value={product._id}
                            className='form-control'
                            disabled
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Merchant Name</td>
                        <td>
                          <Select
                            value={selectedMerchantOption}
                            options={hintMerchantData}
                            onChange={setSelectedMerchantOption}
                            name='merchant_id'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Category Name</td>
                        <td>
                          <Select
                            value={selectedCategoryOption}
                            options={hintCategoryData}
                            onChange={setSelectedCategoryOption}
                            name='category_id'
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Product Name</td>
                        <td>
                          <Field
                            name='name'
                            placeholder='Product Name'
                            value={values.name}
                            className={clsx(
                              'form-control',
                              {
                                'is-invalid': touched.name && errors.name,
                              },
                              {
                                'is-valid': touched.name && !errors.name,
                              }
                            )}
                          />
                          {touched.name && errors.name && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.name}</span>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Product Description</td>
                        <td>
                          <Field
                            name='description'
                            placeholder='Product Description'
                            value={values.description}
                            className={clsx(
                              'form-control',
                              {
                                'is-invalid': touched.description && errors.description,
                              },
                              {
                                'is-valid': touched.description && !errors.description,
                              }
                            )}
                            as='textarea'
                          />
                          {touched.description && errors.description && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.description}</span>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Sales Start</td>
                        <td>
                          {/* <Field name='start_date'>
                            {({field, form}: FieldProps<any>) => {
                              const {setFieldValue} = form
                              const {value} = field
                              return (
                                <input
                                  type='date'
                                  className={clsx(
                                    'form-control',
                                    {
                                      'is-invalid': touched.start_date && errors.start_date,
                                    },
                                    {
                                      'is-valid': touched.start_date && !errors.start_date,
                                    }
                                  )}
                                  name='start_date'
                                  value={value ? new Date(value).toISOString().slice(0, 10) : ''}
                                  onChange={(event) => {
                                    setFieldValue('start_date', event.target.value)
                                  }}
                                />
                              )
                            }}
                          </Field> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Sales End</td>
                        <td>
                          {/* <Field name='end_date'>
                            {({field, form}: FieldProps<any>) => {
                              const {setFieldValue} = form
                              const {value} = field
                              return (
                                <input
                                  type='date'
                                  className={clsx(
                                    'form-control',
                                    {
                                      'is-invalid': touched.end_date && errors.end_date,
                                    },
                                    {
                                      'is-valid': touched.end_date && !errors.end_date,
                                    }
                                  )}
                                  name='end_date'
                                  value={value ? new Date(value).toISOString().slice(0, 10) : ''}
                                  onChange={(event) => {
                                    setFieldValue('end_date', event.target.value)
                                  }}
                                />
                              )
                            }}
                          </Field> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Deal Title</td>
                        <td>
                          <Field
                            name='actual_price'
                            placeholder='Original Price'
                            value={values.actual_price}
                            className={clsx(
                              'form-control',
                              {
                                'is-invalid': touched.actual_price && errors.actual_price,
                              },
                              {
                                'is-valid': touched.actual_price && !errors.actual_price,
                              }
                            )}
                          />
                          {touched.actual_price && errors.actual_price && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{errors.actual_price}</span>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Deals</td>
                        <td>
                          {/* <FieldArray name='deals'>
                            {({insert, remove, push}) => (
                              <div className='fv-row mb-7'>
                                {values.deals && values.deals.length > 0 ? (
                                  values.deals.map((deal, index) => (
                                    <div key={index}>
                                      <Accordion defaultActiveKey='0' className='mb-7'>
                                        <Accordion.Item eventKey={String(index)}>
                                          <Accordion.Header>
                                            Deal #{`${index + 1}`}
                                          </Accordion.Header>
                                          <Accordion.Body>
                                            <div className='fv-row mb-7'>
                                              <label className='form-label fw-bolder text-dark fs-6'>
                                                Title
                                              </label>
                                              <Field
                                                className='form-control form-control-lg form-control-solid'
                                                name={`deals.${index}.title`}
                                                placeholder='Title'
                                                autoComplete='off'
                                              />
                                            </div>

                                            <div className='fv-row mb-7'>
                                              <label className='form-label fw-bolder text-dark fs-6'>
                                                Subtitle
                                              </label>
                                              <Field
                                                className='form-control form-control-lg form-control-solid'
                                                name={`deals.${index}.sub_title`}
                                                placeholder='Subtitle'
                                                autoComplete='off'
                                              />
                                            </div>
                                            <div className='row mb-7'>
                                              <div className='col-md-6'>
                                                <label className='form-label fw-bolder text-dark fs-6'>
                                                  Actual Price
                                                </label>
                                                <Field
                                                  className='form-control form-control-lg form-control-solid'
                                                  placeholder='Actual Price'
                                                  name={`deals.${index}.price`}
                                                  autoComplete='off'
                                                />
                                              </div>

                                              <div className='col-md-6'>
                                                <label className='form-label fw-bolder text-dark fs-6'>
                                                  Discount Percent
                                                </label>
                                                <Field
                                                  className='form-control form-control-lg form-control-solid'
                                                  placeholder='Discount Percent'
                                                  name={`deals.${index}.discount_percent`}
                                                  autoComplete='off'
                                                />
                                              </div>
                                            </div>
                                          </Accordion.Body>
                                        </Accordion.Item>
                                      </Accordion>
                                      {index === values.deals.length - 1 && (
                                        <>
                                          <button
                                            type='button'
                                            className='btn btn-danger me-3'
                                            onClick={() => remove(values.deals.length - 1)}
                                          >
                                            -
                                          </button>
                                          <button
                                            type='button'
                                            className='btn btn-primary'
                                            onClick={() => insert(values.deals.length, '')}
                                          >
                                            +
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  ))
                                ) : (
                                  <button
                                    type='button'
                                    className='btn btn-primary'
                                    onClick={() => push('')}
                                  >
                                    Add Deals
                                  </button>
                                )}
                              </div>
                            )}
                          </FieldArray> */}
                        </td>
                      </tr>
                      <tr>
                        <td>Image </td>
                        <td>
                          {/* begin::Form group Image*/}
                          <div className='fv-row mb-7'>
                            {/* <Row>
                              {values.images.map((item, index) => {
                                return (
                                  <Col xs={12} md={2} className='mb-2' key={index}>
                                    <div className='image-input image-input-outline'>
                                      <img
                                        className='image-input-wrapper'
                                        src={item as string}
                                        alt='product'
                                      />
                                      <span
                                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow'
                                        data-kt-image-input-action='remove'
                                        data-bs-toggle='tooltip'
                                        data-bs-dismiss='click'
                                        data-bs-original-title='Remove avatar'
                                        onClick={(event) => {
                                          event.preventDefault()
                                          let image = values.images.splice(index, 1)
                                          setFieldValue('images', values.images)
                                        }}
                                      >
                                        <i className='bi bi-x fs-2' />
                                      </span>
                                    </div>
                                  </Col>
                                )
                              })}
                            </Row> */}
                            {/* <Field
                              type='file'
                              name='file'
                              accept='.png, .jpg, .jpeg'
                              onChange={async (event: React.ChangeEvent<any>) => {
                                let formdata = new FormData()
                                formdata.append('file', event.currentTarget.files[0])
                                try {
                                  const url: string = process.env.REACT_APP_IMAGE_UPLOAD as string
                                  const res = await fetch(url, {
                                    method: 'post',
                                    body: formdata,
                                  })
                                  const data = await res.json()
                                  if (data.status_code === 201) {
                                    setFieldValue(`images[${values.images.length}]`, data['url'])
                                  }
                                } catch (err) {
                                  console.log(err)
                                }
                              }}
                            /> */}
                            {touched.images && errors.images && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.images}</span>
                                </div>
                              </div>
                            )}
                          </div>
                          {/* end::Form group */}
                        </td>
                      </tr>
                    </tbody>
                    {/*end::Table body*/}
                  </table>
                  {/*end::Table*/}
                  <div className='d-flex justify-content-end align-items-center mt-15'>
                    {/*begin::Button*/}
                    <button onClick={() => history.goBack()} className='btn btn-light me-5'>
                      Back
                    </button>
                    {/*end::Button*/}
                    {/*begin::Button*/}
                    <button
                      type='submit'
                      className='btn btn-primary'
                      disabled={isSubmitting || !isValid}
                    >
                      {!loading ? (
                        <span className='indicator-label'>Save</span>
                      ) : (
                        <span className='indicator-progress'>
                          Save
                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                      )}
                    </button>
                    {/*end::Button*/}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/*end::Card body*/}
        </div>
      )}
    </>
  )
}

export default EditProduct
