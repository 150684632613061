import {Select} from 'antd'
import {useContext} from 'react'
import DealContext from '../../contexts/DealContext'

const SelectAttributes = ({e, variants, name, restField, form}: any) => {
  let defaultValue

  if (variants.appliedVariants && variants.appliedVariants[restField.key]) {
    defaultValue = variants.appliedVariants[restField.key][e]
  }

  const {isStockChange} = useContext(DealContext)

  return (
    <Select
      onChange={(value) => {
        let fieldsValue = form!.getFieldsValue()
        fieldsValue['deals'][name]['variation'][restField.name] = {
          ...fieldsValue['deals'][name]['variation'][restField.name],
          [e]: value,
        }
        form!.setFieldsValue(fieldsValue)
      }}
      allowClear
      placeholder='Please select'
      value={defaultValue}
      disabled={isStockChange}
    >
      {variants.applicableVariants &&
        variants.applicableVariants
          .find((el: any) => el.title === e)
          ?.values.map((e: string) => (
            <Select.Option key={e} value={e}>
              {e}
            </Select.Option>
          ))}
    </Select>
  )
}

export default SelectAttributes
