/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {getBrand} from '../api/brand'
import {Link, useParams, useHistory} from 'react-router-dom'
import {parseDate} from '../../../../utils/dateAndTime'
import {BrandModel} from '../interface/Brand'
import {capitalize} from '../../../../utils/capitalize'

export interface IPageParams {
  id: string
}
const ViewBrand: FC = () => {
  const {id} = useParams<IPageParams>()
  const [brand, setBrand] = useState<BrandModel>()
  let history = useHistory()

  useEffect(() => {
    getBrand(id)
      .then(({data}) => {
        setBrand(data.result)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  return (
    <>
      {/*begin::Card*/}
      {brand && (
        <div className='card mb-5 mb-xl-8'>
          {/*begin::Card body*/}
          <div className='card-body'>
            {/*begin::Summary*/}
            {/*begin::Brand Info*/}
            <div className='d-flex flex-center flex-column py-5'>
              {/*begin::Avatar*/}
              <div className='symbol symbol-100px symbol-circle mb-7'>
                <img src={brand.image} alt='avatar' />
              </div>
              {/*end::Avatar*/}
              {/*begin::Name*/}
              <a href='#' className='fs-3 text-gray-800 text-hover-primary fw-bolder mb-3'>
                {brand?.is_featured}
              </a>
              {/*end::Name*/}
              {/*begin::Position*/}
              <div className='mb-9'>
                {/*begin::Badge*/}
                <div className='badge badge-lg badge-light-primary d-inline'>
                  {capitalize(brand?.name)}
                </div>
                {/*begin::Badge*/}
              </div>
              {/*end::Position*/}
            </div>
            {/*end::Brand Info*/}
            {/*end::Summary*/}
            {/*begin::Details toggle*/}
            <div className='d-flex flex-stack fs-4 py-3'>
              <div className='fw-bolder rotate collapsible'>Details</div>
              <span>
                <button onClick={() => history.goBack()} className='btn btn-sm btn-light me-2'>
                  Back
                </button>
                <Link to={`/brand/edit/${id}`} className='btn btn-sm btn-light-primary'>
                  Edit
                </Link>
              </span>
            </div>
            {/*end::Details toggle*/}
            <div className='separator' />
            {/*begin::Details content*/}
            <div id='kt_brand_view_details' className='collapse show'>
              {console.log(brand)}
              <div className='pb-5 fs-6'>
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>ID</div>
                <div className='text-gray-600'>{brand?._id}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>NAME</div>
                <div className='text-gray-600'>{brand.name}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Tagline</div>
                <div className='text-gray-600'>{brand.tagline}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Image</div>
                <div className='text-gray-600'>{brand.image}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Featured</div>
                <div className='text-gray-600'>{brand.is_featured ? 'Yes' : 'No'}</div>
                {/*end::Details item*/}
                {/*begin::Details item*/}
                <div className='fw-bolder mt-5'>Created Date</div>
                <div className='text-gray-600'>{parseDate(brand?.createdAt)}</div>
                {/*end::Details item*/}
              </div>
            </div>
            {/*end::Details content*/}
          </div>
          {/*end::Card body*/}
        </div>
      )}
      {/*end::Card*/}
    </>
  )
}

export default ViewBrand
